import {
  Box,
  Typography,
  Grid,
  Checkbox,
  FormControlLabel,
} from "@mui/material";
import styles from "./S1_ActivationStep4.module.scss";
import { LinearProgressBarCust, ButtonCust } from "../../../widgets";
import EnvConfig from "../../../../config/env.config.json";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { HelpSupportModal } from "../ActivationStep2/SupportModal";
import { ActivationFailedModal } from "./ActivationFailedModal";
import Config from "../../../../config/env.config.json";
import ActivationIsMnp from "./ActivationIsMnp";
import { useState } from "react";
import { OperationInProcessState } from "../../../../enums";

const ActivationStep4 = (props: any) => {
  const {
    handleStep,
    currentLine,
    content,
    modalContent,
    showModal,
    openSupportDialog,
    activateBtnLd,
    showActionIsMnp,
    redirection,
    activateHandler,
    onCloseSupportDialog,
    openSupportModal,
    closeActivationFailModal,
  } = props;
  const [wifiEnable, setWifiEnabled] = useState(false);
  const {
    step4,
    step_txt,
    sp4_title,
    c_sup_title,
    wifi_check_label,
    wifi_check_confirm,
  } = content;
  const { isMNP, mnpInfo, zipcode } = currentLine || {};
  const { oldZip, oldPassword, oldNumber, acctNum } = mnpInfo || {};
  const { isSimInHand, simId } = currentLine || {};
  const simID = localStorage.getItem("simId");
  const lineDetails = (
    imgName: any,
    altName: string,
    labelText: string,
    labelVal: any
  ) => {
    return (
      <Grid item container sm={5} xs={6} py={1.5}>
        <Box display="flex" alignItems="center">
          <Box
            component="img"
            src={imgName}
            className={styles.icon_size}
            alt={altName}
          />
          <Grid item container className={styles.labels_grid}>
            <Typography className={styles.labels} textOverflow="break-word">
              {" "}
              {labelText}
            </Typography>
            <Typography className={styles.details}> {labelVal}</Typography>
          </Grid>
        </Box>
      </Grid>
    );
  };
  const handleWifiCheck = () => {
    setWifiEnabled(!wifiEnable);
  };
  const handleBackBtn = () => {
    props?.setBckBtnClick(false);
    handleStep(false);
  }
  return (
    <Box className={styles.step4_main}>
      {!isSimInHand && (
        <Box className={styles.heading_container}>
          <ArrowBackIcon
            sx={{ ml: { md: "30px", sm: "25px", xs: "4%" } }}
            className={styles.arrow_icon}
            onClick={() => (!showActionIsMnp ? handleBackBtn() : null)}
          />
        </Box>
      )}
      {showActionIsMnp ? (
        <ActivationIsMnp
          {...props}
          redirection={redirection}
          step4Content={content}
        />
      ) : (
        <>
          <Box className={styles.heading_container}>
            <Typography className={styles.title_main}>{sp4_title}</Typography>
          </Box>
          <Box className={styles.stepper_content}>
            <Typography className={styles.stepper_txt}>{step_txt}</Typography>
            <Box width={{ xs: "90%", sm: "50%", md: "35%" }} mb="25px">
              <LinearProgressBarCust value={100} variant={"determinate"} />
            </Box>
          </Box>
          <Box
            className={styles.activation_box}
            width={{ md: "48%", xs: "85%", sm: "90%" }}
            p={{ xs: "30px 15px", md: "30px" }}
            alignItems="center"
          >
            <Box className={styles.activation_final_heading}>
              {step4?.title}
            </Box>
            <Box className={styles.activation_final_sub_heading}>
              {step4?.desc}
            </Box>

            <Grid
              item
              container
              display="flex"
              alignItems="center"
              justifyContent="space-between"
              md={8.5}
              sm={9}
              xs={12}
              mx="auto"
              px={0}
            >
              {lineDetails(
                step4?.dev_img?.data?.attributes?.url,
                step4?.dev_img?.data?.attributes?.alternativeText,
                step4?.dev,
                currentLine?.model
              )}
              {lineDetails(
                step4?.devId_img?.data?.attributes?.url,
                step4?.devId_img?.data?.attributes?.alternativeText,
                step4?.devId,
                currentLine?.imei
              )}
              {isMNP &&
                lineDetails(
                  step4?.cur_no_img?.data?.attributes?.url,
                  step4?.cur_no_img?.data?.attributes?.alternativeText,
                  step4?.cur_no,
                  oldNumber
                )}
              {isSimInHand &&
                lineDetails(
                  step4?.zip_code_img?.data?.attributes?.url,
                  step4?.zip_code_img?.data?.attributes?.alternativeText,
                  step4?.zip_code,
                  zipcode
                )}
              {lineDetails(
                step4?.simId_img?.data?.attributes?.url,
                step4?.simId_img?.data?.attributes?.alternativeText,
                step4?.simId,
                simID || simId
              )}
              {isMNP &&
                lineDetails(
                  step4?.acc_num_img?.data?.attributes?.url,
                  step4?.acc_num_img?.data?.attributes?.alternativeText,
                  step4?.acc_num,
                  acctNum
                )}

              {isMNP &&
                lineDetails(
                  step4?.pin_img?.data?.attributes?.url,
                  step4?.pin_img?.data?.attributes?.alternativeText,
                  step4?.pin,
                  oldPassword
                )}

              {isMNP &&
                lineDetails(
                  step4?.zip_code_img?.data?.attributes?.url,
                  step4?.zip_code_img?.data?.attributes?.alternativeText,
                  step4?.zip_code,
                  oldZip
                )}
            </Grid>
            {(wifi_check_label || wifi_check_confirm) &&
            EnvConfig.IS_WIFI_CHECK_NEEDED_FOR_ACTIVATION ? (
              <Box mt={"25px"}>
                {wifi_check_label ? (
                  <Typography
                    sx={{
                      lineHeight: "20px",
                      fontSize: "14px",
                      fontWeight: "800",
                      fontFamily: "var(--font_family_Bold)",
                    }}
                  >
                    {wifi_check_label}
                  </Typography>
                ) : null}
                {wifi_check_confirm ? (
                  <Box sx={{ textAlign: "center" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={wifiEnable}
                          onChange={handleWifiCheck}
                          style={{
                            color: "var(--primary_color)",
                          }}
                        />
                      }
                      label={
                        <Typography
                          style={{ color: "var(--text_color_2)" }}
                          sx={{ fontFamily: "var(--font_family_Medium)" }}
                        >
                          {wifi_check_confirm}
                        </Typography>
                      }
                    />
                  </Box>
                ) : null}
              </Box>
            ) : null}
            <Box className={styles.activate_button}>
              <ButtonCust
                sx={{ width: { xs: "200px" } }}
                onClick={() => activateHandler()}
                loading={activateBtnLd}
                variantType={Config.PRIMARY_BUTTON_TYPE}
                disabled={
                  !wifiEnable && EnvConfig.IS_WIFI_CHECK_NEEDED_FOR_ACTIVATION && 
                  currentLine?.operationsInProcess.includes("MIA_IN_PROCESS") || 
                  currentLine?.operationsInProcess.includes(OperationInProcessState.paymentCallback)
                }
              >
                {step4?.act_btn}
              </ButtonCust>
            </Box>
          </Box>
        </>
      )}
      {showModal && (
        <ActivationFailedModal
          showModal={showModal}
          openSupportModal={openSupportModal}
          closeActivationFailModal={closeActivationFailModal}
          content={content}
          currentLine={currentLine}
        />
      )}
      <HelpSupportModal
        open={openSupportDialog}
        onClose={onCloseSupportDialog}
        title={c_sup_title}
        showClose={false}
        showBackBtn={true}
        content={modalContent}
      />
    </Box>
  );
};

export default ActivationStep4;
