import { version, versionNew } from "../config/RoutingURLs";
import EnvConfig from "./env.config.json";

const ApiEndPoints = {
  networkCoverage: `/authsvc/${version}/address/network/coverage`,
  loginWithNumber: `/authsvc/${version}/customers/reachnumber/`,
  resetPassword: `/authsvc/${version}/customer/reset/password/link/email`,
  countryList: `/authsvc/${version}/intl/roaming/countries/`,
  hubspot: `/authsvc/${version}/customers/hubspot`,
  customerDetails: `/authsvc/${version}/customers/username?id=`,
  StateCityByZipcode: `/authsvc/${version}/mdn/zipcode/availability/`,
  createCustomer: `/authsvc/${version}/`,
  updateCustomer: `/authsvc/${version}/customers/`,
  taxCalculation: `/authsvc/${version}/taxes/`,
  updateCustomerSource: `/authsvc/${version}/update/fields/customer/`,
  coupon: `/authsvc/${version}/coupon/`,
  couponReserve: `/authsvc/${version}/coupon/reserve/promo`,
  eligibility: `/authsvc/${version}/coupon/eligibility?emailId=`,
  reachPlan: `/authsvc/${version}/reachplans/`,
  checkIMEI: `/authsvc/${version}/imei/`,
  configKeys: `/authsvc/${version}/config/keys`,
  SIMIdCheck: `/authsvc/${version}/icc/validate/`,
  SIMIDValiate: `/authsvc/${version}/iccid/validate`,
  trackOrderShipment: `/authsvc/${version}/shipment/order/`,
  trackOrderShipmentV2:`/authsvc/${version}/shipment/order`,
  activateLine: `/authsvc/${version}/activation`,
  monthInAdvance: `/authsvc/${version}/month/in/advance/amount/`,
  creditCardDetails: `/authsvc/${version}/customer/cc/`,
  monthInAdvancePost: `/authsvc/v0/month/in/advance/pay/`,
  topupPulse: `/authsvc/${
    EnvConfig?.OPERATOR?.toUpperCase() === "PLUM_VAD" &&
    EnvConfig?.MULTIPLE_DATA_TOP_UP_REQUIRED
      ? versionNew
      : version
  }/topup/pulse`,
  activationStatusCheck: `/authsvc/v0/activation/status/`,
  tax4Calculation: `/authsvc/${version}/taxes/usa`,
  configInfo: `/authsvc/${version}/config/info`,
  topUpCustomer: `/authsvc/${version}/topup/customer/`,
  topUpCustomerStatus: `/authsvc/${version}/customer/operations/status/`,
  topUpGroup: `/authsvc/${version}/topup/group/`,
  currentDataUsage: `/authsvc/${version}/cdr/data/usage/customer/current/`,
  billCycleDataUsage: `/authsvc/${version}/cdr/data/usage/customer/overall/`,
  customerDataUsage: `/authsvc/${version}/data/usage/customer/`,
  customerGroup: `/authsvc/${version}/customers/group/`,
  paRoamingPurchasePack: `/authsvc/${version}/intl/roaming/pack/purchase/customer/`,
  PaRoamingAvailableOfferings: `/authsvc/${version}/get/additional/offerings`,
  port: `/authsvc/${version}/port/validate/`,
  portResult: `/authsvc/${version}/port/validate/result/`,
  checkEmailAvailability: `/authsvc/${version}/customers/availability/`,
  secondaryCustomerUpdate: `/authsvc/${version}/customers/secondary/update`,
  changePlan: `/authsvc/${version}/reachplan/change`,
  PlanChangeCustomerStatus: `/authsvc/${version}/customer/operations/status/`,
  topUpUpdate: `/authsvc/${version}/auto/topup/update`,
  autoTopUp: `/authsvc/${version}/auto/topup/customer/`,
  paymentHistory: `/authsvc/${version}/billing/customize/invoices`,
  dataCutOff: `/authsvc/${version}/data/cutoff/manage`,
  referralCredits: `/authsvc/${version}/coupon/referral/analytics/group/`,
  emailBill: `/authsvc/${version}/customer/send/invoice?emailId=`,
  updateCardExpiry: `/authsvc/${version}/cc/update/`,
  autoPayCreditCard: `/authsvc/${version}/customer/cc/autopay/`,
  removeCreditCard: `/authsvc/${version}/customer/cc/delete/`,
  setDefaultCard: `/authsvc/${version}/customer/cc/update/`,
  billing: `/authsvc/${version}/billing/customer`,
  plumBills: `/authsvc/${version}/billing/customize/bills/`,
  referalDetails: `/authsvc/${version}/coupon/referral/detail`,
  billingForecast: `/authsvc/${version}/billing/forecast/customer/`,
  customerPlan: `/authsvc/${version}/customer/plan`,
  resendEsimKit: `/authsvc/${version}/resend/qr/`,
  currentBillPay: `/authsvc/${version}/payment/customer`,
  getShipmentById: `/authsvc/${version}/shipment/order/customer/`,
  getSettings: `/authsvc/${version}/network/settings/`,
  updateLanguage: `/authsvc/${version}/update/voicemail/language/`,
  voicemailResetPassword: `/authsvc/${version}/customer/rest/voicemail/password/`,
  dataSaver: `/authsvc/${version}/sim/settings/dataSaver/`,
  wifiUpdate: `/authsvc/${version}/wificalling/update/status/`,
  internationalRoming: `/authsvc/${version}/intl/roaming/plans/IR`,
  internationalCalling: `/authsvc/${version}/intl/roaming/plans/ILD`,
  walletPurchaseHistory: `/authsvc/${version}/customer/purchase/credit/history/`,
  walletPurchase: `/authsvc/${version}/customer/purchase/credit`,
  updateRoaming: `/authsvc/${version}/intl/roaming/update`,
  eligibilityCheckApi: `/authsvc/fetch/fetchDiscountCode`,
  sendAPNSettingsPostPurchase: `/authsvc/${version}/ios/att/apn/guidelines/email/send`,
  sendAPNSettingsBeforePurchase: `/authsvc/${version}/ios/att/apn/guidelines/send/email`,
  sendAPNSettingsAndroidPostPurchase: `/authsvc/${version}android/att/apn/guidelines/email/send/`,
  sendAPNSettingsAndroidBeforePurchase: `/authsvc/${version}/android/att/apn/guidelines/send/email`,
  hsdAddApi: `/authsvc/v0/hsd/discount`,
  discountAppliedApi: `/authsvc/v0/hsd/discount/applied/`,
  landingPromo: `/authsvc/${version}/reachplans/`,
  reservePromoCoupon: `/authsvc/${version}/coupon/email/mapping`,
  checkCompatibility: `/authsvc/${version}/imei/info/`,
  appDataApi: `/authsvc/${version}/app/data/`,
  getAllPacks: `/authsvc/${version}/intl/roaming/pack/group/`,
  irAutoRenew: `/authsvc/${version}/intl/roaming/pack/`,
  irDataAddOn: `/authsvc/${version}/intl/roaming/pack/`,
  irGetAvailOfferingId: `/authsvc/${version}/additional/offerings/`,
  deleteFuturePack: `/authsvc/${version}/intl/roaming/pack/`,
  rescheduleFuturePack: `/authsvc/${version}/intl/roaming/pack/`,
  getPackUsageHistory: `/authsvc/${version}/intl/roaming/pack/`,
  getTransactionHistory: `/authsvc/v0/customer/purchase/credit/history/`,
  getILDCredits: `/authsvc/${version}/ild/status/group/`,
  getILDUsageHistory: `/authsvc/${version}/ild/usage/`,
  shopWareAddToCart: `/cart/plan`,
  shopWareUpdateCart: `/store-api/checkout/cart/line-item`,
  shopWareUpdateShippingMethods: `/store-api/checkout/cart/line-item`,
  shopWareGetCart: `/store-api/checkout/cart`,
  shopWareEye4fraud: `/orders/plan/pending`,
  customerInfo: `/authsvc/${version}/customer/info`,
  hsiEligibilityCheckApi: `/authsvc/${version}/partner/hsi/eligibilitycheck/`,
  activateCustomerCouponApply: `/authsvc/${version}/coupon/apply/customer/active`,
  shopWareGetCountries: `/store-api/country`,
  shopWareAuth: `/auth`,
  shopWareDeleteCart: `/store-api/checkout/cart/line-item?ids[]`,
  shopWareCreateAddress: `/store-api/account/address`,
  shopWareDefaultAddressOnShopware: `/store-api/account/address/default-shipping/`,
  shopWareUpdateAddress: `/store-api/account/address/`,
  shopWareEmailUpdate: `/store-api/account/change-email`,
  shopWareGetCustomer: `/store-api/account/customer`,
  shopWareGetAddress: `/store-api/account/list-address`,
  liveOpsMessages: `/authsvc/${version}/search`,
  verifyRecoveryEmail: `/authsvc/${version}/security/action`,
  generateOtp: `/authsvc/${version}/security/action`,
  verifyOtp: `/authsvc/${version}/verify/security/action`,
  sendAccountLockReminder: `/authsvc/${version}/send/account/lock/reminder/`,
  emailBillByNumber: `/authsvc/${version}/customer/send/invoice/number`,
  getInvoice: `/authsvc/${version}/invoice/`,
};

export default ApiEndPoints;
