import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";

type InitialState = {
  reachPlan: any;
  errorReachPlan: any;
  trackOrderStatus: any;
  errorTrackOrderStatus: any;
  shipmentOrderSuccess: any;
  errorShipmentOrder: any;
  getShipmentID: any;
  errorGetShipmentID: any;
  currentOderNo: any;
};
const initialState: InitialState = {
  reachPlan: null,
  errorReachPlan: null,
  trackOrderStatus: null,
  errorTrackOrderStatus: null,
  shipmentOrderSuccess: null,
  errorShipmentOrder: null,
  getShipmentID: null,
  errorGetShipmentID: null,
  currentOderNo: null,
};

export const ReachPlan = createAsyncThunk(
  "trackorder/ReachPlan",
  async (action: any, { rejectWithValue }) => {
    try {
      const planURL = `${ApiEndPoints.reachPlan}${encodeURIComponent(
        action.plan
      )}`;
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.reachPlan}${action.plan}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const trackOrderShipment = createAsyncThunk(
  "trackOrder/trackOrderShipment",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.trackOrderShipment}${action.orderId}?poll=${action.poll}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const getShipmentOrderById = createAsyncThunk(
  "trackOrder/getShipmentOrderById",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "get",
        url: `${ApiEndPoints.getShipmentById}${action.id}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const shipmentOrder = createAsyncThunk(
  "trackOrder/shipmentOrder",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.trackOrderShipmentV2}?doPayment=true`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

const trackorderSlice = createSlice({
  name: "trackorder",
  initialState,
  reducers: {
    refreshOrderNo: (state, action) => {
      state.currentOderNo = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      ReachPlan.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reachPlan = action.payload;
        state.errorReachPlan = null;
      }
    );
    builder.addCase(ReachPlan.rejected, (state, action) => {
      state.reachPlan = null;
      state.errorReachPlan = action.payload || "Something went wrong";
    });
    builder.addCase(
      trackOrderShipment.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.trackOrderStatus = action.payload.data;
        state.currentOderNo = action?.payload?.data?.data?.orderNumber || null;
        state.errorTrackOrderStatus = null;
      }
    );
    builder.addCase(trackOrderShipment.rejected, (state, action) => {
      state.trackOrderStatus = null;
      state.errorTrackOrderStatus = action.error || "Something went wrong";
    });
    builder.addCase(
      shipmentOrder.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.shipmentOrderSuccess = action.payload.data;
        state.errorShipmentOrder = null;
      }
    );
    builder.addCase(shipmentOrder.rejected, (state, action) => {
      state.shipmentOrderSuccess = null;
      state.errorShipmentOrder = action.payload || "Something went wrong";
    });
    builder.addCase(
      getShipmentOrderById.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getShipmentID = action.payload.data;
        state.errorGetShipmentID = null;
      }
    );
    builder.addCase(getShipmentOrderById.rejected, (state, action) => {
      state.getShipmentID = null;
      state.errorGetShipmentID = action.payload || "Something went wrong";
    });
  },
});

export const { refreshOrderNo } = trackorderSlice.actions;
export default trackorderSlice.reducer;
