import styles from "./S1_TrackOrder.module.scss";
import { useNavigate } from "react-router-dom";
import {
  Box,
  CircularProgress,
  Divider,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { ButtonCust, StepperCust } from "../widgets";
import Config from "../../config/env.config.json";
import { pushTrackingEventsToGAAndFB } from "../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../Types/GoogleAnalytics";
import {
  StepperStatus,
  OrderStatus,
} from "../../features/trackorder/trackOrder";
import { CustomerStatus, OperationInProcessState } from "../../enums";
import EnvConfig from "../../config/env.config.json";
import { useAppSelector } from "../../app/hooks";
import { RoutingURLs } from "../../config/RoutingURLs";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { BrandNameUpdate } from "../../utils/commonFunctions/BrandNameUpdate";

const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const T1_TrackOrder = (props: any) => {
  const navigate = useNavigate();
  const {
    content,
    trackOrderData,
    planData,
    customerData,
    shipmentTrackingOpsMessages,
  } = props;
  const GAAndFBEventsHandler = () => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.trackOrder,
      category: gaCategory.activation,
      buttonValue: content.act_now,
    });
  };

  const { trackOrderStatus, currentOderNo } = useAppSelector(
    (state: any) => state?.trackorder || {}
  );
  const onClickHandler = (navigationPath: string, redirectionPage: string) => {
    if (EnvConfig.IS_APN_SETTINGS_DETAILS_SCREEN_NEEDED) {
      navigationPath = RoutingURLs.deviceConfirmCheck;
    }
    if (redirectionPage === "_self") {
      navigate(navigationPath);
    } else {
      window.open(navigationPath, redirectionPage);
    }
    GAAndFBEventsHandler();
  };
  const onArrowBack = () => {
    if (customerData?.additionalLines >= 1) {
      navigate(RoutingURLs.account, {
        state: {
          navigateFromNotification: true,
        },
      });
    } else {
      navigate(RoutingURLs.myAccount);
    }
  };

  const getSteps = () => {
    if (trackOrderData.cId && customerData.id) {
      let time = new Date(trackOrderData.orderDate);
      let time1 = time
        .toLocaleString("en-US", {
          hour: "2-digit",
          minute: "2-digit",
          hour12: true,
        })
        ?.replace(/ /g, "")
        ?.toLowerCase();

      let labeledTime: any = trackOrderData.labelledOn
        ? new Date(trackOrderData.labelledOn)
        : "";
      let labeled1: any = labeledTime
        ? labeledTime
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";

      let dateDelivery: any = trackOrderData.deliveredOn
        ? new Date(trackOrderData.deliveredOn)
        : trackOrderData.estimatedDeliveryDate
        ? new Date(trackOrderData.estimatedDeliveryDate)
        : "";
      let dateDelivery1: any = dateDelivery
        ? dateDelivery
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";

      let shippedTime: any = trackOrderData.shippedOn
        ? new Date(trackOrderData.shippedOn)
        : "";
      let shippedTime1: any = shippedTime
        ? shippedTime
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";
      let cancelledTime: any = trackOrderData.orderCanceledOn
        ? new Date(trackOrderData.orderCanceledOn)
        : "";
      let cancelledTime1: any = cancelledTime
        ? cancelledTime
            .toLocaleString("en-US", {
              hour: "2-digit",
              minute: "2-digit",
              hour12: true,
            })
            ?.replace(/ /g, "")
            ?.toLowerCase()
        : "";
      let stepperObj = [
        {
          label: StepperStatus.OrderReceived,
          text: `${
            months[time.getMonth()]
          } ${time.getDate()}, ${time.getFullYear()}${"\n"}${time1}`,
          time: time1,
          date: `${time.getDate()} ${
            months[time.getMonth()]
          }, ${time.getFullYear()} `,
          simcards: trackOrderStatus?.data?.items
            ?.map((obj: any) => obj?.quantity)
            .reduce((acc: any, cur: any) => acc + cur, 0),
          simCardLabel: `${content.sim_cards_text}`,
          keyProp: 0,
        },
        {
          label: StepperStatus.InProgress,
          text:
            labeledTime && labeled1
              ? `${
                  content.in_pro_text
                }${"\n"} ${labeled1}${"\n"} ${labeledTime.getDate()} ${
                  months[labeledTime.getMonth()]
                }, ${labeledTime.getFullYear()}`
              : `${content.in_pro_text}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 1,
        },
        {
          label: StepperStatus.OrderShipped,
          text:
            shippedTime && shippedTime1
              ? `${content.order_sh_text} ${"\n"} ${shippedTime.getDate()} ${
                  months[shippedTime.getMonth()]
                }, ${shippedTime.getFullYear()}${"\n"}${shippedTime1}`
              : `${content.order_sh_text}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 2,
        },
        {
          label: StepperStatus.DELIVERED,
          text: `${
            months[dateDelivery.getMonth()]
          } ${dateDelivery.getDate()}, ${dateDelivery.getFullYear()}${"\n"}${dateDelivery1}`,
          simCardLabel: null,
          simcards: null,
          keyProp: 3,
        },
      ];
      let finalStepperObj: any[] = [];
      if (trackOrderData.orderStatus === OrderStatus.Cancelled) {
        const cancelledObj = [
          {
            label: StepperStatus.Cancelled,
            text:
              cancelledTime && cancelledTime1
                ? `${content.cancel_txt}${"\n"} ${cancelledTime.getDate()} ${
                    months[cancelledTime.getMonth()]
                  }, ${cancelledTime.getFullYear()}${"\n"}
                  ${cancelledTime1}`
                : `${content.cancel_txt}`,
            simCardLabel: null,
            simcards: null,
            keyProp: 4,
          },
        ];
        if (trackOrderData.lastOrderStatus === OrderStatus.Ordered) {
          finalStepperObj.push(stepperObj[0], ...cancelledObj);
        }
      } else {
        finalStepperObj = [...stepperObj];
      }
      return finalStepperObj;
    } else {
      return [{ label: StepperStatus.OrderReceived }, {}, {}, {}];
    }
  };

  const renderLiveOpsMessages = () => {
    if (shipmentTrackingOpsMessages?.length <= 0) return null;
    return (
      <Typography component="div" sx={{ m: "20px 40px", textAlign: "left" }}>
        <Typography
          component="div"
          sx={{
            color: "var(--primary_brand_color)",
            fontSize: "16px",
            fontFamily: "var(--font_family_Medium)",
          }}
        >
          Note:
        </Typography>
        {shipmentTrackingOpsMessages.map((opsMessage: any) => {
          return (
            <Typography
              component="div"
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "14px",
                wordBreak: "break-all",
              }}
              key={opsMessage.id}
            >
              <Typography
                component="span"
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "inherit",
                }}
              >
                {opsMessage.messageType}:{" "}
              </Typography>
              {opsMessage.message}
            </Typography>
          );
        })}
      </Typography>
    );
  };

  let activeStep: number = 0;
  if (trackOrderData && trackOrderData.cId) {
    activeStep =
      trackOrderData.orderStatus === OrderStatus.Ordered
        ? 0
        : trackOrderData.orderStatus === OrderStatus.Labelled ||
          trackOrderData.orderStatus === OrderStatus.Cancelled
        ? 1
        : trackOrderData.orderStatus === OrderStatus.Shipped
        ? 2
        : trackOrderData.orderStatus === OrderStatus.Delivered
        ? 3
        : 0;
  }
  return (
    <Box>
      <Box
        className={styles.container}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          background: {
            xs: "linear-gradient( var(--white) 97%, var(--primary_brand_color) 0%)",
            sm: "linear-gradient( var(--lightgrey_3) 43.5%, var(--primary_brand_color) 43%)",
          },
        }}
      >
        <Paper
          sx={{
            padding: { sm: "20px 0" },
            margin: {
              xs: "25px 0 50px 0",
              sm: "50px 0",
            },
            boxShadow: {
              xs: "unset",
              sm: "0 0 4px 0 rgb(0 0 0 / 25%)",
            },
            background: "#fff",
            minWidth: { xs: "calc(100% - 40px)", sm: "70%", md: "50%" },
            maxWidth: { sm: "min-content" },
            textAlign: "center",
          }}
        >
          <Box>
            <Box
              display="flex"
              flexDirection="row"
              borderBottom="6px solid var(--white_2)"
              px={{ xs: "0px", sm: "20px" }}
            >
              {EnvConfig.IS_TRACK_ORDER_PAGE_BACK_FUNCTIONALITY_REQD && (
                <ArrowBackIcon
                  sx={{ cursor: "pointer", width: "24px" }}
                  onClick={onArrowBack}
                />
              )}
              <Typography
                component={"h5"}
                variant={"h5"}
                fontSize="18px"
                fontFamily={"var(--font_family_Bold)"}
                pb={{ xs: "25px", sm: "20px" }}
                textAlign="center"
                width="100%"
                mr={
                  EnvConfig.IS_TRACK_ORDER_PAGE_BACK_FUNCTIONALITY_REQD
                    ? "24px"
                    : "0px"
                }
              >
                {content.title}
              </Typography>
            </Box>
            <Divider
              sx={{
                bgcolor: "var(--lightgrey_2)",
                opacity: "0.5",
              }}
            />
            <Box textAlign={{ xs: "center" }}>
              <Box
                sx={{
                  width: "250px",
                  height: "195px",
                }}
                mt="30px"
                mb="15px"
                component="img"
                src={content?.image?.data?.attributes?.url || ""}
                alt={content?.image?.data?.attributes?.alternativeText}
              />
              {planData &&
              planData.displayName &&
              customerData &&
              customerData.id ? (
                <Typography
                  component={"h5"}
                  variant={"h5"}
                  fontSize="18px"
                  color="var(--text_color_2)"
                  fontFamily={"var(--font_family_Bold)"}
                  pb={{ xs: "10px", sm: "5px" }}
                  textAlign={{ xs: "center" }}
                  fontWeight={"var(--font_weight_2)"}
                >
                  {`${planData.displayName} ${
                    content.for_text
                      ? `${content.for_text} ${customerData.noOfSimsOrdered}`
                      : ""
                  }`}
                </Typography>
              ) : null}
              <Box
                sx={{
                  fontFamily: "var(--font_family_Medium)",
                  fontSize: "14px",
                  fontWeight: "var(--font_weight_2)",
                  fontStretch: "normal",
                  fontStyle: "normal",
                  lineHeight: "1.43",
                  letterSpacing: " normal",
                  textAlign: "center",
                }}
              >
                {content.order}: &nbsp; 
                {!trackOrderStatus ? (
                  <Box component="span" className={styles.order_circle}>
                    <CircularProgress />
                  </Box>
                ) : (
                   trackOrderStatus?.data?.orderNumber
                )}
              </Box>

              <Box display="flex" justifyContent="center">
                <StepperCust
                  activeStep={activeStep}
                  steps={getSteps()}
                  stepOneExtraContentTR={true}
                  orderStatus={trackOrderData.orderStatus}
                  {...props}
                  sx={{ padding: "24px", textAlign: "center" }}
                />
              </Box>
              <>
                {EnvConfig?.IS_MSG_CENTER_ENABLED
                  ? renderLiveOpsMessages()
                  : null}
              </>
              {trackOrderData?.orderStatus !== OrderStatus.Cancelled && (
                <>
                  {content?.res?.home_btn && activeStep !== 3 ? (
                    <Box>
                      <ButtonCust
                        variantType={Config.PRIMARY_BUTTON_TYPE}
                        onClick={() => {
                          if (
                            customerData?.isPrimary &&
                            customerData?.status === CustomerStatus.ACTIVE
                          ) {
                            navigate(RoutingURLs.postActivationHome);
                          } else {
                            navigate(content?.res?.home_btn_url);
                          }
                        }}
                      >
                        {content?.res?.home_btn}
                      </ButtonCust>
                    </Box>
                  ) : EnvConfig.TRACK_ORDER_ACTIVATE_BTN ? (
                    <Box>
                      <ButtonCust
                        disabled={activeStep === 3 ? false : true}
                        variantType={Config.PRIMARY_BUTTON_TYPE}
                        onClick={() =>
                          onClickHandler(RoutingURLs.activation, "_self")
                        }
                      >
                        {content.btn_text}
                      </ButtonCust>
                    </Box>
                  ) : (
                    <>
                      {activeStep === 3 && (
                        <Box mb="20px">
                          <ButtonCust
                            variantType={Config.PRIMARY_BUTTON_TYPE}
                            onClick={() =>
                              onClickHandler(RoutingURLs.activation, "_self")
                            }
                          >
                            {content.btn_text}
                          </ButtonCust>
                        </Box>
                      )}
                    </>
                  )}
                  {trackOrderData?.orderStatus !== OrderStatus.Delivered && (
                    <>
                      <Box
                        sx={{
                          fontFamily: "var(--font_family_Medium)",
                          fontSize: "14px",
                          fontWeight: "var(--font_weight_0)",
                          letterSpacing: " normal",
                          textAlign: "center",
                          color: "var(--text_color_5)",
                        }}
                        mt="20px"
                      >
                        {content.rec_kit}
                      </Box>
                      <Box
                        textAlign={{ xs: "center" }}
                        sx={{
                          fontSize: "14px",
                          fontFamily: "var(--font_family_Bold)",
                          fontWeight: "var(--font_weight_5)",
                          cursor:
                            customerData?.operationsInProcess?.includes(
                              OperationInProcessState.paymentCallback
                            ) || !customerData?.simPaid
                              ? "not-allowed"
                              : "pointer",
                          color: "var(--primary_color)",
                          pt: "5px",
                          opacity:
                            customerData?.operationsInProcess?.includes(
                              OperationInProcessState.paymentCallback
                            ) || !customerData?.simPaid
                              ? 0.5
                              : 1,
                        }}
                        onClick={() => {
                          if (
                            !customerData?.operationsInProcess?.includes(
                              OperationInProcessState.paymentCallback
                            ) &&
                            customerData?.simPaid
                          ) {
                            onClickHandler(RoutingURLs.activation, "_self");
                          }
                        }}
                      >
                        {content.act_now}
                      </Box>
                    </>
                  )}
                  {Config.FREE_SHIPPING_ACTIVATED &&
                  customerData?.status === CustomerStatus.INACTIVE &&
                  content?.track_img?.data?.attributes?.url ? (
                    <>
                      <Box
                        sx={{
                          width: "40px",
                          height: "35px",
                        }}
                        mt="30px"
                        mb="10px"
                        component="img"
                        src={content?.track_img?.data?.attributes?.url || ""}
                        alt={
                          content?.track_img?.data?.attributes?.alternativeText
                        }
                      />
                      <Box
                        sx={{
                          fontFamily: "var(--font_family_Semibold)",
                          fontSize: "14px",
                          fontWeight: "var(--font_weight_0)",
                          letterSpacing: " normal",
                          lineHeight: "21px",
                          textAlign: "center",
                          color: "var(--text_color_5)",
                          margin: {
                            xs: "0 18px 0",
                            sm: "0 10% 0",
                            md: "0 16% 0",
                          },
                        }}
                      >
                        {BrandNameUpdate(content.act_your_line)}
                      </Box>
                    </>
                  ) : null}
                </>
              )}
            </Box>
            {trackOrderData?.orderStatus === OrderStatus.Delivered &&
            (content?.res?.order_deliver_note ||
              content?.res?.order_deliver_desc) ? (
              <Box
                sx={{
                  paddingX: { xs: "10px", md: "85px" },
                  lineHeight: "24px",
                }}
              >
                {content?.res?.order_deliver_note ? (
                  <Box
                    sx={{
                      fontFamily: "var(--font_family_Medium)",
                      fontSize: "14px",
                      fontWeight: "var(--font_weight_0)",
                      letterSpacing: " normal",
                      textAlign: "left",
                      color: "var(--primary_color)",
                    }}
                    mt="20px"
                  >
                    {content?.res?.order_deliver_note}
                  </Box>
                ) : null}
                {content?.res?.order_deliver_desc ? (
                  <Box
                    textAlign={{ xs: "left" }}
                    sx={{
                      fontSize: "14px",
                      fontFamily: "var(--font_family_Medium)",
                      fontWeight: "var(--font_weight_0)",
                      cursor: "pointer",
                      pt: "5px",
                    }}
                    dangerouslySetInnerHTML={{
                      __html: BrandNameUpdate(content?.res?.order_deliver_desc),
                    }}
                  />
                ) : null}
              </Box>
            ) : null}
          </Box>
          {/* <Box className={styles.share_reach}>
            {BrandNameUpdate(content.share_reach)}
            <Box component={"span"}>
              {content.reach_credits}
              <ArrowRightIcon className={styles.triangle} />
            </Box>
          </Box> */}
        </Paper>
      </Box>
    </Box>
  );
};

export default T1_TrackOrder;
