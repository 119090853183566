import { Box, Stack, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { ButtonCust, SimpleModal, TextFieldCust } from "../../widgets";
import { sha512 } from "js-sha512";
import EnvConfig from "../../../config/env.config.json";

type Props = { setIsVerified: any };

const serverName: any = process.env.REACT_APP_SERVER_NAME;

export const isDevServer =
  serverName.includes("dev") ||
  serverName.includes("qa") ||
  serverName.includes("stage") ||
  serverName.includes("prod");

export const PasswordAuthenticator = (props: Props) => {
  const { setIsVerified } = props;
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [showModal, setShowModal] = useState(true);

  const checkPw = (e: any) => {
    e.preventDefault();
    if (password === "") {
      setPasswordError("Password cannot be empty");
    } else if (sha512(password) === process.env.REACT_APP_SITE_PROTECT) {
      setIsVerified(true);
      localStorage.setItem("website_auth", sha512(password));
      setShowModal(false);
    } else {
      setPassword("");
      setPasswordError("Password is incorrect");
    }
  };

  useEffect(() => {
    let authVal = localStorage.getItem("website_auth");
    if (authVal === process.env.REACT_APP_SITE_PROTECT) setIsVerified(true);
  }, []);

  const closeModalHandler = () => {};
  const onChangeHandler = (e: any) => {
    setPasswordError("");
    setPassword(e.target.value);
  };
  return (
    <SimpleModal
      isShowModal={showModal}
      onCloseModal={() => closeModalHandler()}
      showClose={false}
      sx={{
        width: {
          xs: "95%",
          sm: "70%",
          md: "80%",
          lg: "45%",
          xl: "30%",
          padding: "30px",
        },
        borderRadius: "4px",
      }}
    >
      <Stack
        sx={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <Typography
          component="h3"
          sx={{
            fontSize: { xs: "18px", sm: "28px" },
            fontFamily: "var(--font_family_Bold)",
            fontWeight: "var(--font_weight_custom2)",
            mb: "20px",
            color: "var(--title-color_1)",
          }}
        >
          This page is password protected
        </Typography>
        <Box
          onKeyDown={(e: any) => {
            if (e.key === "Enter") {
              checkPw(e);
            }
          }}
          sx={{ width: "100%", maxWidth: "430px", mb: "35px" }}
        >
          <TextFieldCust
            value={password}
            type="password"
            label={"Password"}
            onChange={(e): any => onChangeHandler(e)}
            error={passwordError !== ""}
            helperText={passwordError}
          />
        </Box>
        <ButtonCust
          sx={{
            width: { xs: "200px", sm: "250px" },
            fontFamily: "var(--font_family_Medium)",
            fontSize: "18px",
            fontWeight: "var(--font_weight_2)",
          }}
          onClick={checkPw}
          variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
        >
          Submit
        </ButtonCust>
      </Stack>
    </SimpleModal>
  );
};
