import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import Config from "../../../../config/app.config.json";
import { getCC } from "../../../../features/account/accountSlice";
import { monthInAdvance } from "../../../../features/activation/activationSlice";
import { isTemplateExisits } from "../../../../utils/commonFunctions";
import EnvConfig from "../../../../config/env.config.json";
import { GetCustomer } from "../../../../features/checkout/checkoutSlice";

const PayComp = isTemplateExisits(Config.Activation.PayNow)
  ? require(`./${Config.Activation.PayNow.template}_payNow`).default
  : null;

export default function ActivationPayNow(props: any) {
  const { currentLine, manageCardFg } = props;
  const [autoPayCard, setAutoPayCard] = useState<any>({});
  const dispatch = useAppDispatch();
  const { getCCDetails } = useAppSelector((state: any) => state?.account || {});
  const {
    getCustomer
  } = useAppSelector((state: any) => state.checkout);
  const { monthInAdvanceData } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  useEffect(() => {
    if (getCustomer === null) {
      const customerDetail: any = JSON.parse(
        localStorage?.getItem("customerDetail") || "null"
      );
      dispatch(
        GetCustomer({
          username: encodeURIComponent(customerDetail?.emailId),
        })
      )
    }
  }, [])
  useEffect(() => {
    if (getCCDetails) {
      getCCDetails.map((card: any) => {
        if (card.isAutoPay) {
          setAutoPayCard(card);
          return null;
        } else {
          return null;
        }
      });
    }
  }, [getCCDetails]);
  useEffect(() => {
    let addCardActivation = localStorage.getItem("addCardActivation");
    if ((!getCCDetails || !monthInAdvanceData) && getCustomer) {
      const customerDetail: any = JSON.parse(
        localStorage?.getItem("customerDetail") || "null"
      );
      if (
        !EnvConfig.DISPLAY_ADD_NEW_CARD_LOADER_DIALOG ||
        (EnvConfig.DISPLAY_ADD_NEW_CARD_LOADER_DIALOG && !addCardActivation)
      ) {
        if (customerDetail?.id) {
          dispatch(getCC({ customerId: customerDetail?.id }));
        }
      }
      const py: any = {
        customerId: currentLine?.id,
      };
      dispatch(monthInAdvance({ payload: py })).then((res) => {
        if (res?.payload?.status === 200) {
          if (addCardActivation) {
            manageCardFg();
            localStorage.removeItem("addCardActivation");
          }
        }
      });
    }
  }, [getCustomer]);
  return PayComp ? <PayComp {...props} autoPayCard={autoPayCard} /> : <></>;
}
