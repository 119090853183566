import { CheckCoverageProps } from "./CheckCoverage";
import { AutoComplete } from "../../widgets";
import { Box, Button, CircularProgress } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./S3_CheckCoverage.module.scss";

const CheckCoverageComponent = (props: CheckCoverageProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    checkCoverageHandler,
    coverageContent,
    showCoverage,
    checkAddress,
    coverageLoading,
    coverageAddress,
    setShowCoverage,
    signal4G,
    signal5G,
    coverageError,
    navigateCheckCoverage,
  } = props;

  const {
    heading,
    sub_heading,
    place_holder,
    signal_note1,
    signal_note2,
    button_1,
    button_2,
    error,
    coverage_1,
    coverage_2,
    signal_strength1,
    signal_strength2,
    signal_strength3,
    signal_strength4,
    signal_strength5,
    close_icon,
    tick_icon,
  } = coverageContent || {};

  return (
    coverageContent && (
      <Box className={styles.check_coverage_outer}>
        <Box className={styles.coverage_check_heading}>{heading}</Box>
        <Box className={styles.coverage_check_subheading}>{sub_heading}</Box>
        {!showCoverage && (
          <Box className={styles.address_holder}>
            <AutoComplete
              className={styles.auto_complete}
              value={checkAddress}
              onChange={checkAddressHandler}
              onSelect={(e: any, placeId: string) => {
                handleSelect(placeId);
              }}
              disabled={coverageLoading}
              placeholder={place_holder}
              autoComplete={"off"}
            />

            <Button
              variant="outlined"
              className={styles.submit_btn}
              startIcon={
                coverageLoading ? (
                  <CircularProgress sx={{ color: "var(--white) !important" }} />
                ) : (
                  <ArrowForwardIcon
                    sx={{
                      fontSize: "43px !important",
                      fontWeight: "var(--font_weight_0)",
                    }}
                  />
                )
              }
              onClick={() => checkCoverageHandler(coverageAddress)}
              disabled={checkAddress === "" ? true : false}
            ></Button>
          </Box>
        )}
        {showCoverage && (
          <Box>
            {signal4G !== "no service" || signal5G !== "no service" ? (
              <Box className={styles.coverage_final_text}>
                <Box
                  component={"img"}
                  sx={{ marginRight: "10px", verticalAlign: "middle" }}
                  src={tick_icon?.data?.attributes?.url}
                  alt={tick_icon?.data?.attributes?.alternativeText}
                />
                <Box component={"span"}>
                  {coverage_1}
                  {signal4G === "fair"
                    ? signal_strength1
                    : signal4G === "good"
                    ? signal_strength2
                    : signal4G === "great"
                    ? signal_strength3
                    : signal_strength4}
                </Box>
                <Box component={"span"}>
                  {" "}
                  {coverage_2}
                  {signal5G === "fair"
                    ? signal_strength1
                    : signal5G === "good"
                    ? signal_strength2
                    : signal5G === "great"
                    ? signal_strength3
                    : signal_strength5}
                </Box>
                <Box sx={{ margin: "0px 0px 16px 48px" }}>{signal_note1}</Box>
              </Box>
            ) : (
              <Box className={styles.coverage_final_text}>
                <Box
                  component={"img"}
                  src={close_icon?.data?.attributes?.url}
                  alt={close_icon?.data?.attributes?.alternativeText}
                />
                <Box component={"span"}>{signal_note2}</Box>
              </Box>
            )}
            <Box className={styles.coverage_buttons}>
              <Button
                className={styles.button_text}
                onClick={() => setShowCoverage(false)}
              >
                {button_1}
              </Button>
              <Button
                className={styles.button_text}
                onClick={navigateCheckCoverage}
              >
                {button_2}
              </Button>
            </Box>
          </Box>
        )}

        {coverageError && <Box className={styles.coverage_error}>{error}</Box>}
      </Box>
    )
  );
};

export default CheckCoverageComponent;
