import { Box, CircularProgress, Typography } from "@mui/material";
import styles from "./ReEligibilityModal.module.scss";
import {
  ButtonCust,
  Divider,
  SimpleModal,
  TextFieldCust,
} from "../../../widgets";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useAppSelector } from "../../../../app/hooks";

type propsEligible = {
  isOpen: any;
  setIsOpen: any;
  modalContent: any;
  variantType: any;
  onClick: any;
  customStyles?: any;
  isLoading: any;
};

const ReEligibilityModal = (props: propsEligible) => {
  const {
    setIsOpen,
    isOpen,
    modalContent,
    variantType,
    onClick,
    customStyles,
    isLoading,
  } = props;
  const { title, description, buttonText, loading, icon, isFailed } =
    modalContent;
  let couponDetails = JSON.parse(
    localStorage.getItem("couponDetails") || "null"
  );

  couponDetails = couponDetails?.discountInDollar;
  const { reEligibilityCouponData } = useAppSelector((state) => state.checkout);

  const successDescription = description?.replace(
    /HSD_DISCOUNT/g,
    GetCurrencyFixedToTwo(reEligibilityCouponData?.discountInDollar)
  );

  return (
    <SimpleModal
      isShowModal={isOpen}
      onCloseModal={() => setIsOpen(false)}
      showClose={isLoading ? false : true}
      sx={{ top: "20%", borderRadius: "6px", width: "60%", ...customStyles }}
    >
      {isLoading ? (
        <CircularProgress />
      ) : (
        <Box className={styles.container}>
          {icon && (
            <img
              width={40}
              height={40}
              src={icon.data.attributes.url}
              alt={icon.data.attributes.alternativeText}
            />
          )}
          <Typography className={styles.title}>{title}</Typography>

          <Typography className={styles.desc}>{successDescription}</Typography>
          <ButtonCust
            sx={{ width: "100%" }}
            variantType={variantType}
            onClick={isFailed ? onClick : () => setIsOpen(false)}
            disabled={loading}
          >
            {loading ? <CircularProgress /> : buttonText}
          </ButtonCust>
        </Box>
      )}
    </SimpleModal>
  );
};

export default ReEligibilityModal;
