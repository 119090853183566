import { useEffect, useState } from "react";
import TagManager from "react-gtm-module";
import firebase from "./adapters/firebase";
import "./App.css";
import { useAppDispatch } from "./app/hooks";
import EnvConfig from "./config/env.config.json";
import {
  getCartItems,
  getCustomerFromShopware,
  shopwareAuth,
} from "./features/shopWare/shopWareSlice";
import { notificationsContent } from "./features/strapi/componentSlice";
import { globalValContent } from "./features/strapi/globalValuesSlice";
import AppRoutes from "./routes/AppRoutes";
import { shopwareAuthentication } from "./utils/commonFunctions/shopWareFunctions";
import { PasswordAuthenticator, isDevServer } from "./components";
function App() {
  const dispatch = useAppDispatch();
  useEffect(() => {
    if (
      !(
        localStorage.getItem("accessToken") &&
        localStorage.getItem("currentuser")
      )
    ) {
      firebase
        .auth()
        .signInAnonymously()
        .then(async (user) => {
          await localStorage.setItem(
            "currentuser",
            JSON.stringify(user && user.user)
          );
          let user1: any = await localStorage.getItem("currentuser");
          user1 = JSON.parse(user1);
          let token =
            user1 &&
            user1.uid &&
            user1.stsTokenManager &&
            user1.stsTokenManager.accessToken;
          await localStorage.setItem("accessToken", token);
        })
        .catch((error) => {
          console.log(error);
        });
    }
    dispatch(globalValContent());
    dispatch(notificationsContent());
    const tagManagerArgs = {
      gtmId: process.env.REACT_APP_GOOGLE_TAG_MANAGER_KEY || "",
    };
    TagManager.initialize(tagManagerArgs);
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      let currentuser: any = localStorage.getItem("currentuser");
      currentuser = JSON.parse(currentuser);
      let searchParams = new URLSearchParams(document.location.search);
      let logoutCheck: string | null = searchParams.get("logout");
      if (!logoutCheck) {
        dispatch(getCartItems());
        if (currentuser && currentuser.email) {
          fetchShopwareUser();
        }
      }
    }
  }, []);
  const fetchShopwareUser = () => {
    dispatch(getCustomerFromShopware())
      .then((_res: any) => {})
      .catch((_err: any) => {
        handleLoginShopware();
      });
  };
  const handleLoginShopware = () => {
    let payloadData: any = shopwareAuthentication();
    if (payloadData) {
      dispatch(shopwareAuth(payloadData));
    }
  };
  const [isVerified, setIsVerified] = useState(false);
  let websiteAuth: any = localStorage.getItem("website_auth");
  return (
    <>
      {isDevServer && EnvConfig?.WEBSITE_PASSWORD_AUTHENTICATION ? (
        isVerified || websiteAuth ? (
          <AppRoutes />
        ) : (
          <PasswordAuthenticator setIsVerified={setIsVerified} />
        )
      ) : (
        <AppRoutes />
      )}
    </>
  );
}

export default App;
