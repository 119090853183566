import { KeyboardArrowDown, KeyboardArrowUp } from "@mui/icons-material";
import {
  Box,
  Card,
  ClickAwayListener,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import _ from "lodash";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import unlimitedPlanDiscount from "../../../config/discountCouponsRegular.json";
import EnvConfig from "../../../config/env.config.json";
import { RoutingURLs } from "../../../config/RoutingURLs";
import { planModalType } from "../../../features/checkout/Types";
import { GetCurrencyFixedToTwo } from "../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { gtmTagManager } from "../../../utils/commonFunctions/GtmTagManager";
import {
  caclulatePlanCostBasedOnEligibility,
  renderTextStyles,
} from "../../../utils/commonFunctions/ReusableFunctions";
import { NutritionLabel } from "../../home/PlanCard/NutritionLabel/NutritionLabel";
import { ButtonCust, CustToolTip, SimpleModal } from "../../widgets";
import { AddLine_ChangePlanTemplateProps } from "./AddLine_ChangePlan";
import { BrandNameUpdate, getCache } from "../../../utils";
import { PlanSectionContent } from "../../../features/strapi/homePageSlice";
import { getConfigKeys } from "../../../features/activation/activationSlice";
import styles from "./S5_AddLine_ChangePlan.module.scss";
import { Coupon, updatePlanDetails } from "../../../features/checkout/checkoutSlice";
import clsx from "clsx";
const T5_AddLine_ChangePlan = (props: AddLine_ChangePlanTemplateProps) => {
  // const theme = useTheme();
  // const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const {
    isShowModal,
    modalType,
    setOpenModal,
    plandata,
    index,
    formik,
    handleChangePlan,
    handleAddLine,
    content,
    currentPlan,
    amendedReachPlanId,
    initialCount = 0,
    isEsim,
    currentLine,
    isAcount,
    planContent,
  } = props;
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    fade_out,
    home_page_plan_card,
    tag,
    wrapper_div,
    mb_img,
    title,
    separator,
    save_money_area,
    save_money_img,
    cost,
    plan_cost_data,
    cost1,
    plan_page_offer,
  } = styles;
  const { heading1, heading2 } = content;

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const { globalVal } = useAppSelector(
    (state: any) => state?.strapi?.globalValues || {}
  );
  const { coupon, isLoaded } = useAppSelector((state: any) => state.checkout);
  const {
    planDetails,
  } = useAppSelector((state: any) => state.checkout);
  const dispatch = useAppDispatch();
  // const [cacheDataPlanSec, setCacheDataPlanSec] = useState<any>(null);
  // const { planCardContent } = useAppSelector(
  //   (state: any) => state.strapi.homepage
  // );

  // useEffect(() => {
  //   getCache("PlanSecHome", PlanSectionContent, setCacheDataPlanSec, dispatch);
  // }, []);
  const [showAnswer, setShowAnswer] = useState<any>(true);
  let presentPlan: any = localStorage.getItem("reachSelectedPlanDetail");
  presentPlan = JSON.parse(presentPlan);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };
  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);

  useEffect(() => {
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    // setUser(customerDetail);
    let ssoAccessToken: any = localStorage.getItem("ssoAccessToken");
    if (ssoAccessToken) {
      // setIsSSOToken(true);
    }
    let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerInfo");
    ssoCustomerInfo = JSON.parse(ssoCustomerInfo);
    if (
      !coupon &&
      ssoCustomerInfo?.customer_eligibility &&
      ssoCustomerInfo?.promo_code
    ) {
      dispatch(
        Coupon({
          couponCode: `${ssoCustomerInfo?.promo_code.toUpperCase()}`,
        })
      );
    }
  }, []);
  const getPlanCost = (plan: any, selectedLine: any=planDetails?.numberOfLines) => {
    let planCost: any;
    let primaryDiscountInDollar: any = coupon?.discountInDollar;
    let secondaryDiscountInDollar: any = coupon?.secondaryDiscountInDollar;
    if (plan?.addLineSplitPrice && selectedLine > 1) {
      let linePrice1 = 0;
      for (let i = 1; i <= selectedLine - 1; i++) {
        linePrice1 = linePrice1 + plan?.addLineSplitPrice[i];
      }
      planCost =
        plan?.baseLinePrice -
        primaryDiscountInDollar +
        (linePrice1 - secondaryDiscountInDollar * (selectedLine - 1));
    } else {
      planCost =
        plan?.baseLinePrice -
        primaryDiscountInDollar +
        (selectedLine - 1) *
          (plan?.additionalLinePrice - secondaryDiscountInDollar);
    }
    return (
      planCost ||
      plan?.baseLinePrice + (selectedLine - 1) * plan?.additionalLinePrice
    );
  };

  const [nutritionLabel, setNutritionLabel] = useState({
    show: true,
    name: "",
  });

  const selectPlanHandler = (newPlan: any) => {
    // const lines = parseInt(customerDetails.extraLines) + 1;
    if (isAcount) {
      handleChangePlan(newPlan);
    } else {
      localStorage.setItem(
        "reachSelectedPlanDetail",
        JSON.stringify({
          numberOfLines: planDetails?.numberOfLines,
          plan: newPlan,
        })
      );
      localStorage.setItem(
        "reachSelectedPlanLine",
        String(planDetails?.numberOfLines)
      );
      dispatch(
        updatePlanDetails({
          numberOfLines: planDetails?.numberOfLines,
          selectedPlan: newPlan,
        })
      );
    }
    onClose();
  };

  const onClose = () => {
    setOpenModal(false);
  };

  const calculateUnlimitedCostMnM = (
    data: any,
    selectedLine: any,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateCost = (
    data: any,
    selectedLine: any = 1,
    isRounded: boolean = true
  ) => {
    const SELECTED_LINE = selectedLine;
    const planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    let planPerLinesCost = 0;
    if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
    else planPerLinesCost = planCost / SELECTED_LINE;
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const calculateUnlimitedCost = (
    data: any,
    selectedLine: any = 1,
    isRounded?: boolean
  ) => {
    const SELECTED_LINE = selectedLine;

    let planCost = 0;
    let planPerLinesCost = 0;
    if (data?.addLineSplitPrice) {
      let linePrice1 = 0;
      for (let i = 1; i <= SELECTED_LINE - 1; i++) {
        linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
      }
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
      planPerLinesCost = planCost / SELECTED_LINE;
    } else {
      planCost =
        ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
        (SELECTED_LINE - 1) * data?.additionalLinePrice;
      planPerLinesCost = planCost / SELECTED_LINE;
    }
    if (isRounded) {
      planCost = Math.ceil(planCost);
      planPerLinesCost = Math.ceil(planPerLinesCost);
    }
    return {
      planCost,
      planPerLinesCost,
    };
  };
  const priceCalculationHandler: any = (data: any, selectedLine: any = 1) => {
    let PlanDiscountCost, planPerLinesCost: any;
    if (data.isUnlimited) {
      const { planCost } = calculateUnlimitedCostMnM(data, selectedLine, false);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data.discountInDollar * selectedLine
      );
    } else {
      const { planCost } = calculateCost(data, selectedLine);
      PlanDiscountCost = caclulatePlanCostBasedOnEligibility(
        planCost,
        unlimitedPlanDiscount.data.discountInDollar * selectedLine
      );
    }
    planPerLinesCost = PlanDiscountCost / selectedLine;
    return planPerLinesCost;
  };
  return (
    <SimpleModal
      isShowModal={isShowModal}
      onCloseModal={onClose}
      showClose={true}
      sx={{
        width: { xs: "90%", sm: "100%", md: "100%", lg: "99%", xl: "85%" },
        boxShadow:
          "0px 0px 2px 0px rgba(79, 94, 113, 0.12), 0px 2px 4px 0px rgba(79, 94, 113, 0.11), 0px 4px 8px 0px rgba(79, 94, 113, 0.10), 0px 8px 16px 0px rgba(79, 94, 113, 0.09), 0px 16px 32px 0px rgba(79, 94, 113, 0.08), 0px 32px 64px 0px rgba(79, 94, 113, 0.07)",
        borderRadius: "9px",
      }}
    >
      <Typography sx={renderTextStyles(1, "24px", "title-color_1", "36px")}>
        {modalType === planModalType.changePlan ? heading1 : heading2}
      </Typography>
      <Box
        sx={{
          display: { xs: "flex", md: "flex" },
          justifyContent: { xs: "center", md: "space-evenly" },
          overflow: { xs: "scroll", sm: "initial" },
          flexWrap: { xs: "nowrap", sm: "wrap" },
          m: "20px auto",
          p: "4px",
          gap: { xs: "20px", sm: "0px" },
          width: "100%",
        }}
      >
        {plandata?.map((p: any, i: any) => {
         let planCost: any = getPlanCost(p);
          // const planPerLinesCost = priceCalculationHandler(p);
          return (
            <Card
              id={`home${index}`}
              key={p.displayName}
              raised
              // elevation={isMobile ? 0 : 8}
              // key={index}
              className={clsx(
                home_page_plan_card,
                plan_page_plan_card
                // borderColorAdd ? borderColorAdd : "",
                // isCurrentPlan ? current_plan : ""
              )}
              sx={{
                borderRadius: "14px",
                minWidth: "235px",
                p: "20px 7px",
                width: "15%",
                boxShadow: "none !important",
                overflow: {
                  xs: nutritionLabel.show ? "scroll" : "revert",
                  sm: nutritionLabel.show ? "scroll" : "unset",
                },
                position: {
                  xs:
                    presentPlan?.plan?.displayName === p.displayName
                      ? "relative"
                      : "initial",
                  sm: "unset",
                },
                border:
                  presentPlan?.plan?.displayName === p.displayName
                    ? "1px solid var(--primary_color)"
                    : "1px solid var(--plan_card_border_color)",
                height: "435px",
                maxHeight: "450px",
                "&::-webkit-scrollbar": {
                  display: "none",
                },
                "&-ms-overflow-style:": {
                  display: "none",
                },
              }}
            >
              {presentPlan?.plan?.displayName === p.displayName ? (
                <Box
                  sx={{
                    mt: { xs: "-8px", sm: "-32px" },
                    display: "flex",
                    justifyContent: "center",
                    width: "100%",
                    pb: "32px",
                    position: { xs: "absolute", sm: "unset" },
                  }}
                >
                  <Typography
                    component="div"
                    sx={{
                      backgroundColor: "var(--white)",
                      position: "absolute",
                      display: "flex",
                      alignItems: "center",
                      top: { xs: "-20px", sm: "unset" },
                      gap: "2px",
                      px: "5px",
                    }}
                  >
                    <Box
                      component="img"
                      alt={
                        planContent?.tick_icon?.data?.attributes
                          ?.alternativeText
                      }
                      src={planContent?.tick_icon?.data?.attributes?.url || ""}
                      width="17px"
                    />
                    <Typography
                      component="span"
                      sx={_.merge(
                        renderTextStyles(3, "14px", "medium-green", "21px"),
                        {
                          fontStyle: "italic",
                        }
                      )}
                    >
                      {planContent?.current_plan}
                    </Typography>
                  </Typography>
                </Box>
              ) : null}
              <Box>
                <Box className={styles.top_container}>
                  <Box className={title}>{p.displayName}</Box>
                  <Box
                    display="flex"
                    alignItems={"center"}
                    justifyContent={"center"}
                  >
                    <Box>
                      {p?.isUnlimited && planContent?.u_plan_img ? (
                        <>
                          <Box
                            component={"img"}
                            alt={BrandNameUpdate(
                              planContent?.u_plan_img?.data?.attributes
                                ?.alternativeText
                            )}
                            src={planContent?.u_plan_img?.data?.attributes?.url}
                          />
                          &nbsp;
                        </>
                      ) : !p?.isUnlimited && planContent?.s_plan_img ? (
                        <>
                          <Box
                            component={"img"}
                            alt={BrandNameUpdate(
                              planContent?.s_plan_img?.data?.attributes
                                ?.alternativeText
                            )}
                            src={planContent?.s_plan_img?.data?.attributes?.url}
                          />
                          &nbsp;
                        </>
                      ) : null}
                    </Box>
                    <Box>
                      <Box
                        sx={{
                          fontSize: "21px",
                          fontWeight: "bold",
                          lineHeight: "25px",
                        }}
                      >
                        {p?.planData}
                        {p?.dataUnit}
                      </Box>
                    </Box>
                    <Box
                      sx={{
                        color: "var(--grey33-color)",
                        fontSize: "15px",
                        fontWeight: "var(--h4_font_weight)",
                      }}
                    >
                      {p?.isUnlimited ? (
                        <>&nbsp;{planContent?.u_p_text}</>
                      ) : (
                        <>&nbsp;{planContent?.s_p_text}</>
                      )}
                    </Box>
                  </Box>
                  <Box className={separator}></Box>
                  <Box
                    className={save_money_area}
                    style={{
                      opacity: p.isBestFitAllowed ? 1 : 0.2,
                      display: "none",
                    }}
                  >
                    <Box
                      component={"img"}
                      src={planContent?.shareable_image?.data?.attributes?.url}
                      alt={
                        planContent?.shareable_image?.data?.attributes
                          ?.alternativeText
                      }
                      className={save_money_img}
                    />
                    {planContent?.save_money}
                  </Box>
                  <Box className={plan_cost_data}>
                    <Box component={"span"} className={cost}>
                      {GetCurrencyFixedToTwo(
                        planCost ? planCost : 0,
                        p.currency ? p.currency : EnvConfig.CURRENCY
                      )}
                    </Box>
                    <Box component={"span"} className={cost1}>
                      {planContent?.per_money_text}
                    </Box>
                  </Box>
                </Box>
                <Box className={styles.bottom_container}>
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      // mb: "30px",
                      minHeight: "var(--plan_card_bottom_section_min_height)"
                        ? "var(--plan_card_bottom_section_min_height)"
                        : "135px",
                    }}
                  >
                    {planContent?.icon_offers &&
                      planContent?.icon_offers?.map(
                        (item: any, featureIndex: any) => {
                          const hideItem = planContent?.hide_feature_item?.find(
                            (sItem: any) =>
                              sItem?.name?.toLowerCase() ===
                              item?.offer?.toLowerCase()
                          );
                          return !hideItem?.indexes?.includes(index) ||
                            !hideItem?.name === item?.offer?.toLowerCase() ? (
                            <Box
                              key={featureIndex}
                              sx={{
                                display: "flex",
                                alignItems: "center",
                                p: "10px 0px 0px",
                              }}
                            >
                              <Box>
                                <Box
                                  component={"img"}
                                  alt=""
                                  src={item?.image?.data?.attributes?.url}
                                  sx={{ width: "16px" }}
                                />
                              </Box>
                              <Box className={plan_page_offer}>
                                {item?.offer}
                              </Box>
                            </Box>
                          ) : null;
                        }
                      )}
                  </Box>
                </Box>
              </Box>
              {!currentLine?.reachPlanChangeDate &&
              amendedReachPlanId === p.name ? (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      padding: "5px",
                      margin: "15px 0px",
                      backgroundColor: "var(--light_primary_color)",
                    }}
                  >
                    <Typography
                      sx={{
                        fontSize: "10px",
                        color: "var(--primary_color)",

                        fontWeight: "var(--font_weight_1)",
                        textAlign: "center",
                        width: "fit-content",
                        maxWidth: "130px",
                        fontFamily: "var(--font_family_Medium)",
                      }}
                    >
                      {content?.plan_features?.new_plan_update}
                    </Typography>
                  </Box>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    my: "15px",
                  }}
                >
                  <ButtonCust
                    variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
                    data-testid="selectPlanHandler"
                    onClick={(e: any) => {
                      selectPlanHandler(p);
                      let eventPrefix =
                        location.pathname === RoutingURLs.checkout
                          ? globalVal?.gtmEvents?.changeSelectPlan
                          : location.pathname === RoutingURLs.activation
                          ? isEsim
                            ? "mobile_dashboard_esim_"
                            : "mobile_dashboard_psim_"
                          : "";
                      gtmTagManager({
                        event: `${eventPrefix}${p.planData}${p.dataUnit}_select_plan`,
                        click_section: "checkout",
                      });
                    }}
                    sx={{
                      "&:disabled": {
                        backgroundColor: "var(--primary_color) !important",
                        opacity: "30%",
                        border: "none !important",
                      },
                    }}
                    disabled={
                      modalType === planModalType.changePlan &&
                      presentPlan?.plan?.displayName === p.displayName
                        ? true
                        : false
                    }
                  >
                    {planContent?.button_text}
                  </ButtonCust>
                </Box>
              )}
              <Divider
                sx={{
                  borderColor: "var(--cascading-white)",
                  mb: "10px",
                  mx: { xs: "-10px", sm: "-10px" },
                }}
              />
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  mx: "auto",
                  gap: "2px",
                  cursor: "pointer",
                  width: "max-content",
                }}
                onClick={() => {
                  setNutritionLabel((prevState) => ({
                    ...prevState,
                    show: prevState.show ? false : true,
                    name: p?.name,
                  }));
                }}
              >
                <Typography
                  component={"span"}
                  sx={_.merge(
                    renderTextStyles(2, "14px", "primary_color", "21px"),
                    {
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }
                  )}
                >
                  {planContent?.broadband}
                </Typography>
                {nutritionLabel.show ? (
                  <KeyboardArrowUp sx={{ color: "var(--primary_color)" }} />
                ) : (
                  <KeyboardArrowDown sx={{ color: "var(--primary_color)" }} />
                )}
              </Box>
              {nutritionLabel.show ? (
                <NutritionLabel
                  {...{
                    plandata,
                    priceCalculationHandler,
                  }}
                  plandata={p}
                  selectedLine={1}
                  NLContent={planContent}
                  showAnswer={showAnswer}
                  handleAnswer={handleAnswer}
                  isheading={false}
                  planCost={planCost}
                />
              ) : null}
            </Card>
          );
        })}
      </Box>
    </SimpleModal>
  );
};
export default T5_AddLine_ChangePlan;
