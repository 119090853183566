export type InitialState = {
  loginNumberSuccess: any;
  errorLoginNumber: any;
  getInTouch: any;
  userData: any;
  resetPasswordData: any;
  errorResetPasswordData: any;
  stateCityByZip: any;
  errorStateCityByZipcode: any;
  getCustomer: any;
  errorGetCustomer: any;
  createCustomer: any;
  errorCreateCustomer: any;
  getCustomerInfo: any;
  errorGetCustomerInfo: any;
  updateCustomer: any;
  errorUpdateCustomer: any;
  errorPlanTaxCharges: any;
  errorOneTimeSimTaxCharges: any;
  errorOneTimeShippingTaxCharges: any;
  planDetails: IPlanDetails;
  formValues: IFormValues;
  updateCustomerSource: any;
  reEligibilityCouponData:any;
  errorUpdateCustomerSource: any;
  coupon: any;
  errorCoupon: any;
  couponReserve: any;
  errorCouponReserve: any;
  eligibility: any;
  errorEligibility: any;
  isLoaded: boolean;
  isLoadedShippingTaxes: boolean;
  isLoadedPlanTaxCharges: boolean;
  oneTimeSIMTaxCharges: any;
  oneTimeShippingTaxCharges: any;
  planTaxCharges: null | number;
  referrelCoupon: any;
  buttonActionLoader: boolean;
  isRenderLogin: boolean;
  primaryUser: null;
  secondaryUser: null;
  errorGetCouponReserve: any;
  isPrimaryLineEsim: boolean;
  hsdAdd: any;
  hsdAddError: any;
  landingPromoPlan: any;
  errorLandingPromoPlan: any;
  isPendingSIMVerification: boolean;
  errorInSIMVerification: boolean;
  isSIMVerificationSuccess: boolean;
  getAppData: any;
  getAppDataError: any;
};

export interface IPlanDetails {
  numberOfLines: null | number;
  selectedPlan: any;
}

export interface IFormValues {
  firstName: string;
  lastName: string;
  phone: string;
  shipmentPlan: string;
  isShippingSameAsBilling: true;
  billingAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    type: addressType.billing;
  };
  shippingAddress: {
    address1: string;
    address2: string;
    city: string;
    country: string;
    state: string;
    zip: string;
    type: addressType.shipping;
  };
}
export interface IAddress {
  address1: string;
  address2?: string;
  city: string;
  country: string;
  state: string;
  zip: string;
  type?: addressType.shipping;
}
export enum addressType {
  billing = "billing",
  shipping = "shipping",
}
export enum TaxTypes {
  sim = 1,
  plan = 2,
  shipment = 23,
  IR = 5,
  ILD = 8,
  UpgradePlan = 14,
  activation = 11,
}

export enum SimTypes {
  sim = "Sim",
  eSim = "eSim",
  phySim = "PHYSICAL_SIM",
  ESIM = "ESIM",
  simInHAND = "SIM_IN_HAND",
}

export enum planModalType {
  addLine = "add_line",
  changePlan = "change_plan",
}

export interface GetInTouchRequestPayload {
  data: {
    emailId: string;
    hubspotMap: {
      email?: string;
      firstname?: string;
      lastname?: string;
      imei_page_imei_entered?: string;
      is_test?: boolean;
      is_qa?: boolean;
      feedback_form_authorize_share?: boolean;
      feedback_form_experience_rating?: string;
      feedback_form_source?: string;
      website_purchase_payment_failed_reason?: any;
      website_purchase_payment_success?: any;
      bill_page_number_of_lines?: any;
      bill_page_usage_reported?: any;
      bill_page_amount_reported?: any;
      bill_page_recommended_plan?: any;
      bill_page_was_plan_recommended?: boolean;
      website_logged_in?: boolean;
      website_login_date?: any;
      login_provider?: string; // (“email, facebook, google, apple”)
      plan_selected?: any;
      website_journey_score?: number;
      website_number_of_lines?: number;
      address?: string;
      city?: string;
      state?: string;
      zip?: number | string;
      country?: string;
      web_address_validation_failed?: boolean | string;
      sim_in_hand?: boolean;
      website_purchase_failed?: boolean;
      device_imei?: string;
      device_make?: string;
      device_model?: string;
      website_imei_incompatibility_reason?: string;
      imei_page_supported_carriers?: any;
      downtime_error_message?: string;
      imei_checked_in_downtime?: boolean;
      downtime_api_call?: boolean;
      is_auto_pay?: boolean;
      referral_send_reminder?: boolean;
      brand_name?: string;
      global_account_id?: string;
    };
  };
}

export enum ACCOUNT_LOCK_STATUS {
  ACC_LOCK = "ACC_LOCK",
  ACC_UNLOCK = "ACC_UNLOCK",
  SIM_SWAP = "SIM_SWAP",
  PORT_OUT = "PORT_OUT",
  RECOVERY_MAIL = "RECOVERY_MAIL",
}
