import InfoIcon from "@mui/icons-material/Info";
import ReportProblemOutlinedIcon from "@mui/icons-material/ReportProblemOutlined";
import TaskAltIcon from "@mui/icons-material/TaskAlt";
import WarningAmberOutlinedIcon from "@mui/icons-material/WarningAmberOutlined";
import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { differenceInDays, isDate } from "date-fns";
import { useEffect, useState } from "react";
import { Store } from "react-notifications-component";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import envConfig from "../../../../config/env.config.json";
import { Coupon, CouponReserve, GetCustomer, UpdateCustomerSource } from "../../../../features/checkout/checkoutSlice";
import { BrandNameUpdate } from "../../../../utils";
import Config from "../../../../config/env.config.json";
import {
  ButtonCust,
  CustToolTip,
  NotificationCust,
  SimpleModal,
  SwitchCust,
} from "../../../widgets";
import { AccountLockModal } from "./AccountLockModal";
import { activateCustomerCouponApplyApi, hsiEligibilityCheckApi } from "../../../../features/account/accountSlice";
import { hsiReEligibilityStatus } from "../../../../enums";
import ReEligibilityModal from "./ReEligibilityModal";

const T1_AccountInfo = (props: any) => {
  const { content: fccAccountContent, hsiContent } = props; 
  const [isReEligibility, setIsReEligibility] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hideReEligibility, setHideReEligibility] = useState(true);
  const fccAccountImages = props;
  let customerDetails: any = JSON.parse(localStorage.getItem("customerDetail") || "null");
  const recoveryEmail = customerDetails?.recoveryEmail ?? undefined;
  const recoveryEmailVerified = customerDetails?.recEmailVerified ?? false;

  const { configInfo } = useAppSelector((state: any) => state.account);
  const { getCustomer } = useAppSelector((state: any) => state.checkout);

  const [modalContent, setModalContent] = useState({
    icon: "",
    title: "",
    description: "",
    buttonText: "",
    loading: isLoading,
    isFailed: false,
  });

  const [showAccountLockModal, setShowAccountLockModal] = useState(false);
  const [addRecoveryEmailModal, setAddRecoveryEmailModal] = useState(false);
  const [profileUpdateLoading, setProfileUpdateLoading] = useState(false);
  const [updateRecoveryEmailModal, setUpdateRecoveryEmailModal] =
    useState(false);
  const [hardNudgeModal, setHardNudgeModal] = useState(
    customerDetails &&
    (recoveryEmail === undefined || !recoveryEmailVerified) &&
    customerDetails.meta &&
    customerDetails.meta.recoveryEmail &&
    !customerDetails.meta.recoveryEmail.app_nudge_alert &&
    customerDetails.meta.recoveryEmail.app_alert_date &&
    isDate(new Date(customerDetails.meta.recoveryEmail.app_alert_date)) &&
    differenceInDays(
      new Date(),
      new Date(customerDetails.meta.recoveryEmail.app_alert_date)
    ) >
    (configInfo?.configs?.DAYS_TO_SHOW_HARD_NUDGE_FOR_RECOVERY_EMAIL ?? 15)
  );
  const { coupon } = useAppSelector((state: any) => state.checkout);
  let customerDetail: any = localStorage.getItem("customerDetail");
  customerDetail = JSON.parse(customerDetail);

  useEffect(() => {
    if(!coupon){
      dispatch(Coupon({ couponCode: customerDetail?.meta?.appliedCoupon }));
    }
  }, []);

  const reEligibilitySubmitHandler = async () => { 
    try {
      setIsLoading(true);
      setIsReEligibility(true);

      const checkAndDispatch = async (thunk: any, payload: any) => {
        const couponPayload = {
          customerId: customerDetails?.id,
          couponId: customerDetails?.meta.appliedCoupon,
        };
        const response = await dispatch(thunk(payload));
        if (
          response?.payload?.data?.data?.status ===
          hsiReEligibilityStatus.eligible
        ) {
          setModalContent({
            icon: hsiContent?.plan_now_img,
            title: hsiContent?.reEligibilitySuccess?.title,
            description: hsiContent?.reEligibilitySuccess?.desc,
            buttonText: hsiContent?.reEligibilitySuccess?.btn,
            loading: isLoading,
            isFailed: false,
          });
          await dispatch(CouponReserve(couponPayload));
          await dispatch(activateCustomerCouponApplyApi(couponPayload));
          await dispatch(
            GetCustomer({
              username: encodeURIComponent(
                customerDetails && customerDetails.emailId
              ),
            })
          );
        } else if (
          response?.payload?.data?.data?.status ===
          hsiReEligibilityStatus.notEligible
        ) {
          const retryCount = response?.payload?.data?.data?.retry_count;
          const maxTryCount =
            response?.payload?.data.data.HSI_ELIGIBILITY_ALLOWED_COUNT_APP_WEB;
          setModalContent({
            icon: hsiContent?.info,
            title: hsiContent?.reEligibilityStatus?.title,
            description: hsiContent?.reEligibilityStatus?.desc1,
            buttonText: `${hsiContent?.reEligibilityStatus?.btn} ${retryCount}/${maxTryCount}`,
            loading: isLoading,
            isFailed: true,
          });
        } else if (
          response?.payload?.status === hsiReEligibilityStatus.failure
        ) {
          const apiResponseTime = response?.payload.meta.TILL_LOCK_TIME;
          setHideReEligibility(false);
          localStorage.setItem("expirationTime", apiResponseTime);
          setTimeout(() => {
            setHideReEligibility(true);
            localStorage.removeItem("expirationTime");
          }, apiResponseTime * 1000); 
          setModalContent({
            icon: hsiContent?.info,
            title: hsiContent?.reEligibilityStatus?.title,
            description: hsiContent?.reEligibilityStatus?.desc2,
            buttonText: hsiContent?.reEligibilitySuccess?.btn,
            loading: isLoading,
            isFailed: false,
          });
        }
        return response;
      };

      // Dispatch and check each thunk
      await checkAndDispatch(hsiEligibilityCheckApi, {
        UUID: customerDetails.peerAccountRef,
      });
      setIsLoading(false);
    } catch (error) {
      // Handle errors
      setModalContent({
        icon: hsiContent?.plan_now_img,
        title: hsiContent?.reEligibilitySuccess?.title,
        description: hsiContent?.reEligibilitySuccess?.desc,
        buttonText: hsiContent?.reEligibilitySuccess?.btn,
        loading: isLoading,
        isFailed: false,
      });
      setIsLoading(false);
    }
  };

  const line = () => {
    return (
      <Grid item xs={12} textAlign="right">
        <Typography
          sx={{
            borderBottom: " 2px solid var(--navy)",
            opacity: 0.1,
            margin: "15px 0px 15px",
          }}
        ></Typography>
      </Grid>
    );
  };

  const dispatch = useAppDispatch();

  const updateCustomer = async (data: any) => {
    setProfileUpdateLoading(true);

    await dispatch(
      UpdateCustomerSource({
        customerId: customerDetails?.id,
        data: { ...data },
      })
    );
    Store.removeNotification("RecoveryEmailNotification");

    setProfileUpdateLoading(false);
  };

  // useEffect(() => {
  //   if (
  //     customerDetails &&
  //     (recoveryEmail === undefined || !recoveryEmailVerified) &&
  //     ((customerDetails.meta &&
  //       customerDetails.meta.recoveryEmail &&
  //       !customerDetails.meta.recoveryEmail.app_alert) ||
  //       !customerDetails.meta ||
  //       !customerDetails.meta.recoveryEmail)
  //   ) {
  //     NotificationCust({
  //       message: (
  //         <Box
  //           sx={{
  //             fontSize: "14px",
  //             fontFamily: "var(--font_family_Semibold)",
  //             display: "flex",
  //             gap: "20px",
  //             color: "black",
  //           }}
  //           onClick={(e) => e.stopPropagation()}
  //         >
  //           <WarningAmberOutlinedIcon />
  //           <Typography
  //             component="div"
  //             sx={{
  //               display: "flex",
  //               flexDirection: "column",
  //               alignItems: "flex-start",
  //             }}
  //           >
  //             <Typography
  //               sx={{
  //                 fontWeight: "var(--font_weight_2)",
  //                 fontSize: "16px",
  //               }}
  //             >
  //               {fccAccountContent?.header1}
  //             </Typography>
  //             <Typography
  //               style={{
  //                 lineHeight: "1.5",
  //                 textAlign: "left",
  //                 fontSize: "13px",
  //               }}
  //             >
  //               {BrandNameUpdate(fccAccountContent?.desc1)}
  //             </Typography>
  //           </Typography>
  //           <ButtonCust
  //             variantType={envConfig.PRIMARY_BUTTON_TYPE}
  //             onClick={(e) => {
  //               e.stopPropagation();
  //               setAddRecoveryEmailModal(true);
  //               setShowAccountLockModal(true);
  //             }}
  //             sx={{
  //               width: "200px",
  //               height: "40px !important",
  //               alignSelf: "flex-end",
  //               "&:hover": {
  //                 backgroundColor: "var(--button_hover_color) !important",
  //                 border: "1px solid var(--button_hover_color) !important",
  //               },
  //             }}
  //           >
  //             {fccAccountContent?.action_add}
  //           </ButtonCust>
  //         </Box>
  //       ),
  //       type: "warning",
  //       duration: 0,
  //       id: "RecoveryEmailNotification",
  //       showIcon: true,
  //       onRemoval: (id, _) => {
  //         const meta = {
  //           recoveryEmail: {
  //             app_alert: true,
  //             app_alert_date: new Date(),
  //           },
  //         };
  //         updateCustomer({ meta });
  //       },
  //     });
  //   } else {
  //     Store.removeNotification("RecoveryEmailNotification");
  //   }
  //   return () => Store.removeNotification("RecoveryEmailNotification");
  // }, [getCustomer]);

  return (
    <Box
      sx={{
        mt: "0px",
        mb: "2rem",
        display: { xs: "block", sm: "flex" },
        justifyContent: "center",
        flexDirection: "column",
        alignItems: "center",
        mx: { xs: "auto", sm: "1rem", md: "auto" },
        width: { xs: "95%", sm: "95%", md: "800px" },
      }}
    >
      <ReEligibilityModal
        isOpen={isReEligibility}
        setIsOpen={setIsReEligibility}
        modalContent={modalContent}
        variantType={Config.PRIMARY_BUTTON_TYPE}
        onClick={reEligibilitySubmitHandler}
        isLoading={isLoading}
      />
      <Box
        sx={{
          boxShadow: "0px 4px 6px 0px rgba(0, 0, 0, 0.15)",
          backgroundColor: "var(--white)",
          borderRadius: "8px",
          height: "auto",
          width: "100%",
          padding: "20px",
        }}
      >
        {/* ROW 1 */}
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
          }}
        >
          <Box>
            {/* Name & Plan */}
            <Box
              sx={{
                display: { xs: "block", sm: "flex" },
                alignItems: "center",
                color: "var(--text_color)",
                fontFamily: "var(--font_family_Semibold)",
                fontSize: "16px",
                textAlign: { xs: "center", sm: "left" },
              }}
            >
              <Typography
                component="div"
                sx={{
                  width: "200px",
                  height: "50px !important",
                  color: "var(--primary_color)",
                  fontFamily: "var(--font_family_Semibold)",
                  fontSize: "24px",
                }}
              >
                {fccAccountContent?.header2}
              </Typography>
            </Box>
          </Box>
        </Box>
        {/* ROW 1 */}
        {line()}
        {/* ROW 2 */}
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
            paddingY: "6px",
          }}
        >
          {
            <Grid
              item
              sx={{
                "&.MuiGrid-root": {
                  "&.MuiGrid-item": {
                    pl: "0px",
                    pt: "0px",
                  },
                },
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "16px",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "10px" },
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  // justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    sm: "0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "var(--text_color)",
                    textAlign: "center",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {fccAccountContent?.sh1}
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography
                  sx={{
                    color: "var(--text_color)",
                    textAlign: "center",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {customerDetails?.emailId ?? ""}
                </Typography>
              </Box>
            </Grid>
          }
        </Box>
        {/* ROW 2 */}
        {line()}
        {/* ROW 3 */}
        {/* <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
            paddingY: "6px",
          }}
        >
          {
            <Grid
              item
              sx={{
                "&.MuiGrid-root": {
                  "&.MuiGrid-item": {
                    pl: "0px",
                    pt: "0px",
                  },
                },
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "16px",
                flexDirection: { xs: "column", sm: "row" },
                gap: { xs: "10px" },
              }}
            >
              <Box
                sx={{
                  display: { xs: "block", sm: "flex" },
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    sm: "0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    display: "flex",
                    color: "var(--text_color)",
                    textAlign: "center",
                    fontFamily: "var(--font_family_Semibold)",
                  }}
                >
                  {fccAccountContent?.sh2}{" "}
                  <CustToolTip
                    title={BrandNameUpdate(fccAccountContent?.tooltip1)}
                    disableFocusListener
                    arrow
                    placement="bottom"
                    sx={{
                      padding: "2px",
                      width: "290px",
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "var(--black) !important",
                          color: "var(--white) !important",
                          lineHeight: "20px !important",
                          fontSize: "14px !important",
                          padding: "8px !important",
                        },
                      },
                      arrow: {
                        sx: {
                          "::before": {
                            backgroundColor: "var(--black) !important",
                          },
                        },
                      },
                    }}
                  >
                    <InfoIcon
                      sx={{
                        color: "var(--primary_color)",
                        marginLeft: "3px",
                        cursor: "pointer",
                      }}
                    />
                  </CustToolTip>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                {!recoveryEmail || !recoveryEmailVerified ? (
                  <Typography
                    sx={{
                      color: true
                        ? "var(--danger_notification)"
                        : "var(--text_color)",
                      textAlign: "center",
                      fontFamily: "var(--font_family_Semibold)",
                      display: "flex",
                      cursor: "pointer",
                    }}
                    onClick={() => {
                      setAddRecoveryEmailModal(true);
                      setShowAccountLockModal(true);
                    }}
                  >
                    <ReportProblemOutlinedIcon
                      sx={{
                        marginRight: "3px",
                      }}
                    />{" "}
                    {fccAccountContent?.action_add}
                  </Typography>
                ) : (
                  <>
                    <Typography
                      sx={{
                        color: "var(--text_color)",
                        textAlign: "center",
                        fontFamily: "var(--font_family_Semibold)",
                        display: "flex",
                      }}
                    >
                      <TaskAltIcon
                        sx={{ marginRight: "3px", color: "var(--success)" }}
                      />{" "}
                      {recoveryEmail}
                    </Typography>
                    <Typography
                      sx={{
                        color: "var(--primary_color)",
                        fontFamily: "var(--font_family_Semibold)",
                        textDecoration: "underline",
                        marginLeft: "15px",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        setUpdateRecoveryEmailModal(true);
                        // setShowAccountLockModal(true);
                      }}
                    >
                      {fccAccountContent?.change}
                    </Typography>
                  </>
                )}
              </Box>
            </Grid>
          }
        </Box> */}
        {line()}
        {/* ROW 4 */}
        <Box
          sx={{
            display: { xs: "block", sm: "flex" },
            justifyContent: "space-between",
            paddingY: "6px",
          }}
        >
          {
            <Grid
              item
              sx={{
                "&.MuiGrid-root": {
                  "&.MuiGrid-item": {
                    pl: "0px",
                    pt: "0px",
                  },
                },
                display: "flex",
                justifyContent: "space-between",
                width: "100%",
                fontSize: "16px",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "center",
                  alignItems: "center",
                  padding: {
                    sm: "0px",
                  },
                }}
              >
                <Typography
                  sx={{
                    color: "var(--text_color)",
                    textAlign: "center",
                    fontFamily: "var(--font_family_Semibold)",
                    display: "flex",
                  }}
                >
                  {fccAccountContent?.sh3}{" "}
                  <CustToolTip
                    title={fccAccountContent?.tooltip2}
                    disableFocusListener
                    arrow
                    placement="bottom"
                    sx={{
                      padding: "2px",
                      width: "290px",
                    }}
                    componentsProps={{
                      tooltip: {
                        sx: {
                          backgroundColor: "var(--black) !important",
                          color: "var(--white) !important",
                          lineHeight: "20px !important",
                          fontSize: "14px !important",
                          padding: "8px !important",
                        },
                      },
                      arrow: {
                        sx: {
                          "::before": {
                            backgroundColor: "var(--black) !important",
                          },
                        },
                      },
                    }}
                  >
                    <InfoIcon
                      sx={{
                        color: "var(--primary_color)",
                        marginLeft: "3px",
                        cursor: "pointer",
                      }}
                    />
                  </CustToolTip>
                </Typography>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <SwitchCust
                  styles={{
                    mr: "0px",
                  }}
                  checked={
                    customerDetails?.accAccessLock?.length > 0
                  }
                  handleSwitchToggle={(e: any) => {
                    setShowAccountLockModal(true);
                  }}
                />
              </Box>
            </Grid>
          }
        </Box>
      </Box>
      {(showAccountLockModal || updateRecoveryEmailModal) && (
        <AccountLockModal
          display={showAccountLockModal || updateRecoveryEmailModal}
          closeHandler={() => {
            setAddRecoveryEmailModal(false);
            setShowAccountLockModal(false);
            setUpdateRecoveryEmailModal(false);
          }}
          primaryEmail={customerDetails?.email ?? ""}
          recoveryEmail={recoveryEmail}
          recoveryEmailVerified={recoveryEmailVerified}
          addRecoveryEmailOnly={addRecoveryEmailModal}
          updateRecoveryEmailModal={updateRecoveryEmailModal}
        />
      )}
      {hardNudgeModal && (
        <SimpleModal
          isShowModal={hardNudgeModal}
          onCloseModal={() => {
            setHardNudgeModal(false);
            const meta = {
              recoveryEmail: {
                app_nudge_alert: true,
              },
            };
            updateCustomer({ meta });
          }}
          showClose
          sx={{ width: { xs: "95%", sm: "50%", md: "45%", lg: "33%" } }}
          style={{ borderRadius: "4px" }}
        >
          <>
            <Box display="flex" alignItems="center">
              {
                <img
                  src={fccAccountImages?.img1?.data?.attributes?.url}
                  alt={"account-lock"}
                />
              }
            </Box>
            <Typography
              sx={{
                fontSize: "22px",
                color: "var(--text_color)",
                fontWeight: "var(--font_weight_4)",
              }}
            >
              {fccAccountContent?.m1h1}
            </Typography>
            <Typography
              pt={2}
              sx={{
                fontSize: "14px",
                color: "var(--text_color)",
                textAlign: "center",
                fontFamily: "var(--font_family_Semibold)",
              }}
            >
              {fccAccountContent?.m1d1}
            </Typography>
            <Box pt={3} sx={{ width: "100%" }}>
              <ButtonCust
                variant="contained"
                variantType={envConfig.PRIMARY_BUTTON_TYPE}
                onClick={async () => {
                  setHardNudgeModal(false);
                  const meta = {
                    recoveryEmail: {
                      ...customerDetails.meta.recoveryEmail,
                      app_nudge_alert: true,
                    },
                  };
                  updateCustomer({ meta });
                }}
                loading={profileUpdateLoading}
                sx={{ width: "100%" }}
              >
                {fccAccountContent?.m1a1}
              </ButtonCust>
            </Box>
            <Typography
              component="div"
              sx={{
                fontSize: { xs: "13px", sm: "16px" },
                color: "var(--primary_color)",
                mt: "25px",
                cursor: "pointer",
              }}
              onClick={() => {
                setHardNudgeModal(false);
                setAddRecoveryEmailModal(true);
                setShowAccountLockModal(true);
              }}
            >
              {fccAccountContent?.action_add}
            </Typography>
          </>
        </SimpleModal>
      )}
      {!customerDetails?.hsdPlan && envConfig?.HSI_FEATURE_REELIGIBILITY && (
        <Typography
          sx={{
            fontFamily: "var(--font_family_Medium)",
            fontSize: { xs: "1em", sm: "16px" },
            marginTop: "25px",
            mx: { xs: "10px", md: "0px" },
            textAlign: { xs: "center", md: "left" },
          }}
        >
          {hsiContent?.re_eligibilityTxt} 
          <a
            style={{ color: "var(--primary_color)", cursor: "pointer" }}
            onClick={reEligibilitySubmitHandler}
          >
            {" "}
            {hsiContent?.re_eligibilityLink} 
          </a>
        </Typography>
      )}
    </Box>
  );
};
export default T1_AccountInfo;
