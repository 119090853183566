import { useEffect, useState } from "react";
import Config from "../../../config/app.config.json";
import { useAppDispatch, useAppSelector } from "../../../app/hooks";
import {
  activationIntroContent,
  activationStep1Content,
  activationStep2Content,
  activationStep3Content,
  activationStep4Content,
} from "../../../features/strapi/activationSlice";
import { pushTrackingEventsToGAAndFB } from "../../../utils/commonFunctions/GaAndFb";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../Types/GoogleAnalytics";
import { PhoneNoPattern } from "../../../utils/regexPatterns";
import { useNavigate } from "react-router-dom";
import { UpdateCustomerSource } from "../../../features/checkout/checkoutSlice";
import { CustomerStatus as CS } from "../../../enums";
import { isTemplateExisits } from "../../../utils/commonFunctions/mapTemplatesToPage";
import { getCache } from "../../../utils/commonFunctions/getCacheFunction";
import { useNonInitialEffect } from "../../../utils/commonFunctions/UseNonInitialEffectHook";
import { addMultipleCacheData } from "../../../utils/commonFunctions/addMultipleCacheData";
import { getSimPrefernceContent } from "../../../features/strapi/checkoutSlice";
import EnvConfig from "../../../config/env.config.json";
import { RoutingURLs } from "../../../config/RoutingURLs";

const ActivationStepsComponent = isTemplateExisits(
  Config.Activation.ActivationFlow
)
  ? require(`./${Config.Activation.ActivationFlow.template}_activation`).default
  : null;

export const ActivationSteps = () => {
  const navigate = useNavigate();
  const [cacheActivationIntro, setCacheActivationIntro] = useState<any>(null);
  const [cacheStep1, setCacheStep1] = useState<any>(null);
  const [cacheStep2, setCacheStep2] = useState<any>(null);
  const [cacheStep3, setCacheStep3] = useState<any>(null);
  const [cacheStep4, setCacheStep4] = useState<any>(null);
  const [cacheSimPrefenceContent, setCacheSimPrefenceContent] = useState();
  const [bckBtnClick, setBckBtnClick] = useState(true);
  const { simPreferenceContent } = useAppSelector(
    (state: any) => state.strapi?.checkout || {}
  );

  const {
    activationIntroData,
    activationStep1Data,
    activationStep2Data,
    activationStep3Data,
    activationStep4Data,
  } = useAppSelector((state: any) => state.strapi.activation);
  const { primaryUser, secondaryUser } = useAppSelector(
    (state: any) => state.checkout || {}
  );
  const dispatch = useAppDispatch();
  useEffect(() => {
    getCache(
      "IntroActivation",
      activationIntroContent,
      setCacheActivationIntro,
      dispatch
    );
    getCache(
      "Step1Activation",
      activationStep1Content,
      setCacheStep1,
      dispatch
    );
    getCache(
      "Step2Activation",
      activationStep2Content,
      setCacheStep2,
      dispatch
    );
    getCache(
      "Step3Activation",
      activationStep3Content,
      setCacheStep3,
      dispatch
    );
    getCache(
      "Step4Activation",
      activationStep4Content,
      setCacheStep4,
      dispatch
    );
    getCache(
      "SimPreferenceContent",
      getSimPrefernceContent,
      setCacheSimPrefenceContent,
      dispatch
    );
  }, []);
  useNonInitialEffect(() => {
    addMultipleCacheData("IntroActivation", activationIntroData);
    addMultipleCacheData("Step1Activation", activationStep1Data);
    addMultipleCacheData("Step2Activation", activationStep2Data);
    addMultipleCacheData("Step3Activation", activationStep3Data);
    addMultipleCacheData("Step4Activation", activationStep4Data);
    addMultipleCacheData("SimPreferenceContent", simPreferenceContent);
  }, [
    activationIntroData,
    activationStep1Data,
    activationStep2Data,
    activationStep3Data,
    activationStep4Data,
    simPreferenceContent,
  ]);

  const gaHandler = (buttonValue: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: gaCategory.activation,
      buttonValue: buttonValue,
    });
  };

  const updatingCustomer = (py: any) => {
    dispatch(
      UpdateCustomerSource({
        customerId: secondaryUser ? secondaryUser.id : primaryUser?.id,
        data: py,
      })
    );
  };
  let currentCustomer: any = secondaryUser ? secondaryUser : primaryUser;

  let loginCheck: any = localStorage.getItem("currentuser");
  loginCheck = JSON.parse(loginCheck) || null;

  const numChangeHandler = (e: any, formik: any) => {
    if (PhoneNoPattern.test(e.target.value) || e.target.value === "") {
      formik.handleChange(e);
    }
  };
  useEffect(() => {
    currentCustomer?.status !== CS.INACTIVE &&
      currentCustomer?.status !== CS.PORT_CANCELLED &&
      navigate(RoutingURLs.postActivationHome);
  }, [currentCustomer]);

  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.activation,
      category: category,
      buttonValue: value,
    });
  };
  return (currentCustomer?.status === CS.INACTIVE ||
    currentCustomer?.status === CS.PORT_CANCELLED) &&
    (cacheActivationIntro || activationIntroData) &&
    (cacheStep1 || activationStep1Data) &&
    (cacheStep2 || activationStep2Data) &&
    (cacheStep3 || activationStep3Data) &&
    (cacheStep4 || activationStep4Data) ? (
    <ActivationStepsComponent
      gaHandler={gaHandler}
      numChangeHandler={numChangeHandler}
      currentLine={currentCustomer}
      updatingCustomer={updatingCustomer}
      bckBtnClick={bckBtnClick}
      setBckBtnClick={setBckBtnClick}
      activationIntroContent={
        cacheActivationIntro ? cacheActivationIntro : activationIntroData
      }
      step1Content={cacheStep1 ? cacheStep1 : activationStep1Data}
      step2Content={cacheStep2 ? cacheStep2 : activationStep2Data}
      step3Content={cacheStep3 ? cacheStep3 : activationStep3Data}
      step4Content={cacheStep4 ? cacheStep4 : activationStep4Data}
      simPrefenceContent={
        cacheSimPrefenceContent ? cacheSimPrefenceContent : simPreferenceContent
      }
      GAAndFBEventsHandler={GAAndFBEventsHandler}
    />
  ) : null;
};
