import { Box, Typography } from "@mui/material";
import styles from "./IncDecButton.module.scss";
import { Remove, Add } from "@mui/icons-material";
import EnvConfig from "../../../config/env.config.json";
interface IncDecButtonProps {
  unit?: string;
  step: number;
  maxValue: number;
  minValue: number;
  value: number;
  countHandler: Function;
  customStyles?: any;
  isUnitPlural?: boolean;
  s?: any;
  updateCartItems?: any;
  removeIncDecIcon?: boolean;
}

export const IncDecButtonCmp = ({
  unit,
  step,
  maxValue,
  minValue,
  value,
  countHandler,
  customStyles,
  isUnitPlural,
  updateCartItems,
  removeIncDecIcon,
}: IncDecButtonProps) => {
  const handleLineSelection = (actionType: string) => {
    if (value < maxValue && actionType === "add") {
      value = value + step;
      countHandler(value);
    }
    if (value > minValue && actionType === "sub") {
      value = value - step;
      countHandler(value);
      if (EnvConfig.IS_SHOPWARE_ENABLED) updateCartItems();
    }
  };
  return (
    <Box
      display="flex"
      component="span"
      justifyContent="center"
      alignItems="center"
      color="var(--primary_color)"
    >
      {!removeIncDecIcon && (
        <Remove
          onClick={() => handleLineSelection("sub")}
          className={
            value <= minValue
              ? `${styles.line_count_button} ${styles.button_disable}`
              : `${styles.line_count_button}`
          }
        />
      )}
      <Typography
        sx={{ fontFamily: "var(--font_family_Semibold)", width: "max-content" }}
        className={styles.inputValue}
        style={
          customStyles && customStyles.inputStyles
            ? customStyles.inputStyles
            : {}
        }
      >
        {value}
        {unit
          ? isUnitPlural
            ? value === 1
              ? `${unit}`
              : `${unit}s`
            : unit
          : ""}
      </Typography>
      {!removeIncDecIcon && (
        <Add
          onClick={() => handleLineSelection("add")}
          className={
            value === maxValue
              ? `${styles.line_count_button} ${styles.button_disable}`
              : `${styles.line_count_button}`
          }
        />
      )}
    </Box>
  );
};
