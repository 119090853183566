import { CheckCoverageProps } from "./CheckCoverage";
import _ from "lodash";
import { Box, Button, CircularProgress } from "@mui/material";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import styles from "./S2_CheckCoverage.module.scss"
import {AutoComplete} from "../../widgets/AutoComplete/AutoComplete";

const CheckCoverageComponent = (props: CheckCoverageProps) => {
  const {
    checkAddressHandler,
    handleSelect,
    getStarted,
    checkCoverageHandler,
    coverageContent,
    showCoverage,
    checkAddress,
    coverageLoading,
    coverageAddress,
    setShowCoverage,
    signal4G,
    signal5G,
    coverageError,
    navigateCheckCoverage,
  } = props;

  const {
    heading,
    sub_heading,
    place_holder,
    signal_note1,
    signal_note2,
    button_1,
    button_2,
    button_3,
    error,
    coverage_1,
    coverage_2,
    signal_strength1,
    signal_strength2,
    signal_strength3,
    signal_strength4,
    signal_strength5,
    close_icon,
    tick_icon,
  } = coverageContent || {};
  return (
      <Box minHeight={{xs: "305px", sm: "255px", md: "215px"}}>
        <Box sx={{p:{xs:"25px 35px",sm:"50px", md:"25px 50px"},textAlign:{xs:"center",sm:"left"}}} className={`${styles.check_coverage_outer} container-fluid p-4`} >
        <Box className={styles.coverage_check_heading}>{heading}</Box>
        <Box className={styles.coverage_check_subheading} sx={{m:{xs:"0px 0px 14px 0px",md:"14px 0px"}}} >{sub_heading}</Box>
        {!showCoverage && (
          <Box sx={{display:"flex",flexDirection:{xs:"column",sm:"row"},alignItems:{xs:"center",sm:"left"},justifyContent:{xs:"center",sm:"left"}}} className={`${styles.address_holder} `}>
            <Box sx={{alignItems:"center"}} >
              <AutoComplete
              value={checkAddress}
              onChange={checkAddressHandler}
              onSelect={(e: any, placeId: string) => {
                handleSelect(placeId);
              }}
              disabled={coverageLoading}
              className="mb-4"
              placeholder={place_holder}
              autoComplete={"off"}
              name="address"
            /></Box>
            <Button
              variant="outlined"
              className={styles.submit_btn}
              startIcon={
                coverageLoading ? <CircularProgress color="inherit"/> : <ArrowForwardIcon />
              }
              sx={{mb:"16px"}}
              onClick={() => checkCoverageHandler(coverageAddress)}
              disabled={checkAddress === "" ? true : false}
            ></Button>
          </Box>
        )}
        {showCoverage && (
          <Box>
            {signal4G !== "no service" || signal5G !== "no service" ? (
              <Box className={`${styles.coverage_final_text} `} sx={{textAlign:"left"}}>
                <Box component="img" sx={{alignSelf:"flex-start" ,mr:"10px"}} src={tick_icon?.data?.attributes?.url} alt="tickIcon" className="me-2" />
                <Box>
                <span className="m-0">
                  {coverage_1}
                  {signal4G === "fair"
                    ? signal_strength1
                    : signal4G === "good"
                    ? signal_strength2
                    : signal4G === "great"
                    ? signal_strength3
                    : signal_strength4}
                </span>
                {coverage_2 && signal_strength5? <span className="m-0">
                  {coverage_2}
                  {signal5G === "fair"
                    ? signal_strength1
                    : signal5G === "good"
                    ? signal_strength2
                    : signal5G === "great"
                    ? signal_strength3
                    : signal_strength5}
                </span>:null}
                <p className={styles.signal_note1}>{signal_note1}</p>
                </Box>
              </Box>
            ) : (
              <Box className={styles.coverage_final_text}>
                <img src={close_icon?.data?.attributes?.url} alt="closeIcon" className="me-2" />
                <span>{signal_note2}</span>
              </Box>
            )}
            <Box className={`${styles.coverage_buttons} my-2 mx-5`} sx={{ml:{md:"48px"},m:"10px 45px"}} >
              <Button sx={{textTransform:"none"}} onClick={() => setShowCoverage(false)}>{button_1}</Button>
              <Button sx={{textTransform:"none"}} onClick={getStarted}> {button_2}</Button>
              <Button sx={{textTransform:"none"}} onClick={navigateCheckCoverage}>{button_3}</Button>
            </Box>
          </Box>
        )}

        {coverageError && <Box className={`${styles.coverage_error}  mt-4 mt-md-0`}>{error}</Box>}
      </Box>
      </Box>
    )
};

export default CheckCoverageComponent;
