import { useState, useEffect } from "react";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import {
  getCustomerBillCycleDataUsage,
  getCustomerDataUsageById,
} from "../../features/customer/customerSlice";
import { subMonths, subDays, addDays, parseISO } from "date-fns";
import { GetCustomer } from "../../features/checkout/checkoutSlice";
import { getUTCDate } from "../../utils/commonFunctions/Date";
import { sortObjectByDates } from "../../utils/commonFunctions/SortByKey";
import ConfigJSON from "../../config/env.config.json";
import { DataUsageInfo } from "./DataUsageInfo";
import { customerGroup } from "../../features/account/accountSlice";

export type IDataUsagePoviderProps = {
  content?: any;
  showSecondaryLinesUsage: boolean;
  showDataUsageForUnlimitedPack: boolean;
  handleBuyData?: any;
  showDataExceedingNotification?: boolean;
  setShowRecurring?: any;
  isCardExpired: any;
};

export const DataUsageInfoProvider = ({
  content,
  showSecondaryLinesUsage,
  showDataUsageForUnlimitedPack,
  handleBuyData,
  showDataExceedingNotification,
  setShowRecurring,
  isCardExpired,
}: IDataUsagePoviderProps) => {
  const dispatch = useAppDispatch();
  const [dataUsageInfo, setDataUsageInfo] = useState<any>();
  const [displayStartDate, setDisplayStartDate] = useState<any>();
  const [displayEndDate, setDisplayEndDate] = useState<any>();
  const [selectedDateKeyIndex, setSelectedDateKeyIndex] = useState<number>(0);
  const [totalNumberOfKeys, setTotalNumberOfKeys] = useState<number>(0);
  const [consolidatedBillCycyle, setConsolidatedBillCycle] = useState<any>();
  const [loadingData, setLoadingData] = useState(false);
  const { customerUsageData, customerBillCycleDataUsage } = useAppSelector(
    (state: any) => state.customer
  );
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  let customerStoreData: any = JSON.parse(
    localStorage.getItem("customerDetail") || "null"
  );
  const isValidBillCyclePayload = () => {
    return (
      customerStoreData?.id &&
      customerStoreData.nextBillingDate &&
      customerStoreData?.activationDate
    );
  };
  useEffect(() => {
    fetchCustomerDetails();
  }, []);
  const getCustomerGroup = () => {
    if (customerStoreData?.groupId) {
      dispatch(
        customerGroup({
          groupId: customerStoreData.groupId,
        })
      );
    }
  };
  useEffect(() => {
    getCustomerGroup();
    // loadCurrentDataUsageData();
    if (customerStoreData?.id && isValidBillCyclePayload()) {
      loadCurrentDataUsageData();
    }
  }, []);

  useEffect(() => {
    if (setShowRecurring) {
      if (totalNumberOfKeys > 1) {
        if (selectedDateKeyIndex === totalNumberOfKeys - 1) {
          setShowRecurring(true);
        } else {
          setShowRecurring(false);
        }
      } else {
        setShowRecurring(true);
      }
    }
  }, [selectedDateKeyIndex]);

  const fetchCustomerDetails = () => {
    let loggedUser: any = localStorage.getItem("currentuser");
    loggedUser = loggedUser ? JSON.parse(loggedUser) : null;
    if (loggedUser?.email) {
      dispatch(GetCustomer({ username: encodeURIComponent(loggedUser.email) }));
    }
  };
  const loadCurrentDataUsageData = () => {
    const customerId = customerStoreData?.isPrimary
      ? customerStoreData?.id
      : customerStoreData?.parentId;
    dispatch(getCustomerDataUsageById(customerId))
      .then((cdrCurrentResponse: any) => {
        setLoadingData(false);
        fetchDataUsageDetails(customerUsageData, customerBillCycleDataUsage);
        requestOverallBillCycleDataUsage(
          cdrCurrentResponse?.payload?.data.data
        );
      })
      .catch(() => {
        setLoadingData(false);
        requestOverallBillCycleDataUsage(null);
      });
  };
  const requestOverallBillCycleDataUsage = (cdrCurrentDataUsageData: any) => {
    if (isValidBillCyclePayload()) {
      const startDate = parseISO(customerStoreData?.activationDate);
      const endDate = parseISO(customerStoreData?.billStartDate);
      fetchBillCycleDataUsage(
        getUTCDate(startDate),
        getUTCDate(endDate),
        cdrCurrentDataUsageData
      );
    }
  };
  const fetchBillCycleDataUsage = (
    startDate: string,
    endDate: string,
    cdrCurrentDataUsageData: any
  ) => {
    const customerId = customerStoreData?.isPrimary
      ? customerStoreData?.id
      : customerStoreData?.parentId;
    const payload = {
      userId: customerId,
      startDate,
      endDate,
    };
    dispatch(getCustomerBillCycleDataUsage(payload)).then(
      (cdrOverallResponse) => {
        if (cdrOverallResponse?.payload?.status === 200) {
          fetchDataUsageDetails(
            cdrCurrentDataUsageData,
            cdrOverallResponse?.payload?.data?.data
          );
        }
      }
    );
  };
  const fetchDataUsageDetails = (
    customerCurrentUsageData: any,
    customerOverallDataUsage: any
  ) => {
    const initialDisplayStartDate = parseISO(customerStoreData?.billStartDate);
    let initialDisplayEndDate = parseISO(customerStoreData?.nextBillingDate);
    initialDisplayEndDate = subDays(initialDisplayEndDate, 1);
    setDisplayStartDate(initialDisplayStartDate);
    setDisplayEndDate(initialDisplayEndDate);
    const formatedEndDate = getUTCDate(
      parseISO(customerStoreData?.billEndDate)
    );
    let latestRecordInCurrentDataUsage = {
      [formatedEndDate]: customerCurrentUsageData?.[formatedEndDate],
    };
    if (!customerCurrentUsageData?.[formatedEndDate]) {
      latestRecordInCurrentDataUsage = {};
    }
    const completeBillCycleDataUsage = {
      ...customerOverallDataUsage,
      ...latestRecordInCurrentDataUsage,
    };
    const sortedDataUsage: any = sortObjectByDates(completeBillCycleDataUsage);
    setConsolidatedBillCycle(sortedDataUsage);
    setTotalNumberOfKeys(Object.keys(sortedDataUsage).length);
    setSelectedDateKeyIndex(Object.keys(sortedDataUsage).length - 1);
    const endDate = subDays(parseISO(customerStoreData.nextBillingDate), 1);
    const uDataInfo = sortedDataUsage[getUTCDate(endDate)];
    setDataUsageInfo(uDataInfo);
  };
  const handleBillCycleChange = (type: string) => {
    let newSelectedKey: number = selectedDateKeyIndex;
    if (type === "decrement") {
      newSelectedKey = selectedDateKeyIndex - 1;
    } else {
      newSelectedKey = selectedDateKeyIndex + 1;
    }
    setSelectedDateKeyIndex(newSelectedKey);
    handleDateKeyChange(newSelectedKey);
  };
  const handleDateKeyChange = (newSelectedKey: number) => {
    let newEndDate: any = Object.keys(consolidatedBillCycyle)[newSelectedKey];
    let newStartDate = null;
    if (!ConfigJSON.FIXED_DAYS_BILLING) {
      newStartDate = addDays(subMonths(parseISO(newEndDate), 1), 1);
    } else {
      newStartDate = addDays(
        parseISO(newEndDate),
        ConfigJSON.NUMBER_OF_BILLING_DAYS_FOR_BILL_CYCLE
      ); // config need to be tested for plum
    }
    if (newSelectedKey === totalNumberOfKeys - 1) {
      // For handling current details bill cycle dates
      setDisplayStartDate(parseISO(getCustomer?.billStartDate));
    } else {
      setDisplayStartDate(newStartDate);
    }
    setDisplayStartDate(newStartDate);
    setDisplayEndDate(parseISO(newEndDate));
    setDataUsageInfo(consolidatedBillCycyle[newEndDate]);
  };
  return (
    <DataUsageInfo
      dataUsage={dataUsageInfo}
      startDate={displayStartDate}
      endDate={displayEndDate}
      handleBillCycleChange={handleBillCycleChange}
      selectedDateKeyIndex={selectedDateKeyIndex}
      totalNumberOfKeys={totalNumberOfKeys}
      content={content}
      fetchDataUsageDetails={loadCurrentDataUsageData}
      setLoadingData={setLoadingData}
      showSecondaryLinesUsage={showSecondaryLinesUsage}
      showDataUsageForUnlimitedPack={showDataUsageForUnlimitedPack}
      callBackGetCustomerGroup={getCustomerGroup}
      handleBuyData={handleBuyData}
      showDataExceedingNotification={showDataExceedingNotification}
      loading={loadingData}
      isCardExpired={isCardExpired}
    />
  );
};
