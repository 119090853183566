import { Navigate, Outlet, useLocation } from "react-router-dom";
import { RoutingURLs } from "../config/RoutingURLs";
import Config from "../config/env.config.json";

const ProtectedRoutes = () => {
  const location = useLocation();
  let user: any = localStorage.getItem("currentuser");
  const currentuser: any = user ? JSON.parse(user) : null;
  let ssoCustomerDetails: any = null;
  let customerDetail: any = null;
  if (Config?.IS_SSO_LOGIN) {
    ssoCustomerDetails = localStorage.getItem("ssoCustomerInfo");
    ssoCustomerDetails = JSON.parse(ssoCustomerDetails);
    customerDetail = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
  }
  return (currentuser && !currentuser.isAnonymous) ||
    (ssoCustomerDetails && ssoCustomerDetails?.firstName) ||
    ssoCustomerDetails?.first_name ||
    customerDetail?.first_name ||
    customerDetail?.firstName ? (
    <Outlet />
  ) : (
    <Navigate to={RoutingURLs.login} replace state={{ from: location }} />
  );
};

export default ProtectedRoutes;
