import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import { version } from "../../config/RoutingURLs";
import EnvConfig from "../../config/env.config.json";
import ApiEndPoints from "../../config/ApiEndPoints";
import { GetInTouchRequestPayload } from "../checkout/Types";

type InitialState = {
  plans: any;
  error: string | null;
  coveragePlans: any;
  coverageError: any;
  getInTouch: any;
  getInTouchError: any;
  plansEarthlink: any;
  errorPlansEarthlink: string | null;
  coverageLoading: boolean;
  coverageStatus: any | null;
  errorCoverageStatus: any | null;
  inviteRefereeStatus: any | null;
  errorInviteRefereeStatus: any | null;
  eligibilityCheck: any | null;
  eligibilityCheckError: any;
  discountApplied: any;
  discountAppliedError: any;
  compatibility: any;
  errorCompatibility: any;
  compatibilityLoading: boolean;
  liveOpsMessagesSuccess: any;
  liveOpsMessagesError: any;
};
const initialState: InitialState = {
  plans: [],
  error: null,
  coveragePlans: [],
  coverageError: null,
  getInTouch: [],
  getInTouchError: null,
  plansEarthlink: [],
  errorPlansEarthlink: null,
  coverageLoading: false,
  coverageStatus: {},
  errorCoverageStatus: null,
  inviteRefereeStatus: null,
  errorInviteRefereeStatus: null,
  eligibilityCheck: {},
  eligibilityCheckError: {},
  discountApplied: {},
  discountAppliedError: null,
  compatibility: {},
  errorCompatibility: null,
  compatibilityLoading: false,
  liveOpsMessagesSuccess: null,
  liveOpsMessagesError: null,
};

export const checkCoverage = createAsyncThunk(
  "homepage/checkCoverage",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: ApiEndPoints.networkCoverage,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const getInTouchApi = createAsyncThunk(
  "homepage/getInTouchApi",
  (action: GetInTouchRequestPayload) => {
    return apiJunction.makeRequest({
      method: "post",
      url: ApiEndPoints.hubspot,
      body: action.data,
    });
  }
);

export const checkCompatibility = createAsyncThunk(
  "homepage/checkCompatibility",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.checkCompatibility}${action.imeiNumber}`,
      body: action,
      other1: EnvConfig?.OPERATOR,
    });
  }
);

export const eligibilityCheckApi = createAsyncThunk(
  "homepage/eligibilityCheckApi",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `${ApiEndPoints.eligibilityCheckApi}`,
      body: action.data,
    });
  }
);

export const discountAppliedApi = createAsyncThunk(
  "homepage/discountAppliedApi",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${ApiEndPoints.discountAppliedApi}${action.wowCustomerId}`,
    });
  }
);

export const liveOpsMessagesApi = createAsyncThunk(
  "homepage/liveOpsMessagesApi",
  async (action: any, { rejectWithValue }) => {
    try {
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${ApiEndPoints.liveOpsMessages}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err?.response?.data);
    }
  }
);

export const sendAPNSettingsAndroidBeforePurchase = createAsyncThunk(
  "homepage/sendAPNSettingsAndroidBeforePurchase",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `${ApiEndPoints.sendAPNSettingsAndroidBeforePurchase}`,
      body: action,
    });
  }
);

export const sendAPNSettingsAndroidPostPurchase = createAsyncThunk(
  "homepage/sendAPNSettingsAndroidPostPurchase",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `${ApiEndPoints.sendAPNSettingsAndroidPostPurchase}/${action.customerId}`,
      body: action,
    });
  }
);

export const sendAPNSettingsBeforePurchase = createAsyncThunk(
  "homepage/sendAPNSettingsBeforePurchase",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `${ApiEndPoints.sendAPNSettingsBeforePurchase}`,
      body: action,
    });
  }
);

export const sendAPNSettingsPostPurchase = createAsyncThunk(
  "homepage/sendAPNSettingsPostPurchase",
  (action: any) => {
    return apiJunction.makeRequest({
      method: "post",
      url: `${ApiEndPoints.sendAPNSettingsPostPurchase}/${action.customerId}`,
      body: action,
    });
  }
);
const homepageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {
    resetGetInTouch: (state) => {
      state.getInTouch = [];
    },
    resetEligibilityCheckErr: (state) => {
      state.eligibilityCheckError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(checkCoverage.pending, (state) => {
      state.coverageStatus = {};
      state.errorCoverageStatus = "";
      state.coverageLoading = true;
    });
    builder.addCase(
      checkCoverage.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.coverageStatus = action.payload;
        state.errorCoverageStatus = null;
        state.coverageLoading = false;
      }
    );
    builder.addCase(checkCoverage.rejected, (state, action) => {
      state.coverageStatus = {};
      state.errorCoverageStatus = action.payload || "Something went wrong";
      state.coverageLoading = false;
    });
    builder.addCase(
      getInTouchApi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.getInTouch = action.payload;
        state.getInTouchError = null;
      }
    );
    builder.addCase(getInTouchApi.rejected, (state, action) => {
      state.getInTouch = {};
      state.getInTouchError = action.error || "Something went wrong";
    });
    builder.addCase(checkCompatibility.pending, (state) => {
      state.compatibility = {};
      state.errorCompatibility = "";
      state.compatibilityLoading = true;
    });
    builder.addCase(
      checkCompatibility.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.compatibility = action.payload;
        state.errorCompatibility = null;
        state.compatibilityLoading = false;
      }
    );
    builder.addCase(checkCompatibility.rejected, (state, action) => {
      state.compatibility = {};
      state.errorCompatibility = action.error || "Something went wrong";
      state.compatibilityLoading = false;
    });
    builder.addCase(
      eligibilityCheckApi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.eligibilityCheck = action.payload;
        state.eligibilityCheckError = null;
      }
    );
    builder.addCase(eligibilityCheckApi.rejected, (state, action) => {
      state.eligibilityCheck = {};
      state.eligibilityCheckError = action || "Something went wrong";
    });
    builder.addCase(
      discountAppliedApi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.discountApplied = action.payload.discountApplied;
        state.discountAppliedError = null;
      }
    );
    builder.addCase(discountAppliedApi.rejected, (state, action) => {
      state.discountAppliedError = action || "Something went wrong";
      state.discountApplied = null;
    });
    builder.addCase(
      liveOpsMessagesApi.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.liveOpsMessagesSuccess = action.payload.data;
        state.liveOpsMessagesError = null;
      }
    );
    builder.addCase(liveOpsMessagesApi.rejected, (state, action) => {
      state.liveOpsMessagesSuccess = null;
      state.liveOpsMessagesError = action.payload;
    });
  },
});
export const { resetGetInTouch, resetEligibilityCheckErr } =
  homepageSlice.actions;
export default homepageSlice.reducer;
