import { Box, Skeleton } from "@mui/material";
import { useEffect, useState } from "react";
import clsx from "clsx";
import { ButtonCust } from "../../../widgets/Button/ButtonCust";
import { calculateCost, calculateUnlimitedCost, PlanProps } from "./PlanCard";
import styles from "./S7_PlanCard.module.scss";
import Config from "../../../../config/env.config.json";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { useLocation } from "react-router";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { scroller } from "react-scroll";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import EnvConfig from "../../../../config/env.config.json";
import { BrandNameUpdate } from "../../../../utils";
import { Coupon } from "../../../../features/checkout/checkoutSlice";
import { NutritionLabel } from "../NutritionLabel/NutritionLabel";
import { getConfigKeys } from "../../../../features/activation/activationSlice";

const T7_PlanCard = (props: PlanProps) => {
  const [user, setUser] = useState<any>(null);
  const [isSSOToken, setIsSSOToken] = useState<boolean>(false);
  const [showAnswer, setShowAnswer] = useState<any>(true);
  const handleAnswer = () => {
    if (showAnswer) return setShowAnswer(false);
    return setShowAnswer(true);
  };
  const { coupon, isLoaded } = useAppSelector((state: any) => state.checkout);
  const dispatch: any = useAppDispatch();
  const { eye4FraudLoader, getCartItemsSuccess } = useAppSelector(
    (state: any) => state.shopWare
  );
  const {
    plandata,
    planContent,
    navigate,
    borderColorAdd,
    tagLabel,
    tagBgColor,
    tagTxtColor,
    selectedLine,
    isSelected,
    planSelectionHandler,
  } = props;
  useEffect(() => {
    dispatch(getConfigKeys());
  }, []);
  useEffect(() => {
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    setUser(customerDetail);
    let ssoAccessToken: any = localStorage.getItem("ssoAccessToken");
    if (ssoAccessToken) {
      setIsSSOToken(true);
    }
    let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerInfo");
    ssoCustomerInfo = JSON.parse(ssoCustomerInfo);
    if (
      !coupon &&
      ssoCustomerInfo?.customer_eligibility &&
      ssoCustomerInfo?.promo_code
    ) {
      dispatch(
        Coupon({
          couponCode: `${ssoCustomerInfo?.promo_code.toUpperCase()}`,
        })
      );
    }
  }, []);

  const getPlanCost = (plan: any) => {
    let planCost: any;
    let primaryDiscountInDollar: any = coupon?.discountInDollar;
    let secondaryDiscountInDollar: any = coupon?.secondaryDiscountInDollar;
    if (plan?.addLineSplitPrice && selectedLine > 1) {
      let linePrice1 = 0;
      for (let i = 1; i <= selectedLine - 1; i++) {
        linePrice1 = linePrice1 + plan?.addLineSplitPrice[i];
      }
      planCost =
        plan?.baseLinePrice -
        primaryDiscountInDollar +
        (linePrice1 - secondaryDiscountInDollar * (selectedLine - 1));
    } else {
      planCost =
        plan?.baseLinePrice -
        primaryDiscountInDollar +
        (selectedLine - 1) *
        (plan?.additionalLinePrice - secondaryDiscountInDollar);
    }
    return (
      planCost ||
      plan?.baseLinePrice + (selectedLine - 1) * plan?.additionalLinePrice
    );
  };
  const onViewDetails = (_: any) => {
    navigate(RoutingURLs.cellPhonePlans);
    setTimeout(function () {
      scroller.scrollTo("bestCellPhonePlan", {
        duration: 1000,
        delay: 0,
        offset: -100,
        smooth: true,
      });
    }, 100);
  };

  const location = useLocation();

  let details: any = localStorage.getItem("customerDetail");
  details = JSON.parse(details);
  const {
    cards_div,
    plans_div,
    plan_page_plan_card,
    current_plan,
    separator,
    fade_out,
    save_money_area,
    plan_page_offer,
    start_text,
    price_include,
    hover_color,
    apply_border123,
    home_page_plan_card,
    tag,
    title,
    save_money_img,
    plan_cost_data,
    cost,
    cost1,
  } = styles;
  const {
    button_text,
    res,
    per_money_text,
    save_money,
    view_details_text,
    shareable_image,
    icon_offers,
    s_plan_img,
    u_plan_img,
    s_p_text,
    u_p_text,
    hide_feature_item,
  } = planContent || {};
  const isPlanInCart = getCartItemsSuccess?.lineItems?.filter((data: any) =>
    data?.payload?.productNumber.includes("DATA")
  );
  return planContent && Object.keys(planContent || {}).length > 0 ? (
    <Box className={clsx(cards_div, plans_div)}>
      {plandata.length > 0 &&
        plandata.map((el: any, index: number) => {
          let planCost: any = getPlanCost(el);
          const isCurrentPlan = location.pathname === RoutingURLs.myAccount &&
            details?.reachPlanDisplayName === el?.displayName;
          return !isLoaded ? (
            <Box
              id={`home${index}`}
              key={index}
              className={clsx(
                home_page_plan_card,
                plan_page_plan_card,
                borderColorAdd ? borderColorAdd : "",
                isCurrentPlan ? current_plan : ""
              )}
            >
              <Box className={styles.top_container}>
                {isCurrentPlan && (
                  <>
                    {!tagLabel && (
                      <Box
                        className={tag}
                        sx={{
                          backgroundColor: tagBgColor
                            ? tagBgColor
                            : "var(--primary_background)",
                          color: tagTxtColor ? tagTxtColor : "var(--white)",
                        }}
                      >
                        {planContent?.current_plan}
                      </Box>
                    )}
                  </>
                )}
                <Box className={title}>{el.displayName}</Box>
                <Box
                  display="flex"
                  alignItems={"center"}
                  justifyContent={"center"}
                >
                  <Box>
                    {el?.isUnlimited && u_plan_img ? (
                      <>
                        <Box
                          component={"img"}
                          alt={BrandNameUpdate(
                            u_plan_img?.data?.attributes?.alternativeText
                          )}
                          src={u_plan_img?.data?.attributes?.url}
                        />
                        &nbsp;
                      </>
                    ) : !el?.isUnlimited && s_plan_img ? (
                      <>
                        <Box
                          component={"img"}
                          alt={BrandNameUpdate(
                            s_plan_img?.data?.attributes?.alternativeText
                          )}
                          src={s_plan_img?.data?.attributes?.url}
                        />
                        &nbsp;
                      </>
                    ) : null}
                  </Box>
                  <Box>
                    <Box
                      sx={{
                        fontSize: "21px",
                        fontWeight: "bold",
                        lineHeight: "25px",
                      }}
                    >
                      {el?.planData}
                      {el?.dataUnit}
                    </Box>
                  </Box>
                  <Box
                    sx={{
                      color: "var(--grey33-color)",
                      fontSize: "15px",
                      fontWeight: "var(--h4_font_weight)",
                    }}
                  >
                    {el?.isUnlimited ? (
                      <>&nbsp;{u_p_text}</>
                    ) : (
                      <>&nbsp;{s_p_text}</>
                    )}
                  </Box>
                </Box>
                <Box className={separator}></Box>
                <Box
                  className={save_money_area}
                  style={{
                    opacity: el.isBestFitAllowed ? 1 : 0.2,
                    display: "none",
                  }}
                >
                  <Box
                    component={"img"}
                    src={shareable_image?.data?.attributes?.url}
                    alt={shareable_image?.data?.attributes?.alternativeText}
                    className={save_money_img}
                  />
                  {save_money}
                </Box>
                <Box className={start_text}>{res.start_from}</Box>
                <Box className={plan_cost_data}>
                  <Box component={"span"} className={cost}>
                    {GetCurrencyFixedToTwo(
                      planCost ? planCost : 0,
                      el.currency ? el.currency : Config.CURRENCY
                    )}
                  </Box>
                  <Box component={"span"} className={cost1}>
                    {per_money_text}
                  </Box>
                </Box>
              </Box>
              <Box className={styles.bottom_container}>
                <Box className={price_include}>{res.price_includes}</Box>
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    mb: "30px",
                    minHeight: "var(--plan_card_bottom_section_min_height)"
                      ? "var(--plan_card_bottom_section_min_height)"
                      : "135px",
                  }}
                >
                  {icon_offers &&
                    icon_offers.map((item: any, featureIndex: any) => {
                      const hideItem = hide_feature_item?.find(
                        (sItem: any) =>
                          sItem?.name?.toLowerCase() ===
                          item?.offer?.toLowerCase()
                      );
                      return !hideItem?.indexes?.includes(index) ||
                        !hideItem?.name === item?.offer?.toLowerCase() ? (
                        <Box
                          key={featureIndex}
                          sx={{
                            display: "flex",
                            alignItems: "center",
                            p: "10px 0px 0px",
                          }}
                        >
                          <Box>
                            <Box
                              component={"img"}
                              alt=""
                              src={item?.image?.data?.attributes?.url}
                              sx={{ width: "16px" }}
                            />
                          </Box>
                          <Box className={plan_page_offer}>{item?.offer}</Box>
                        </Box>
                      ) : null;
                    })}
                </Box>
                {location.pathname !== RoutingURLs.myAccount && (
                  <Box
                    className={el.maxLines >= selectedLine ? " " : fade_out}
                    sx={{
                      display: "flex",
                      justifyContent: "center",
                      mt: "10px",
                    }}
                  >
                    <ButtonCust
                      variantType={Config.PRIMARY_BUTTON_TYPE}
                      className={el.maxLines >= selectedLine ? " " : fade_out}
                      onClick={(e: any) => {
                        // el.maxLines >= selectedLine
                        //   ? planSelectionHandler(el, button_text)
                        //   : "";
                        if (el.maxLines >= selectedLine) {
                          (user && EnvConfig?.IS_SSO_LOGIN) || isSSOToken
                            ? planSelectionHandler(el, button_text)
                            : (window.location.href = `${process.env.REACT_APP_SSO_LOGIN_URL}`);
                        }
                      }}
                    >
                      {button_text}
                    </ButtonCust>
                  </Box>
                )}
                <NutritionLabel
                  NLContent={planContent}
                  plandata={el}
                  planCost={planCost}
                  index={index}
                  showAnswer={showAnswer}
                  handleAnswer={handleAnswer}
                  isheading={true}
                />
              </Box>
            </Box>
          ) : (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                margin: "15px",
                maxWidth: "330px",
                backgroundColor: "var(--white)",
                padding: "20px",
                gap: "10px",
              }}
              className={clsx(
                home_page_plan_card,
                plan_page_plan_card,
                current_plan
              )}
            >
              <Skeleton variant="text" width={30} height={20} />
              <Skeleton variant="text" width={100} height={20} />
              <Skeleton variant="text" width={150} height={20} />
              <Skeleton variant="rounded" width={200} height={60} />
              <Box sx={{ display: "flex" }}>
                <Skeleton variant="rounded" width={20} height={30} />
                <Skeleton
                  variant="text"
                  width={75}
                  height={20}
                  sx={{ ml: "10px", padding: "10px 0px 0px" }}
                />
              </Box>
              <Box sx={{ display: "flex" }}>
                <Skeleton variant="rounded" width={20} height={30} />
                <Skeleton
                  variant="text"
                  width={75}
                  height={20}
                  sx={{ ml: "10px", padding: "10px 0px 0px" }}
                />
              </Box>

              <Skeleton variant="text" width={75} height={30} />
              <Box sx={{ display: "flex", textAlign: "center" }}>
                <Skeleton variant="rounded" width={150} height={10} />
                <Skeleton variant="circular" width={5} height={5} />
              </Box>
            </Box>
          );
        })}
    </Box>
  ) : null;
};

export default T7_PlanCard;
