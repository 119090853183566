import { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { Store } from "react-notifications-component";
import { ContactCustomerDialog } from "./ContactCustomer";
import {
  activationStatusCheck,
  activateLine,
} from "../../../../features/activation/activationSlice";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { PortingDialogue } from "./PortingDialog";
import { NotificationCust } from "../../../widgets";
import { ActivationStatusSuccess } from "./ActivationStatusSuccess";
import { formatPhoneNumber } from "../../../../utils/commonFunctions/ReusableFunctions";
import { customerGroup } from "../../../../features/account/accountSlice";
import { PortRequestRejected } from "./PortReqestRejected";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { brandNames, CustomerStatus as CS } from "../../../../enums";
import EnvConfig from "../../../../config/env.config.json";
import { UserStatusNotifications } from "../../../common";
import { BrandNameUpdate } from "../../../../utils/commonFunctions/BrandNameUpdate";

export const ActivationStatus = () => {
  const dispatch = useAppDispatch();
  let customerDetails: any = localStorage?.getItem("customerDetail");
  customerDetails = JSON.parse(customerDetails);
  const [videoFlag, setVideoFlag] = useState(true);
  const [modalName, setModalName] = useState<any>("");
  const [modalData, setModalData] = useState<any>({});
  const [buttonLoaderFg, setButtonLoader] = useState<boolean>(false);
  const { activationModals } = useAppSelector(
    (state: any) => state.strapi.postActivation || {}
  );
  const { notifications: notificationMsg } = activationModals;
  const {
    zipCode,
    accNum,
    verizonPin,
    portingFailCC,
    pinNum,
    acivationFailCC,
    portReject1,
    portReject2,
    newNumSuc,
    portSuccess,
    port_act_subtxt,
    port_act_btn,
  } = activationModals;
  const navigate = useNavigate();

  const { activationStatus } = useAppSelector(
    (state: any) => state?.activation || {}
  );
  const { getCustomer } = useAppSelector((state: any) => state?.checkout || {});
  const {
    status: getCustomerStatus,
    isPrimary,
    eSimSetup,
    esim,
  } = getCustomer || {};

  const { group: groupData } = useAppSelector(
    (state: any) => state.account || {}
  );

  const { password } = activationStatus || {};
  const { id, reachPlanId, simId, imei, make, model } = customerDetails || {};
  const { mnpInfo } = getCustomer || {};
  const { oldNumber, operator, acctNum, oldZip } = mnpInfo || {};

  const activationPayload: any = {
    customerId: id,
    reachPlanId: reachPlanId,
    iccId: simId,
    imei: imei,
    make: make,
    model: model,
    isPort: true,
    numberToPort: oldNumber,
    oldCarrier: operator,
    portUpdate: true,
    oldCarrierAccountNumber: acctNum,
    oldZip: oldZip,
  };
  // shows port modals or activation success or failed modals  based on activation status
  const activationStatusResults = (res?: any) => {
    const resData = res?.payload?.data?.data || {};
    const {
      status: actStatus,
      esim,
      portFailureCode,
      oldCarrier,
      customerId,
    } = resData;
    if (RoutingURLs.postActivationHome === window?.location?.pathname) {
      if (actStatus !== 9 && actStatus !== 1) {
        removeNotifications();
      } else if (actStatus === 1 || actStatus === 9) {
        // Notification for primary line
        if (getCustomer?.id === customerId && isPrimary) {
          notificationSection(actStatus, undefined, esim);
        }
      }
      if (actStatus === 10) {
        if (portFailureCode === 1011) {
          setModalName("zipCode");
          setModalData(zipCode);
        } else if (portFailureCode === 1010) {
          if ("VERIZON" === oldCarrier?.toUpperCase()) {
            setModalName("verizonPin");
            setModalData(verizonPin);
          } else {
            setModalName("pinNum");
            setModalData(pinNum);
          }
        } else if (portFailureCode === 1009) {
          setModalName("accNum");
          setModalData(accNum);
        } else if (portFailureCode === 1017) {
          const oldCarrierName = oldCarrier?.toLowerCase();
          if (oldCarrierName === "att" || oldCarrierName === "at&t") {
            setModalName("portRejected");
            setModalData(portReject1);
          } else {
            setModalName("portRejected");
            setModalData(portReject2);
          }
        } else {
          setModalName("cc");
          setModalData(portingFailCC);
        }
      } else if (actStatus === 2) {
        setVideoFlag(esim ? false : true);
        let Obj: any = {
          subtext: port_act_subtxt,
          btnText: customerDetails?.isPrimary ? port_act_btn : "",
          noteText: "",
        };
        if (resData?.isPort) {
          if (resData?.esim) {
            Obj = {
              ...portSuccess,
              subtext: BrandNameUpdate(
                notificationMsg?.esimPortInSuccessDlgSubText
              ),
            };
          } else {
            Obj = portSuccess;
          }
        } else {
          const { title: titleArr, subtext } = newNumSuc;
          Obj = {
            ...newNumSuc,
            title: `${BrandNameUpdate(titleArr?.[0]?.txt)} ${
              customerDetails?.isPrimary
                ? titleArr?.[1]?.txt
                  ? titleArr?.[1]?.txt
                  : ""
                : `${customerDetails.firstName}'s`
            } ${titleArr?.[2]?.txt ? titleArr?.[2]?.txt : ""} ${
              titleArr?.[2]?.txt ? formatPhoneNumber(resData?.mobileNo) : ""
            } ${titleArr?.[3]?.txt ? titleArr?.[3]?.txt : ""}`,
            subtext: resData?.esim
              ? BrandNameUpdate(notificationMsg?.esimPortInSuccessDlgSubText)
              : subtext?.replace(
                  "{phone}",
                  formatPhoneNumber(resData?.mobileNo)
                ),
          };
        }
        setModalData(Obj);
        setModalName("statusSuccess");
      } else if (actStatus === 8) {
        setModalName("cc");
        setModalData(acivationFailCC);
      } else if (actStatus === 11) {
        navigate(RoutingURLs.activation);
      }
    }
  };

  // Displays and removes the notifications based on status
  const notificationSection: any = (
    st: number,
    msg?: string,
    isESIMType?: boolean
  ) => {
    if (st === 9) {
      const message = isESIMType
        ? notificationMsg.esimActInprogress
        : msg ?? notificationMsg.message1;
      NotificationCust({
        message: BrandNameUpdate(message || ""),
        type: "info",
        duration: 5000,
        id: "portInProgressNoti",
        showIcon: true,
      });
    } else if (st === 1) {
      const message = isESIMType
        ? notificationMsg.esimActInprogress
        : msg ?? notificationMsg.message2;
      NotificationCust({
        message: BrandNameUpdate(message || ""),
        type: "info",
        duration: 0,
        id: "actInProgressNoti",
        showIcon: true,
      });
    }
    if (st !== 9) {
      Store.removeNotification("portInProgressNoti");
    }
    if (st !== 1) {
      Store.removeNotification("actInProgressNoti");
    }
  };

  //removes notifications
  const removeNotifications = () => {
    Store.removeNotification("portInProgressNoti");
    Store.removeNotification("actInProgressNoti");
  };

  //removes all the notifications
  const removeAllNotifications = () => {
    removeNotifications();
    Store.removeNotification("portSpecificErrorNotify");
    Store.removeNotification("portfailurenoti2");
  };

  useEffect(() => {
    const st: any = getCustomerStatus;
    notificationSection(st, undefined, esim);
    if (esim === true && eSimSetup === false && st === 2) {
      navigateToSteps();
    }
    const secondaryUserExists: any = JSON.parse(
      localStorage?.getItem("secondaryUserId") || "null"
    );
    const secondaryUser: any = JSON.parse(
      localStorage?.getItem("secondaryCustomerDetail") || "null"
    );
    const showMsg: any = JSON.parse(
      localStorage?.getItem("showSuccessMessage") || "null"
    );
    if (st == 1 || st == 8 || st === 9 || st === 10 || showMsg || st === 0) {
      showMsg == true &&
        eSimSetup === true &&
        localStorage.removeItem("showSuccessMessage");
      callActivationStatus(secondaryUser?.id);
    } else if (secondaryUserExists) {
      localStorage.removeItem("secondaryUserId");
      callActivationStatus(secondaryUserExists, null);
    }
    return () => {
      removeAllNotifications();
    };
  }, []);

  // only for current logged user redirection (either primiry user or secondary user)
  const navigateToSteps = () => {
    localStorage.setItem("showSuccessMessage", "true");
    navigate(RoutingURLs.steps);
  };

  // sets the notification based on secondary line status and calls activationStatus API
  const secondaryFn = (data: any) => {
    data?.map((item: any) => {
      if (!item?.isPrimary && (item?.status === 1 || item?.status === 9)) {
        window.scroll(0, 0);
        const message = getSecondaryUserNotiferMessage(item);
        callActivationStatus(item.id, item);
        notificationSection(item?.status, message, item?.esim);
      }
    });
  };
  const getSecondaryUserNotiferMessage = (item: any) => {
    let message: string;
    if (item?.status === 9) {
      message = notificationMsg.message1.replace(
        "your",
        item?.firstName + "'s"
      );
    } else {
      message = notificationMsg.message3.replace("your", item?.firstName);
    }
    return message;
  };
  useEffect(() => {
    if (groupData === null && getCustomer?.groupId) {
      dispatch(
        customerGroup({
          groupId: getCustomer?.groupId,
        })
      ).then((res: any) => {
        if (res?.payload?.status === 200) {
          secondaryFn(res?.payload?.data?.data);
        }
      });
    } else if (getCustomer?.groupId && groupData !== null) {
      secondaryFn(groupData?.data);
    }
  }, [groupData]);

  // shows notifications if modal(1009, 1010, 1011) closed without submit button
  const modalCloseFn = () => {
    setModalName("");
    if (modalName !== "cc") {
      let message: string = "";
      //error code 1011
      if (modalName === "zipCode") {
        message = notificationMsg.zipPortFail;
      }
      //error code  1010
      else if (modalName === "verizonPin") {
        message = notificationMsg.verizonPinPortFail;
      } else if (modalName === "pinNum") {
        message = notificationMsg.pinPortFail;
      }
      //error coee 1009
      else if (modalName === "accNum") {
        message = notificationMsg.accountPortFail;
      }
      NotificationCust({
        message: message,
        type: "info",
        duration: 0,
        id: "portSpecificErrorNotify",
        showIcon: true,
      });
    }
    Store.removeNotification("portInProgressNoti");
  };

  // 1009, 1010, 1011 form submit
  const submitHandler = (val: any) => {
    setButtonLoader(true);

    const payload: any = {
      ...activationPayload,
      password: val,
    };

    const pinPayload = { ...payload, password: val };
    const zipcodePayload = {
      ...payload,
      oldZip: val,
      password: password,
    };
    const accountPayload = {
      ...payload,
      oldCarrierAccountNumber: val,
      password: password,
    };
    let py: any = {};
    //error code  1011
    if (modalName === "zipCode") {
      py = zipcodePayload;
    }
    //error code 1010
    else if (modalName === "verizonPin" || modalName === "pinNum") {
      py = pinPayload;
    }
    //error code 1009
    else if (modalName === "accNum") {
      py = accountPayload;
    }
    callActivation(py);
  };
  const callActivation = (py: any) => {
    dispatch(activateLine({ payload: py })).then((res: any) => {
      setButtonLoader(false);
      if (res?.payload?.data?.status === "SUCCESS") {
        if (customerDetails?.isMNP === true) {
          notificationSection(
            CS.PORTING_IN_PROGRESS,
            undefined,
            customerDetails?.esim
          );
        } else {
          notificationSection(
            CS.ACTIVATION_IN_PROGRESS,
            undefined,
            customerDetails?.esim
          );
        }
        callActivationStatus();
        setModalName("");
      } else if (res.payload?.status === "FAILURE") {
        setModalName("cc");
        setModalData(acivationFailCC);
      }
    });
  };
  const callActivationStatus = (
    userId?: string,
    secondaryUserDetails?: any
  ) => {
    dispatch(
      activationStatusCheck({
        payload: { customerId: userId ? userId : customerDetails?.id },
      })
    ).then((res: any) => {
      const resAcStatus = res?.payload?.data?.data?.status || null;
      const { esim } = res?.payload?.data?.data || {};
      if (res?.payload?.status === 200) {
        //for secondary LINE
        if (
          userId &&
          (resAcStatus === CS.ACTIVATION_IN_PROGRESS ||
            resAcStatus === CS.PORTING_IN_PROGRESS ||
            resAcStatus === CS.ACTIVE)
        ) {
          //to update group data
          if (resAcStatus === CS.ACTIVE && secondaryUserDetails) {
            dispatch(
              customerGroup({
                groupId: getCustomer?.groupId,
              })
            );
          }
          const { eSimSetup: secondaryEsimSetup = null } =
            secondaryUserDetails || {};
          //secondary line steps navigation
          if (
            resAcStatus === CS.ACTIVE &&
            esim &&
            secondaryEsimSetup === false
          ) {
            removeNotifications();
            localStorage.setItem("secondaryUserId", JSON.stringify(userId));
            navigate(RoutingURLs.steps);
          } else {
            activationStatusResults(res);
          }
        }
        // logged user either primary or secondary USER
        else if (!userId) {
          if (resAcStatus === CS.ACTIVE && esim && eSimSetup === false) {
            removeNotifications();
            navigateToSteps();
          } else {
            activationStatusResults(res);
            if (
              EnvConfig.DOMAIN === brandNames.WOW &&
              isPrimary &&
              resAcStatus === CS.PORTING_FAILED
            ) {
              portFailHandler();
            }
          }
        }
      }

      if (
        (resAcStatus === CS.ACTIVATION_IN_PROGRESS ||
          resAcStatus === CS.PORTING_IN_PROGRESS ||
          res?.payload?.status === "FAILURE") &&
        RoutingURLs.postActivationHome === window?.location?.pathname
      ) {
        setTimeout(() => {
          callActivationStatus(userId ?? "", secondaryUserDetails ?? null);
        }, 2 * 60 * 1000);
      }
    });
  };

  //closes the currently opened modal
  const closeModal = () => {
    setModalName("");
  };
  const changeModal = () => {
    setModalName("portRejected");
    setModalData(portReject1);
  };
  // changes modal content based on sms received and sms not received
  const smsHandler = (keyName: string) => {
    setModalName("portRejected");
    setModalData(activationModals[keyName]);
  };
  // triggered during retry activation in 1017 senario.
  const retryActivationFn = () => {
    setButtonLoader(true);
    const py = { ...activationPayload, password: password };
    callActivation(py);
  };

  // notification only for primary line
  const portFailHandler = () => {
    NotificationCust({
      message: (
        <div>
          {notificationMsg?.portReqFail}&nbsp;
          <Link style={{ color: "var(--white)" }} to={RoutingURLs.account}>
            {notificationMsg?.tryAgain}
          </Link>
        </div>
      ),
      type: "danger",
      duration: 4000,
      id: "portfailurenoti2",
    });
  };
  return modalName ? (
    <>
      {/* status = 10 (1009, 1010, 1011) */}
      {modalName !== "cc" &&
        modalName !== "statusSuccess" &&
        modalName !== "portRejected" && (
          <PortingDialogue
            name={modalName}
            modalData={modalData}
            closeHandler={modalCloseFn}
            submitHandler={submitHandler}
            buttonLoaderFg={buttonLoaderFg}
            defaultError={notificationMsg.defaultError}
          />
        )}
      {/* status = 8||10 */}
      {modalName === "cc" && (
        <ContactCustomerDialog
          modalData={modalData}
          closeHandler={modalCloseFn}
        />
      )}
      {/* status = 2 */}
      {modalName === "statusSuccess" && (
        <ActivationStatusSuccess
          closeHandler={closeModal}
          modalData={modalData}
          customerDetails={customerDetails}
          videoFlag={videoFlag}
        />
      )}
      {/*  status = 10  (1017) */}
      {modalName === "portRejected" && (
        <PortRequestRejected
          modalData={modalData}
          changeModal={changeModal}
          smsHandler={smsHandler}
          retryActivationFn={retryActivationFn}
          buttonLoaderFg={buttonLoaderFg}
        />
      )}
      {EnvConfig.DOMAIN === brandNames.WOW && <UserStatusNotifications />}
    </>
  ) : null;
};
