import getSymbolFromCurrency from "currency-symbol-map";
import planData from "../../config/data.json";

export function GetCurrencyFixedToTwo(
  value: any,
  currency?: string,
  currencySymbol?: boolean
) {
  let planCurrency = planData.data.map((el: any) => el.currency);
  let Symbol = getSymbolFromCurrency(currency ? currency : planCurrency[0]);

  if (value !== undefined && value !== null) {
    if (typeof value === "string") {
      value = parseFloat(value);
    }

    if (!isNaN(value)) {
      if (value >= 0 && currencySymbol) return `${value.toFixed(2)}`;
      else if (value >= 0) {
        return `${Symbol}${value.toFixed(2)}`;
      } else if (value < 0) {
        return String(value).replace("-", `-${Symbol}`);
      }
    } else {
      return null;
    }
  } else {
    return null;
  }
}
