import { useEffect } from "react";
import Config from "../../config/app.config.json";
import { isTemplateExisits } from "../../utils/commonFunctions/mapTemplatesToPage";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { Coupon } from "../../features/checkout/checkoutSlice";

const PlanItem = isTemplateExisits(Config.Checkout.ChangePlan)
  ? require(`./${Config.Checkout.ChangePlan.template}_PlanItem`).default
  : null;
interface IPlansContainer {
  plans?: Array<any>;
  selectedPlan?: any;
  handlePlanSelection: any;
  activePlanName: any;
  currentPlanBorder?: boolean;
  content?: any;
  user?: any;
  planContent? : any;
  SelectPlanAndProceedHandler?: any;
  isTopUpInitiated?: any;
  isPlanChangeInitiated?: any;
}

export const PlansContainer = (props: IPlansContainer) => {
  const {
    plans,
    selectedPlan,
    activePlanName,
    handlePlanSelection,
    content,
    currentPlanBorder,
    user,
    planContent,
    SelectPlanAndProceedHandler,
    isTopUpInitiated,
    isPlanChangeInitiated
  } = props;

  const dispatch = useAppDispatch();

  const { coupon } = useAppSelector((state: any) => state.checkout);
  
  useEffect(() => {
    let customerDetail: any = localStorage.getItem("customerDetail");
    customerDetail = JSON.parse(customerDetail);
    // setUser(customerDetail);
    let ssoAccessToken: any = localStorage.getItem("ssoAccessToken");
    if (ssoAccessToken) {
      // setIsSSOToken(true);
    }
    let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerInfo");
    ssoCustomerInfo = JSON.parse(ssoCustomerInfo);
    if (
      !coupon &&
      ssoCustomerInfo?.customer_eligibility &&
      ssoCustomerInfo?.promo_code
    ) {
      dispatch(
        Coupon({
          couponCode: `${ssoCustomerInfo?.promo_code.toUpperCase()}`,
        })
      );
    }
  }, []);

  return (
    <div>
      {plans &&
        plans.map((plan: any, index: any) => {
          return (
            <PlanItem
              key={index}
              selectedPlan={selectedPlan}
              activePlanName={activePlanName}
              plan={plan}
              handlePlanSelection={handlePlanSelection}
              content={content}
              currentPlanBorder={currentPlanBorder}
              user={user}
              planIndex={index}
              planContent={planContent}
              SelectPlanAndProceedHandler={SelectPlanAndProceedHandler}
              isTopUpInitiated={isTopUpInitiated}
              isPlanChangeInitiated={isPlanChangeInitiated}
            />
          );
        })}
    </div>
  );
};
