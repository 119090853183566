import { LinearProgress, Box } from "@mui/material";
import "./LinearProgressBarCust.scss";

type progressBarProps = {
  variant: any;
  value: number;
  customSX?: any;
  usedUnits?: any;
};

export const LinearProgressBarCust = (props: progressBarProps) => {
  let backgroundColor = "var(--progress_bar_blue_color)";

  if (typeof props?.usedUnits !== "undefined") {
    switch (true) {
      case props?.usedUnits < 90:
        backgroundColor = "var(--progress_bar_blue_color) !important";
        break;
      case props?.usedUnits >= 90 && props?.usedUnits < 100:
        backgroundColor = "var(--progress_bar_dark_grey_color) !important";
        break;
      case props?.usedUnits >= 100:
        backgroundColor = "var(--progress_bar_warning_color) !important";
        break;
      default:
        backgroundColor = "var(--progress_bar_blue_color) !important";
        break;
    }
  }

  const newCustomSX = {
    width: "100%",
    "& .MuiLinearProgress-bar": {
      backgroundColor: backgroundColor,
    },
    ...(props?.customSX || {}),
  };

  return (
    <Box className="linear_progress_bar_main" sx={newCustomSX}>
      <LinearProgress value={props?.value} variant={props?.variant} />
    </Box>
  );
};
