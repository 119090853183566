import { Grid, Typography } from "@mui/material";
import { Box } from "@mui/system";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useNavigate } from "react-router-dom";
import Link from "@mui/material/Link";
import ArrowRightIcon from "@mui/icons-material/ArrowRight";
import config from "../../../../config/env.config.json";
import { SimpleModal, ButtonCust } from "./../../../widgets";
import {
  inviteFriends,
  inviteFriendsHandler,
} from "../../../widgets/InviteFriends";
import { SwitchCust } from "../../../widgets";
import cloneDeep from "lodash/cloneDeep";
import { useEffect, useState } from "react";
import { DataUsageInfoProvider } from "../../../dataUsageInfo";
import { useAppSelector } from "../../../../app/hooks";
import classes from "./S1_DataDetails.module.scss";
import { CustomerStatus } from "../../../../enums";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import { displayPhoneNumberFormat } from "../../../../utils/commonFunctions/ReusableFunctions";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions/GaAndFb";
import EnvConfig from "../../../../config/env.config.json";
import { Disconnected } from "../../../Disconnected";
import { useNonInitialEffect } from "../../../../utils/commonFunctions";
import { customerGroup } from "../../../../features/account/accountSlice";
import { useAppDispatch } from "../../../../app/hooks";

type dataDetailsProps = {
  setCurrentSection: Function;
  topupPulseDetails: any;
  recurringBtnHandler: any;
  open: boolean;
  setOpen: any;
  topup: boolean;
  unlimited: boolean;
  topUpUpdateLoader: boolean;
  userRecurringTopUp: any;
  setUserRecurringTopUp: any;
  group: any;
  nameChanges: any;
  setNameChanges: any;
  topupSet: boolean;
  setActiveTab?: any;
  tabsContent?: any;
  content?: any;
  dataUsageContent?: any;
  isCardExpired: any;
  setSelectedLine: any;
};

const DataDetails = (props: dataDetailsProps) => {
  const {
    setCurrentSection,
    topupPulseDetails,
    recurringBtnHandler,
    setOpen,
    open,
    topUpUpdateLoader,
    unlimited,
    userRecurringTopUp,
    setUserRecurringTopUp,
    group,
    nameChanges,
    setNameChanges,
    topupSet,
    setActiveTab,
    tabsContent,
    content,
    dataUsageContent,
    isCardExpired,
    setSelectedLine,
  } = props;
  const dispatch = useAppDispatch();
  const { getCustomer } = useAppSelector((state: any) => state.checkout);
  const [showRecurring, setShowRecurring] = useState(false);
  const [initialStatus, setInitialStatus] = useState<any>(userRecurringTopUp);
  let currentdetails: any = localStorage.getItem("customerDetail");
  currentdetails = JSON.parse(currentdetails);
  useEffect(() => {
    inviteFriends();
  }, []);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const GAAndFBEventsHandler = (category: string, value: string) => {
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: config.brand,
      screenName: gaScreenNames.account,
      category: category,
      buttonValue: value,
    });
  };
  const topUpSwitchhandler = (data: any) => {
    let userRecurringTopUp1 = cloneDeep(userRecurringTopUp);
    let nameChanges1 = cloneDeep(nameChanges);
    let ind =
      userRecurringTopUp1?.length > 0 &&
      userRecurringTopUp1?.findIndex((el: any) => el.userId === data.id);
    let firstName = data && data.firstName;
    let name = nameChanges1;
    if (ind !== -1) {
      userRecurringTopUp1[ind].status =
        userRecurringTopUp1[ind] && !userRecurringTopUp1[ind].status;
      if (name === "") {
        name = firstName;
      } else {
        let hasname =
          name.split(", ").length > 0 &&
          name.split(", ").findIndex((n: string) => n === firstName);
        if (hasname === -1) {
          name = name + ", " + firstName;
        }
      }
      setUserRecurringTopUp(userRecurringTopUp1);
      setNameChanges(name);
    }
  };

  useNonInitialEffect(() => {
    if (getCustomer) {
      dispatch(
        customerGroup({
          groupId: getCustomer && getCustomer?.groupId,
        })
      );
    }
  }, [getCustomer]);
  useNonInitialEffect(() => {
    setInitialStatus(userRecurringTopUp);
  }, [open]);
  const handleCloseModal = () => {
    setOpen(false);
    setUserRecurringTopUp(initialStatus);
  };
  const handleChangeBuyData = () => {
    setCurrentSection("CPBDP");
    setActiveTab(tabsContent[0]?.id);
    if (localStorage.getItem("activeTab")) {
      localStorage.removeItem("addCardAccount");
      localStorage.removeItem("tax");
      localStorage.removeItem("activeTab");
      localStorage.removeItem("amount");
      localStorage.removeItem("selectedPlan");
      localStorage.removeItem("selectedLine");
    }
  };
  const EnableData = {
    title: content?.enable_data?.title,
    text1: content?.enable_data?.text1,
    text2: content?.enable_data?.text2,
    subtext: content?.enable_data?.sub_text,
    sideHead: content?.enable_data?.side_head,
    button9: content?.enable_data?.button9,
  };

  const DisableData = {
    title: content?.disable_data?.title,
    text1: content?.disable_data?.text1,
    text2: content?.disable_data?.text2,
    subtext: content?.disable_data?.sub_text,
    sideHead: content?.disable_data?.side_head,
    button9: content?.disable_data?.button9,
  };
  const DisableUnlimitedData = {
    title: content?.disable_unlimited?.title,
    text1: content?.disable_unlimited?.text1,
    text2: content?.disable_unlimited?.text2,
    sideHead: content?.disable_unlimited?.side_head,
    subtext: content?.disable_unlimited?.sub_text,
    button9: content?.disable_unlimited?.button9,
  };
  const navigate = useNavigate();
  const EnableUnlimitedData = {
    title: content?.enable_unlimited?.title,
    text1: content?.enable_unlimited?.text1,
    text2: content?.enable_unlimited?.text2,
    subtext: content?.enable_unlimited?.sub_text,
    sideHead: content?.enable_unlimited?.side_head,
    button9: content?.enable_unlimited?.button9,
  };

  let data = {
    user: content?.user,
    userNumber: content?.user_num,
    userNumber2: content?.user_num2,
    card1Label: content?.card1_label,
    card1Date: content?.card1_date,
    dataUsed: content?.data_used,
    totalData: content?.total_data,
    card2Icon: content?.card2_icon?.data?.attributes?.url,
    card1Link1Content: content?.link1_content,
    card1Link2Content: content?.link2_content,
    card2Link1Content: content?.card2_link1,
    card2Text: content?.card2_text,
    card2SubText: content?.card2_subtext,
    button3: content?.button3,
    button4: content?.button4,
  };

  const TopupModal = (props: any) => {
    const { title, text1, text2, subtext, sideHead, button9 } = props;
    return (
      <Grid>
        <Grid
          item
          textAlign="center"
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography
            sx={{
              fontSize: "22px",
              fontWeight: " var(--font_weight_4)",
              fontFamily: " var(--font_family_Bold)",
              padding: "0px 0px 30px",
            }}
          >
            {title}
          </Typography>
        </Grid>
        <Box sx={{ textAlign: "center" }}>
          <Typography
            sx={{
              fontSize: "14px",
              padding: "5px 0px 20px",
              fontWeight: "var(--font_weight_0)",
              fontFamily: "var(--font_family_Medium)",
            }}
          >
            {text1}{" "}
            <Box
              component={"span"}
              sx={{
                fontFamily: "var(--font_family_Semibold)",
                color: "var(--dusk)",
              }}
            >
              {topupPulseDetails && topupPulseDetails?.pulse}GB{" "}
              {GetCurrencyFixedToTwo(
                topupPulseDetails && topupPulseDetails?.pulseCost
              )}
            </Box>
            &nbsp;
            {text2}
          </Typography>
          {unlimited &&
            group?.data?.map((el: any, index: number) => {
              let status1 =
                userRecurringTopUp?.length > 0 &&
                userRecurringTopUp?.find((data: any) => data.userId === el.id);
              if (el.status === 2) {
                return (
                  <Box
                    key={index}
                    sx={{
                      display: "flex",
                      justifyContent: "space-between",
                      alignItems: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <Box
                      sx={{
                        display: "flex",
                      }}
                    >
                      <Box
                        component={"img"}
                        src={
                          content?.account_credit_icon?.data?.attributes?.url
                        }
                        alt={
                          content?.account_credit_icon?.data?.attributes
                            ?.alternativeText
                        }
                        sx={{ width: " 36px", height: " 36px" }}
                      />
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "column",
                          justifyContent: "center",
                          paddingLeft: "5px",
                        }}
                      >
                        <Box
                          sx={{
                            fontFamily: "var(--font_family_Semibold)",
                            fontSize: "14px",
                            color: "var(--text_color)",
                            textAlign: "left",
                            paddingBottom: "5px",
                          }}
                        >
                          {el && el?.firstName}{" "}
                          {el && el?.isPrimary && content?.owner_txt}
                        </Box>
                        <Box
                          sx={{
                            fontFamily: "var(--font_family_Medium)",
                            fontSize: "14px",
                            color: " var(--grey_shade)",
                            textAlign: "left",
                          }}
                        >
                          {el?.reachNumber
                            ? displayPhoneNumberFormat(el.reachNumber)
                            : "-"}
                        </Box>
                      </Box>
                    </Box>
                    <SwitchCust
                      checked={status1 && status1.status}
                      handleSwitchToggle={() => topUpSwitchhandler(el)}
                    />
                  </Box>
                );
              }
            })}
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "var(--font_weight_0)",
              fontFamily: "var(--font_family_Medium)",
              padding: "5px 0px 20px",
            }}
          >
            <span
              style={{
                fontWeight: " var(--font_weight_4)",
                fontFamily: " var(--font_family)",
              }}
            >
              {sideHead}
            </span>
            {subtext}
          </Typography>
          <ButtonCust
            variantType={EnvConfig?.PRIMARY_BUTTON_TYPE}
            disabled={
              unlimited
                ? JSON.stringify(userRecurringTopUp) !==
                  JSON.stringify(initialStatus)
                  ? false
                  : true
                : false
            }
            loading={topUpUpdateLoader}
            onClick={() => {
              recurringBtnHandler();
              GAAndFBEventsHandler(gaCategory.dataUsage, button9);
            }}
          >
            {button9}
          </ButtonCust>
        </Box>
      </Grid>
    );
  };
  const navigateToBuyDataPack = (id: any) => {
    setCurrentSection("CPBDP");
    setSelectedLine(id);
    setActiveTab(tabsContent[1]?.id);
  };
  return (
    <Box
      sx={{
        pb: "20px",
      }}
    >
      {config?.SHOW_TOP_MODALS && open && (
        <SimpleModal
          isShowModal={open}
          onCloseModal={handleCloseModal}
          showClose={true}
          sx={{ width: { xs: "90%" } }}
        >
          {/* topup modal  */}
          {!topupSet ? (
            unlimited ? (
              <TopupModal {...EnableUnlimitedData} />
            ) : (
              <TopupModal {...EnableData} />
            )
          ) : unlimited ? (
            <TopupModal {...DisableUnlimitedData} />
          ) : (
            <TopupModal {...DisableData} />
          )}
        </SimpleModal>
      )}
      <Grid
        id="dataUsageContent"
        container
        sx={{
          textAlign: "center",
        }}
        display={"flex"}
        fontFamily={" var(--font_family)"}
      >
        {getCustomer?.status === CustomerStatus.ACTIVE ||
        getCustomer?.status === CustomerStatus.SUSPENDED ||
        getCustomer?.status === CustomerStatus.HOTLINED ||
        getCustomer?.status === CustomerStatus.REJECTED ||
        getCustomer?.status === CustomerStatus.DISCONNECTED ||
        getCustomer?.status === CustomerStatus.DISABLED ? (
          <Grid
            xs={12}
            md={data?.card2Link1Content ? 6 : 12}
            item
            sx={{ px: "20px", pt: "30px", minHeight: "352px" }}
          >
            <Grid
              sx={{
                backgroundColor: "var(--white_1)",
                height: "100%",
                borderRadius: "4px",
              }}
              className={classes.data_usage_container}
            >
              {getCustomer?.status === CustomerStatus.DISCONNECTED ? (
                <Disconnected content={dataUsageContent} />
              ) : (
                <>
                  <DataUsageInfoProvider
                    content={dataUsageContent}
                    showSecondaryLinesUsage={true}
                    showDataUsageForUnlimitedPack={false}
                    handleBuyData={navigateToBuyDataPack}
                    showDataExceedingNotification={true}
                    setShowRecurring={setShowRecurring}
                    isCardExpired={isCardExpired}
                  />

                  {isCardExpired && getCustomer?.isPrimary && (
                    <Box className={classes.account_card_expiry_message}>
                      <span>{content?.note_txt} </span>
                      {content?.note_msg}
                      <span
                        className={classes.account_update_expiry_card}
                        onClick={() => {
                          navigate(RoutingURLs.billing);
                        }}
                      >
                        {content?.update_txt}{" "}
                      </span>
                      {content?.note_msg1}
                    </Box>
                  )}
                  {getCustomer?.isPrimary &&
                    showRecurring &&
                    getCustomer?.status === CustomerStatus.ACTIVE && (
                      <>
                        <ButtonCust
                          variantType={config.PRIMARY_BUTTON_TYPE}
                          sx={{
                            my: "10px",
                            px: "20px",
                            height: "45px",
                          }}
                          onClick={() => {
                            handleChangeBuyData();
                            GAAndFBEventsHandler(
                              gaCategory.dataUsage,
                              dataUsageContent?.ctx_btn1
                            );
                          }}
                        >
                          {dataUsageContent?.ctx_btn1}
                        </ButtonCust>
                        {(dataUsageContent?.ctx_btn2 ||
                          dataUsageContent?.ctx_btn3) && (
                          <Box
                            display="flex"
                            justifyContent="center"
                            alignItems="center"
                          >
                            <Link
                              onClick={handleClickOpen}
                              sx={{
                                color: "var(--primary_color)",
                                fontWeight: "bold",
                                textTransform: "none",
                                textDecoration: "none",
                                display: "flex",
                                justifyContent: "center",
                                cursor: "pointer",
                              }}
                            >
                              {!topupSet
                                ? dataUsageContent?.ctx_btn2
                                : dataUsageContent?.ctx_btn3}
                              <>
                                <ArrowRightIcon />
                              </>
                            </Link>
                          </Box>
                        )}
                      </>
                    )}
                </>
              )}
            </Grid>
          </Grid>
        ) : null}
        {/* section2 */}
        {data?.card2Link1Content && (
          <Grid
            xs={12}
            md={6}
            item
            sx={{ px: "20px", pt: "30px", minHeight: "352px" }}
          >
            <Grid
              sx={{
                backgroundColor: "var(--white_1) ",
                height: "100%",
                p: "30px",
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                borderRadius: "4px",
              }}
            >
              <Box
                component="img"
                sx={{ width: "40px", height: "32px", ml: "auto", mr: "auto" }}
                src={data.card2Icon}
                alt="user-icon"
              />
              <Typography
                sx={{
                  m: "5px",
                  fontSize: "18px",
                  fontFamily: " var(--font_family_Bold)",
                  fontWeight: "var(--font_weight_4)",
                }}
              >
                {group?.data?.length === 1?data.card2Text?.replace("Lines", "Line")?.replace("lines", "line"):data.card2Text}
                {group?.data?.length}
              </Typography>
              {getCustomer?.isPrimary && (
                <Link
                  onClick={() => {
                    setCurrentSection("ManageLines");
                    GAAndFBEventsHandler(
                      gaCategory.manageLines,
                      data.card2Link1Content
                    );
                  }}
                  sx={{
                    color: " var(--primary_color)",
                    fontFamily: " var(--font_family_Bold)",
                    fontWeight: "var(--font_weight_4)",
                    textDecoration: "none",
                    cursor: "pointer",
                    padding: "0px 0px 25px",
                  }}
                >
                  {data.card2Link1Content}
                </Link>
              )}
              {config.SHOW_INVITE_FRIENDS &&
                getCustomer?.status === CustomerStatus.ACTIVE && (
                  <>
                    <Box
                      sx={{
                        border: "1px solid var(--ford-grey-color)",
                        width: "175px",
                        m: "0 auto",
                        opacity: 0.5,
                      }}
                    ></Box>
                    <Typography
                      sx={{
                        padding: "15px 0px 15px",
                        fontSize: "14px",
                        fontFamily: "var(--font_family_Semibold)",
                      }}
                    >
                      {data.card2SubText}
                    </Typography>

                    <ButtonCust
                      variantType={config.PRIMARY_BUTTON_TYPE}
                      sx={{
                        mt: "18px",
                        mb: "12px ",
                        mx: "auto",
                      }}
                      onClick={() => {
                        inviteFriendsHandler();
                      }}
                    >
                      {data.button3}
                    </ButtonCust>
                    <Box display="flex" justifyContent="center">
                      <Link
                        sx={{
                          color: " var(--primary_color)",
                          fontFamily: "var(--font_family_Bold)",
                          fontWeight: "var(--font_weight_4)",
                          textDecoration: "none",
                          cursor: "pointer",
                          width: "fit-content",
                        }}
                        onClick={() => {
                          setCurrentSection("ViewCredits");
                          GAAndFBEventsHandler(
                            gaCategory.manageLines,
                            data.button4
                          );
                        }}
                      >
                        {data.button4}
                      </Link>
                    </Box>
                  </>
                )}
            </Grid>
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

export default DataDetails;
