import { Container, Typography } from "@mui/material";
import isEmpty from "lodash/isEmpty";
import { useEffect, useRef, useState } from "react";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { useAppDispatch, useAppSelector } from "../../../../../../app/hooks";
import { pushTrackingEventsToGAAndFB } from "../../../../../../utils/commonFunctions/GaAndFb";
import { AutoComplete, SimpleModal, ButtonCust } from "../../../../../widgets";
import styles from "./AddressCheck.module.scss";
import { checkCoverage } from "../../../../../../features/homepage/homepageSlice";
import Config from "../../../../../../config/env.config.json";
import {
  gaEventTypes,
  gaScreenNames,
  gaCategory,
} from "../../../../../../Types/GoogleAnalytics";
import { EsimCoverageModal } from "../../../../../common/EsimCoverageModal/EsimCoverageModal";
import { RoutingURLs } from "../../../../../../config/RoutingURLs";
import { countryList } from "../../../../../../config/USAStateList";
import { BrandNameUpdate } from "../../../../../../utils/commonFunctions/BrandNameUpdate";
import { purchase } from "../../../../../../features/wallet/walletSlice";
import { gtmTagManager } from "../../../../../../utils/commonFunctions/GtmTagManager";

type Props = {
  navigate?: any;
  setShowCompatibilityModal?: any;
  setCoverageError?: any;
  setCoverageMessage?: any;
  setCoverageAddress?: any;
  setCheckAddress?: any;
  setSignal4G?: any;
  setSignal5G?: any;
  setCoverageModal?: any;
  coverageAddress?: any;
  coverageError?: boolean;
  coverageMessage?: any;
  checkAddress?: any;
  coverageModal?: any;
  closeModalHandler?: any;
  setFinalSuccessModal?: any;
  skipImeiCheck?: any;
  addressContent?: any;
  signal5G?: any;
  signal4G?: any;
  checkAgain?: any;
};

export const AddressCheck = (props: Props) => {
  const { coverageStatus, coverageLoading, errorCoverageStatus } =
    useAppSelector((state: any) => state.homepage);
  const dispatch = useAppDispatch();
  const {
    coverage_modal,
    coverage_check_heading,
    coverage_check_subheading,
    greenline,
    coverage_error,
    detail_coverage,
  } = styles;

  const initialRender2 = useRef(true);
  useEffect(() => {
    if (initialRender2.current) {
      initialRender2.current = false;
    } else {
      if (!isEmpty(coverageStatus)) {
        gtmTagManager({
          event: "coverage_check",
          user_input_address: props.checkAddress,
          coverage_status: `signal4G-${coverageStatus?.data?.data?.signal4g} signal5G-${coverageStatus?.data?.data?.signal5g}`,
          click_section: "purchase_journey",
        });
        if (!Config.IS_ESIM_SUPPORTED && !coverageStatus?.data?.data.isValid) {
          props.setCoverageError(true);
          props.setCoverageMessage(BrandNameUpdate(cover_error4));
        }
        if (
          coverageStatus?.data?.data?.signal4g ||
          coverageStatus?.data?.data.signal5g
        ) {
          if (
            coverageStatus?.data?.data?.signal4g !== "no service" ||
            coverageStatus?.data?.data.signal5G !== "no service"
          ) {
            !Config.IS_ESIM_SUPPORTED && props.setCheckAddress("");
            props.setCoverageAddress({
              address1: "",
              city: "",
              state: "",
              country: "",
              zip: "",
            });
            props.setSignal4G(coverageStatus?.data?.data?.signal4g);
            props.setSignal5G(coverageStatus?.data?.data?.signal5g);
            !Config.IS_ESIM_SUPPORTED && props.setCoverageModal(false);
            if (
              Config.DEVICE_CHECK_DURING_PURCHASE &&
              !Config.IS_ESIM_SUPPORTED
            ) {
              props.setShowCompatibilityModal(true);
            } else {
              !Config.IS_ESIM_SUPPORTED && props.skipImeiCheck();
            }
          } else {
            props.setCheckAddress("");
            !Config.IS_ESIM_SUPPORTED && props.setCoverageModal(false);
          }
        }
      }
    }
  }, [coverageStatus]);

  const initialRender1 = useRef(true);
  useEffect(() => {
    if (initialRender1.current) {
      initialRender1.current = false;
    } else {
      if (!isEmpty(errorCoverageStatus)) {
        if (
          errorCoverageStatus.resCode &&
          errorCoverageStatus.resCode === 50020
        ) {
          props.setSignal4G("no service");
          props.setSignal5G("no service");
          props.setCoverageModal(true);
          props.setCheckAddress("");
          props.setCoverageError(true);
          props.setCoverageMessage(cover_error1);
        } else if (
          errorCoverageStatus.resCode &&
          errorCoverageStatus.resCode === 40175
        ) {
          props.setSignal4G("");
          props.setSignal5G("");
          props.setCoverageError(true);
          props.setCoverageMessage(cover_error2);
        } else {
          props.setSignal4G("");
          props.setSignal5G("");
          props.setCoverageError(true);
          props.setCoverageMessage(cover_error3);
        }
      }
    }
  }, [errorCoverageStatus]);

  const handleSelect = (address: any) => {
    geocodeByPlaceId(address)
      .then(async (results: any) => {
        let city: string = "",
          state: string = "",
          zip: string = "",
          country: string = "",
          route: string = "",
          streetNumber: string = "";
        results &&
          results[0] &&
          results[0].address_components &&
          results[0].address_components.map((el: any) => {
            state = el.types.includes("administrative_area_level_1")
              ? el.short_name
              : state;
            zip = el.types.includes("postal_code") ? el.short_name : zip;
            city = el.types.includes("locality")
              ? el.long_name
              : el.types.includes("sublocality")
              ? el.long_name
              : el.types.includes('neighborhood')
              ? el.long_name
              : city;
            streetNumber = el.types.includes("street_number")
              ? el.short_name
              : streetNumber;
            route = el.types.includes("route") ? el.long_name : route;
            country = el.types.includes("country") ? el.short_name : country;
            return null;
          });
        if (streetNumber && route) {
          props.setCoverageAddress({
            address1: streetNumber + " " + route,
            city,
            state,
            country,
            zip,
          });
          props.setCheckAddress(
            streetNumber +
              " " +
              route +
              ", " +
              city +
              ", " +
              state +
              ", " +
              country +
              ", " +
              zip
          );
          props.setCoverageError(false);
        } else {
          props.setCoverageAddress({
            address1: "",
            city,
            state,
            country,
            zip,
          });
          props.setCheckAddress(
            city + ", " + state + ", " + country + ", " + zip
          );
          props.setCoverageError(true);
        }
      })
      .catch((error) => console.error("error", error));
  };
  const navigateCheckCoverage = () => {
    props.navigate(RoutingURLs.coverageCheck);
  };
  const checkAddressHandler = (e: any) => {
    let checkAddArray = e.split(",");
    let coverageAddressNew = {
      address1: "",
      city: "",
      country: "",
      state: "",
      zip: "",
    };
    if (e.includes(",")) {
      coverageAddressNew = {
        address1: checkAddArray[0],
        city: checkAddArray[1],
        country: checkAddArray[2],
        state: checkAddArray[3],
        zip: checkAddArray[4],
      };
    }
    props.setCheckAddress(e);
    props.setCoverageAddress(
      !isEmpty(props.coverageAddress)
        ? coverageAddressNew
        : props.coverageAddress
    );
  };
  const checkDisableHandler = () => {
    return (
      props.checkAddress === "" &&
      (!props.coverageAddress.address1 ||
        !props.coverageAddress.city ||
        !props.coverageAddress.state ||
        !props.coverageAddress.country ||
        !props.coverageAddress.zip ||
        coverageLoading)
    );
  };

  const {
    coverage_title,
    coverage_subtitle,
    detailed_coverage,
    coverage_btn,
    coverage_placeholder,
    cover_error1,
    cover_error2,
    cover_error3,
    cover_error4,
  } = props.addressContent || {};
  const checkCoverageHandler = (address: any) => {
    props.setCoverageError(false);
    props.setSignal4G("");
    props.setSignal5G("");
    dispatch(checkCoverage(address)).then((response:any) => {
      const { signal4G, signal5G } = response?.payload?.data?.data || {};
      if (
        response.payload.status === 200 &&
        (signal4G !== "no service" || signal5G !== "no service")
      ) {
        localStorage.setItem(
          "checkCoverageAddress",
          JSON.stringify({ ...address, country: countryList?.[0]?.value })
        );
      }
    });
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: Config.brand,
      screenName: gaScreenNames.home,
      category: gaCategory.checkCoverage,
      buttonValue: coverage_btn,
    });
  };

  return (
    <>
      {props.addressContent && !Config.IS_ESIM_SUPPORTED && (
        <SimpleModal
          isShowModal={props.coverageModal}
          onCloseModal={props.closeModalHandler}
          showClose={true}
          className={coverage_modal}
        >
          <Typography
            variant="h5"
            sx={{ mb: 0.75, fontSize: "22px", mt: "20px" }}
            className={coverage_check_heading}
          >
            {coverage_title}
          </Typography>
          <Typography variant="subtitle2" className={coverage_check_subheading}>
            {coverage_subtitle}
          </Typography>
          <Typography
            sx={{ width: "25%", my: 2.75 }}
            className={greenline}
          ></Typography>

          <AutoComplete
            value={props.checkAddress}
            onChange={checkAddressHandler}
            onSelect={(e: any, placeId: string) => {
              handleSelect(placeId);
            }}
            placeholder={coverage_placeholder}
          />

          {props.coverageError && (
            <Container
              style={{ padding: 0 }}
              className={coverage_error}
              dangerouslySetInnerHTML={{ __html: props.coverageMessage }}
            ></Container>
          )}
          <ButtonCust
            sx={{ my: 1, width: "100%" }}
            variantType={Config.PRIMARY_BUTTON_TYPE}
            onClick={() => checkCoverageHandler(props.coverageAddress)}
            disabled={checkDisableHandler()}
            loading={coverageLoading}
          >
            {coverage_btn}
          </ButtonCust>
          <Typography
            variant="subtitle1"
            sx={{ mt: 2 }}
            className={detail_coverage}
            onClick={navigateCheckCoverage}
          >
            {detailed_coverage}
          </Typography>
        </SimpleModal>
      )}
      {Config.IS_ESIM_SUPPORTED && (
        <EsimCoverageModal
          coverageModal={props.coverageModal}
          closeModalHandler={props.closeModalHandler}
          checkAddress={props.checkAddress}
          coverageError={props.coverageError}
          coverageMessage={props.coverageMessage}
          coverageAddress={props.coverageAddress}
          checkAddressHandler={checkAddressHandler}
          handleSelect={handleSelect}
          signal4G={props.signal4G}
          signal5G={props.signal5G}
          checkCoverageHandler={checkCoverageHandler}
          checkDisableHandler={checkDisableHandler}
          coverageLoading={coverageLoading}
          checkAgain={props.checkAgain}
          setCoverageModal={props.setCoverageModal}
          setShowCompatibilityModal={props.setShowCompatibilityModal}
        />
      )}
    </>
  );
};
