import Config from "../../../../config/app.config.json";
import EnvConfig from "../../../../config/env.config.json";
import { PurchaseFlow } from "./PurchaseFlow/PurchaseFlow";
import { useState } from "react";
import { isTemplateExisits } from "../../../../utils/commonFunctions/mapTemplatesToPage";
import {
  gaCategory,
  gaEventTypes,
  gaScreenNames,
} from "../../../../Types/GoogleAnalytics";
import { pushTrackingEventsToGAAndFB } from "../../../../utils/commonFunctions";
import { shopWareRedirectionHandler } from "../../../../utils/commonFunctions/shopWareFunctions";
import { RoutingURLs } from "../../../../config/RoutingURLs";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { BillCalculatorPlanCard } from "../../../billCalculator";
import { gtmTagManager } from "../../../../utils/commonFunctions/GtmTagManager";
import getSymbolFromCurrency from "currency-symbol-map";
import { GetCurrencyFixedToTwo } from "../../../../utils/commonFunctions/GetCurrencyFixedToTwo";
import circularLoaderFlight from "../../../../assets/Common/flightmobile_loader.gif";
import circularLoaderTgtvs from "../../../../assets/Common/tgtvs_loader.gif";
import circularLoaderAllo from "../../../../assets/Common/allo_loader.gif";
import circularLoaderGearheart from "../../../../assets/Common/gearheart_loader.gif";
import circularLoaderQcall from "../../../../assets/Common/qcall_loader.gif";
import circularLoaderLocaltel from "../../../../assets/Common/localtel_loader.gif";
import circularLoaderMichigan from "../../../../assets/Common/michigan_loader.gif";
import circularLoaderTalkie from "../../../../assets/Common/talkie_loader.gif";
import circularLoaderWiconnect from "../../../../assets/Common/wiconnect_loader.gif";
import circularLoaderMaxx from "../../../../assets/Common/Maxx_mobile.gif";
import { Box, Typography } from "@mui/material";
import { eye4Fraud } from "../../../../features/shopWare/shopWareSlice";
import { ButtonCust, Divider, SimpleModal } from "../../../widgets";

const PlanCardComponent = isTemplateExisits(Config.Home.Plans)
  ? require(`./${Config.Home.Plans.template}_PlanCard`).default
  : null;

export type PlanProps = {
  plandata?: any;
  navigate?: any;
  isSimPaid?: boolean;
  planContent?: any;
  selectedLine?: any;
  borderColorAdd?: any;
  tagLabel?: any;
  tagBgColor?: any;
  tagTxtColor?: any;
  isSelected?: boolean;
  planSelectionHandler?: any;
};
export const calculateUnlimitedCost = (
  data: any,
  selectedLine: any,
  isRounded: boolean = true
) => {
  const SELECTED_LINE = selectedLine;

  let planCost = 0;
  let planPerLinesCost = 0;
  if (data?.addLineSplitPrice) {
    let linePrice1 = 0;
    for (let i = 1; i <= SELECTED_LINE - 1; i++) {
      linePrice1 = linePrice1 + data?.addLineSplitPrice[i];
    }
    planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 + linePrice1;
    planPerLinesCost = planCost / SELECTED_LINE;
  } else {
    planCost =
      ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
      (SELECTED_LINE - 1) * data?.additionalLinePrice;
    planPerLinesCost = planCost / SELECTED_LINE;
  }
  if (isRounded) {
    planCost = Math.ceil(planCost);
    planPerLinesCost = Math.ceil(planPerLinesCost);
  }
  return {
    planCost,
    planPerLinesCost,
  };
};

export const calculateCost = (
  data: any,
  selectedLine: any,
  isRounded: boolean = true
) => {
  const SELECTED_LINE = selectedLine;
  const planCost =
    ((100 - data?.discountPctg) * data?.baseLinePrice) / 100 +
    (SELECTED_LINE - 1) * data?.additionalLinePrice;
  let planPerLinesCost = 0;
  if (isRounded) planPerLinesCost = Math.ceil(planCost / SELECTED_LINE);
  else planPerLinesCost = planCost / SELECTED_LINE;
  return {
    planCost,
    planPerLinesCost,
  };
};

export const PlanCard = (props: PlanProps) => {
  const dispatch = useAppDispatch();
  const { getCartItemsSuccess } = useAppSelector(
    (state: any) => state.shopWare
  );
  const [purchaseJourney, setPurchaseJourney] = useState(false);
  const [coverageModal, setCoverageModal] = useState(false);
  const [showCompatibilityModal, setShowCompatibilityModal] = useState(false);
  const [coverageError, setCoverageError] = useState(false);
  const [checkAddress, setCheckAddress] = useState("");
  const [coverageMessage, setCoverageMessage] = useState("");
  const [finalSuccessModal, setFinalSuccessModal] = useState(false);
  const [isFail, setIsFail] = useState(false);
  const [emailCompatability, setEmailCompatability] = useState("");
  const [imeiNumber, setImeiNumber] = useState("");
  const [coverageAddress, setCoverageAddress] = useState({
    address1: "",
    city: "",
    state: "",
    country: "",
    zip: "",
  });
  const [showLoader, setShowLoader] = useState(false);
  const [orderPendingModal, setOrderPendingModal] = useState(false);

  const PurchaseJourneyCheck = () => {
    setEmailCompatability("");
    setImeiNumber("");
    setCoverageError(false);
    setFinalSuccessModal(false);
    setIsFail(false);
    setCoverageMessage("");
    setCoverageAddress({
      address1: "",
      city: "",
      state: "",
      country: "",
      zip: "",
    });
    setCheckAddress("");
    if (
      EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      EnvConfig.DEVICE_CHECK_DURING_PURCHASE &&
      !EnvConfig.IS_ESIM_SUPPORTED
    ) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    } else if (
      EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      !EnvConfig.DEVICE_CHECK_DURING_PURCHASE &&
      !EnvConfig.IS_ESIM_SUPPORTED
    ) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    } else if (
      !EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      EnvConfig.DEVICE_CHECK_DURING_PURCHASE &&
      !EnvConfig.IS_ESIM_SUPPORTED
    ) {
      setShowCompatibilityModal(true);
      setCoverageModal(false);
      setPurchaseJourney(true);
    } else if (
      EnvConfig.IS_ESIM_SUPPORTED &&
      !EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      !EnvConfig.DEVICE_CHECK_DURING_PURCHASE
    ) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    } else if (
      EnvConfig.IS_ESIM_SUPPORTED &&
      !EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      EnvConfig.DEVICE_CHECK_DURING_PURCHASE
    ) {
      setCoverageModal(false);
      setPurchaseJourney(true);
      setShowCompatibilityModal(true);
    } else if (
      EnvConfig.IS_ESIM_SUPPORTED &&
      EnvConfig.ADDRESS_CHECK_DURING_PURCHASE &&
      EnvConfig.DEVICE_CHECK_DURING_PURCHASE
    ) {
      setCoverageModal(true);
      setPurchaseJourney(true);
      setShowCompatibilityModal(false);
    }
    else {
      navigate(RoutingURLs.checkout);
      setPurchaseJourney(false);
    }
  };
  const planSelectionHandler = (data: any, button_text: string) => {
    if (EnvConfig?.IS_SHOPWARE_ENABLED) {
      const allProductName = getCartItemsSuccess?.lineItems?.filter(
        (data: any) => data?.payload?.productNumber.includes("DATA")
      );
      if (allProductName?.length) {
        // check plan exist in cart.
        PurchaseJourneyCheck();
      } else {
        let currentuser: any = localStorage.getItem("currentuser");
        currentuser = JSON.parse(currentuser);
        if (currentuser?.email) {
          dispatch(eye4Fraud())
            .then((res) => {
              if (res?.payload?.data?.dataPlanOrderPending) {
                setOrderPendingModal(true);
              } else {
                PurchaseJourneyCheck();
              }
            })
            .catch((_err) => PurchaseJourneyCheck());
        } else {
          PurchaseJourneyCheck();
        }
      }
    } else {
      PurchaseJourneyCheck();
    }
    pushTrackingEventsToGAAndFB(gaEventTypes.event, gaEventTypes.click, {
      appName: EnvConfig.brand,
      screenName: gaScreenNames.plan,
      category: `${data && data.name} ${selectedLine}`,
      buttonValue: button_text,
    });
    gtmTagManager({
      event: gaCategory.planCardSelected,
      plan_name: data?.name,
      plan_size: `${data?.planData} GB`,
      number_of_users: selectedLine,
      plan_cost: GetCurrencyFixedToTwo(data?.baseLinePrice),
    });
    localStorage.setItem("reachSelectedPlanLine", JSON.stringify(selectedLine));
    localStorage.setItem(
      "reachSelectedPlanDetail",
      JSON.stringify({
        line: selectedLine,
        plan: data,
      })
    );
  };
  const {
    plandata,
    navigate,
    isSimPaid,
    planContent,
    selectedLine,
    isSelected,
  } = props;

  return (
    <>
      {!location.pathname.includes(RoutingURLs.billCalculator) ? (
        <PlanCardComponent
          plandata={plandata}
          navigate={navigate}
          isSimPaid={isSimPaid}
          planContent={planContent}
          selectedLine={selectedLine}
          isSelected={isSelected}
          PurchaseJourneyCheck={PurchaseJourneyCheck}
          planSelectionHandler={planSelectionHandler}
        />
      ) : (
        <BillCalculatorPlanCard
          content={planContent}
          plan={plandata}
          PurchaseJourneyCheck={PurchaseJourneyCheck}
        />
      )}
      {EnvConfig?.IS_SHOPWARE_ENABLED && showLoader && (
        <Box
          sx={{
            opacity: 0.8,
            backgroundColor: "var(--white)",
            position: "fixed",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            zIndex: 1100,
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          {EnvConfig?.brand === "flight-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderFlight}
            />
          ) : EnvConfig?.brand === "tgtvs-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderTgtvs}
            />
          ) : EnvConfig?.brand === "allo-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderAllo}
            />
          ) : EnvConfig?.brand === "gearheart-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderGearheart}
            />
          ) : EnvConfig?.brand === "qcall-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderQcall}
            />
          ) : EnvConfig?.brand === "localtel-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderLocaltel}
            />
          ) : EnvConfig?.brand === "michigan-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderMichigan}
            />
          ) : EnvConfig?.brand === "talkie-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderTalkie}
            />
          ) : EnvConfig?.brand === "wiconnect-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderWiconnect}
            />
          ): EnvConfig?.brand === "maxx-mobile" ? (
            <Box
              component="img"
              alt={"circular_loader"}
              src={circularLoaderMaxx}
            />
          )
            : null}
        </Box>
      )}
      {EnvConfig?.IS_SHOPWARE_ENABLED && (
        <SimpleModal
          isShowModal={orderPendingModal}
          onCloseModal={() => setOrderPendingModal(false)}
          showClose={true}
          sx={{
            width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" },
          }}
        >
          <Box
            component={"img"}
            alt={
              planContent?.order_pending?.info_icon?.data?.attributes
                ?.alternativeText
            }
            src={planContent?.order_pending?.info_icon?.data?.attributes?.url}
            width={"46px"}
          />
          <Typography
            component={"h2"}
            sx={{
              fontFamily: "var(--font_family_Bold)",
              fontSize: "22px",
              lineHeight: 2.3,
            }}
          >
            {planContent?.order_pending?.title}
          </Typography>
          <Divider />
          <Typography
            sx={{
              fontSize: "16px",
              fontFamily: "var(--font_family_Semibold)",
              color: "var(--subtext-color)",
              my: "10px",
              textAlign: "center",
            }}
          >
            {planContent?.order_pending?.desc}
          </Typography>
          <ButtonCust
            sx={{ my: 1 }}
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            onClick={() => {
              setOrderPendingModal(false);
              shopWareRedirectionHandler(
                EnvConfig?.SHOP_DOMAIN,
                "orderhistory"
              );
            }}
          >
            {planContent?.order_pending?.btn_text}
          </ButtonCust>
        </SimpleModal>
      )}
      {purchaseJourney && (
        <PurchaseFlow
          coverageModal={coverageModal}
          setCoverageModal={setCoverageModal}
          showCompatibilityModal={showCompatibilityModal}
          setShowCompatibilityModal={setShowCompatibilityModal}
          coverageError={coverageError}
          setCoverageError={setCoverageError}
          checkAddress={checkAddress}
          setCheckAddress={setCheckAddress}
          coverageMessage={coverageMessage}
          setCoverageMessage={setCoverageMessage}
          coverageAddress={coverageAddress}
          setCoverageAddress={setCoverageAddress}
          finalSuccessModal={finalSuccessModal}
          setFinalSuccessModal={setFinalSuccessModal}
          isFail={isFail}
          setIsFail={setIsFail}
          emailCompatability={emailCompatability}
          setEmailCompatability={setEmailCompatability}
          imeiNumber={imeiNumber}
          setImeiNumber={setImeiNumber}
          setShowLoader={setShowLoader}
        />
      )}
    </>
  );
};
