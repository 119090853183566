import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";
import Config from "../../config/app.config.json";

type InitialState = {
  dataContent: any;
  errorDataContent: any;
  cpbdpContent: any;
  errorCpbdpContent: any;
  cppdContent: any;
  errorCppdContent: any;
  accountLandingPageContent: any;
  errorAccountLandingPageContent: any;
  shipAddContent: any;
  errorShipAddContent: any;
  reviewAddContent: any;
  errorReviewAddContent: any;
  estMonthlyAddContent: any;
  errorEstMonthlyAddContent: any;
  addLineComp: any;
  errorAddLineComp: any;
  reccuringTopUpFailContent: any;
  errorReccuringTopUpFailContent: any;
  accountPlanDetailsContent: any;
  errorAccountPlanDetailsContent: any;
};

const initialState: InitialState = {
  dataContent: null,
  errorDataContent: null,
  cpbdpContent: null,
  errorCpbdpContent: null,
  cppdContent: null,
  errorCppdContent: null,
  accountLandingPageContent: null,
  errorAccountLandingPageContent: null,
  shipAddContent: null,
  errorShipAddContent: null,
  reviewAddContent: null,
  errorReviewAddContent: null,
  estMonthlyAddContent: null,
  errorEstMonthlyAddContent: null,
  addLineComp: null,
  errorAddLineComp: null,
  reccuringTopUpFailContent: null,
  errorReccuringTopUpFailContent: null,
  accountPlanDetailsContent: null,
  errorAccountPlanDetailsContent: null,
};

export const dataDetailsContent = createAsyncThunk(
  "account/dataDetailsContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.AccountDetails.template}${StrapiApiEndPoints.dataDetails}`,
      isStrapiApi: true,
    });
  }
);

export const accountLandingPageSectionContent = createAsyncThunk(
  "account/accountLandingPageSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.AccountDetails.template}${StrapiApiEndPoints.accountLandingPage}`,
      isStrapiApi: true,
    });
  }
);
export const accountPlanDetailsSectionContent = createAsyncThunk(
  "account/accountPlanDetailsSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.AccountPlanDetails.template}${StrapiApiEndPoints.accountPlanDetails}`,
      isStrapiApi: true,
    });
  }
);
export const cpbdpSectionContent = createAsyncThunk(
  "account/cpbdpSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.ChangeBuyData.template}${StrapiApiEndPoints.cpbdp}`,
      isStrapiApi: true,
    });
  }
);

export const cppdSectionContent = createAsyncThunk(
  "account/cppdSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.cppd}`,
      isStrapiApi: true,
    });
  }
);

export const reccuringTopupFail = createAsyncThunk(
  "account/reccuringTopupFailContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${StrapiApiEndPoints.reccuringTopUpFail}`,
      isStrapiApi: true,
    });
  }
);
export const shipAddSectionContent = createAsyncThunk(
  "account/shipAddSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.shipAdd.template}${StrapiApiEndPoints.shipAdd}`,
      isStrapiApi: true,
    });
  }
);
export const reviewStatSectionContent = createAsyncThunk(
  "account/reviewStatSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.reviewStat.template}${StrapiApiEndPoints.reviewAdd}`,
      isStrapiApi: true,
    });
  }
);
export const estimatedBillSectionContent = createAsyncThunk(
  "account/estimatedBillSectionContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.estimatedBill.template}${StrapiApiEndPoints.estBillAdd}`,
      isStrapiApi: true,
    });
  }
);
export const addLineCompContent = createAsyncThunk(
  "account/addLineCompContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `/${Config.Account.addLineComp.template}${StrapiApiEndPoints.addLineComp}`,
      isStrapiApi: true,
    });
  }
);

const accountSlice = createSlice({
  name: "account",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(
      dataDetailsContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.dataContent = action?.payload?.data?.data?.attributes;
        state.errorDataContent = null;
      }
    );
    builder.addCase(dataDetailsContent.rejected, (state, action) => {
      state.dataContent = null;
      state.errorDataContent = action.error || "Something went wrong";
    });
    builder.addCase(
      cpbdpSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.cpbdpContent = action?.payload?.data?.data?.attributes;
        state.errorCpbdpContent = null;
      }
    );
    builder.addCase(cpbdpSectionContent.rejected, (state, action) => {
      state.cpbdpContent = null;
      state.errorCpbdpContent = action.error || "Something went wrong";
    });
    builder.addCase(
      cppdSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.cppdContent = action?.payload?.data?.data?.attributes;
        state.errorCppdContent = null;
      }
    );
    builder.addCase(cppdSectionContent.rejected, (state, action) => {
      state.cppdContent = null;
      state.errorCppdContent = action.error || "Something went wrong";
    });
    builder.addCase(
      accountLandingPageSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.accountLandingPageContent =
          action?.payload?.data?.data?.attributes;
        state.errorAccountLandingPageContent = null;
      }
    );
    builder.addCase(
      accountLandingPageSectionContent.rejected,
      (state, action) => {
        state.accountLandingPageContent = null;
        state.errorAccountLandingPageContent =
          action.error || "Something went wrong";
      }
    );
    
    builder.addCase(
      shipAddSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.shipAddContent = action?.payload?.data?.data?.attributes;
        state.errorShipAddContent = null;
      }
    );
    builder.addCase(shipAddSectionContent.rejected, (state, action) => {
      state.shipAddContent = null;
      state.errorShipAddContent = action.error || "Something went wrong";
    });
    builder.addCase(
      reviewStatSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reviewAddContent = action?.payload?.data?.data?.attributes;
        state.errorReviewAddContent = null;
      }
    );
    builder.addCase(reviewStatSectionContent.rejected, (state, action) => {
      state.reviewAddContent = null;
      state.errorReviewAddContent = action.error || "Something went wrong";
    });
    builder.addCase(
      estimatedBillSectionContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.estMonthlyAddContent = action?.payload?.data?.data?.attributes;
        state.errorEstMonthlyAddContent = null;
      }
    );
    builder.addCase(estimatedBillSectionContent.rejected, (state, action) => {
      state.estMonthlyAddContent = null;
      state.errorEstMonthlyAddContent = action.error || "Something went wrong";
    });
    builder.addCase(
      addLineCompContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addLineComp = action?.payload?.data?.data?.attributes;
        state.errorAddLineComp = null;
      }
    );
    builder.addCase(addLineCompContent.rejected, (state, action) => {
      state.addLineComp = null;
      state.errorAddLineComp = action.error || "Something went wrong";
    });
    builder.addCase(
      reccuringTopupFail.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.reccuringTopUpFailContent = action?.payload?.data?.data?.attributes;
        state.errorReccuringTopUpFailContent = null;
      }
    );
    builder.addCase(reccuringTopupFail.rejected, (state, action) => {
      state.reccuringTopUpFailContent = null;
      state.errorReccuringTopUpFailContent = action.error || "Something went wrong";
    });
  },
});

export default accountSlice.reducer;
