import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import apiJunction from "../../adapters/api";
import ApiEndPoints from "../../config/ApiEndPoints";
import appConfig from "../../config/app.config.json";
import StrapiApiEndPoints from "../../config/StrapiApiEndPoints";

type InitialState = {
  fccAccountContent: any;
  fccAccountContentOther: any;
  fccAccountContentLoading: any;
  fccAccountError: any;
  fccAccountImages: any;
  addRecoveryEmailSuccess: any;
  addRecoveryEmailLoading: boolean;
  errorAddRecoveryEmail: any;
  skipAccountLockLoading: boolean;
  sendOtpSuccess: any;
  sendOtpLoading: boolean;
  sendOtpError: any;
  verifyOtpSuccess: any;
  verifyOtpLoading: boolean;
  verifyOtpError: any;
};
const initialState: InitialState = {
  fccAccountContent: null,
  fccAccountContentOther: null,
  fccAccountContentLoading: true,
  fccAccountError: null,
  fccAccountImages: null,
  addRecoveryEmailSuccess: null,
  addRecoveryEmailLoading: false,
  errorAddRecoveryEmail: null,
  skipAccountLockLoading: false,
  sendOtpSuccess: null,
  sendOtpLoading: false,
  sendOtpError: null,
  verifyOtpSuccess: null,
  verifyOtpLoading: false,
  verifyOtpError: null,
};

export const addRecoveryEmail = createAsyncThunk(
  "accountLock/addRecoveryEmail",
  async (action: any, { rejectWithValue }) => {
    try {
      const addRecoveryEmailUrl = ApiEndPoints.verifyRecoveryEmail;
      const response = await apiJunction.makeRequest({
        method: "post",
        url: addRecoveryEmailUrl,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const onSkipAccountLock = createAsyncThunk(
  "accountLock/onSkipAccountLock",
  async (action: any, { rejectWithValue }) => {
    try {
      const customerId = action;
      const skipAccountLockUrl = ApiEndPoints.sendAccountLockReminder;
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${skipAccountLockUrl}${customerId}`,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const onSendOtp = createAsyncThunk(
  "accountLock/onSendOtp",
  async (action: any, { rejectWithValue }) => {
    try {
      const generateOtpUrl = ApiEndPoints.generateOtp;
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${generateOtpUrl}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);
export const onVerifyOtp = createAsyncThunk(
  "accountLock/onVerifyOtp",
  async (action: any, { rejectWithValue }) => {
    try {
      const generateOtpUrl = ApiEndPoints.verifyOtp;
      const response = await apiJunction.makeRequest({
        method: "post",
        url: `${generateOtpUrl}`,
        body: action,
      });
      return response;
    } catch (err: any) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const accountLockContent = createAsyncThunk(
  "accountLock/accountLockContent",
  () => {
    return apiJunction.makeRequest({
      method: "get",
      url: `${appConfig.FCCAccountLock.AccountInfo.template}${StrapiApiEndPoints.fccAccountLock}`,
      isStrapiApi: true,
    });
  }
);

const accountLockSlice = createSlice({
  name: "accountLock",
  initialState,
  reducers: {
    resetAddRecoveryEmail: (state) => {
      state.addRecoveryEmailSuccess = null;
      state.addRecoveryEmailLoading = false;
      state.errorAddRecoveryEmail = null;
    },
    resetSendOtpState: (state) => {
      state.sendOtpSuccess = null;
      state.sendOtpLoading = false;
      state.sendOtpError = null;
    },
    resetVerifyOtpState: (state) => {
      state.verifyOtpSuccess = null;
      state.verifyOtpLoading = false;
      state.verifyOtpError = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(
      addRecoveryEmail.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.addRecoveryEmailSuccess = action.payload;
        state.addRecoveryEmailLoading = false;
        state.errorAddRecoveryEmail = null;
      }
    );
    builder.addCase(addRecoveryEmail.pending, (state, action) => {
      state.addRecoveryEmailSuccess = null;
      state.addRecoveryEmailLoading = true;
      state.errorAddRecoveryEmail = null;
    });
    builder.addCase(addRecoveryEmail.rejected, (state, action) => {
      state.addRecoveryEmailSuccess = null;
      state.addRecoveryEmailLoading = false;
      state.errorAddRecoveryEmail = "Something went wrong. Please try again.";
    });
    builder.addCase(onSkipAccountLock.pending, (state, action) => {
      state.skipAccountLockLoading = true;
    });
    builder.addCase(onSkipAccountLock.fulfilled, (state, action) => {
      state.skipAccountLockLoading = false;
    });
    builder.addCase(onSkipAccountLock.rejected, (state, action) => {
      state.skipAccountLockLoading = false;
    });
    builder.addCase(
      onSendOtp.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.sendOtpSuccess = action.payload.data;
        state.sendOtpLoading = false;
        state.sendOtpError = null;
      }
    );
    builder.addCase(onSendOtp.pending, (state, action) => {
      state.sendOtpSuccess = null;
      state.sendOtpLoading = true;
      state.sendOtpError = null;
    });
    builder.addCase(onSendOtp.rejected, (state, action) => {
      state.sendOtpSuccess = null;
      state.sendOtpLoading = false;
      state.sendOtpError = "Something went wrong. Please try again.";
    });
    builder.addCase(
      onVerifyOtp.fulfilled,
      (state, action: PayloadAction<any>) => {
        if (action.payload.data?.data?.msg === "WRONG_OTP_ENTERED") {
          state.verifyOtpError = "Invalid OTP";
          state.verifyOtpSuccess = null;
        } else if (action.payload.data?.data?.msg === "OTP_TIME_OUT") {
          state.verifyOtpError = "OTP expired. Please regenerate another OTP.";
          state.verifyOtpSuccess = null;
        } else if (action.payload.data?.data?.msg === "OTP_VERIFIED") {
          state.verifyOtpSuccess = action.payload.data;
          state.verifyOtpError = null;
        }
        state.verifyOtpLoading = false;
      }
    );
    builder.addCase(onVerifyOtp.pending, (state, action) => {
      state.verifyOtpSuccess = null;
      state.verifyOtpLoading = true;
      state.verifyOtpError = null;
    });
    builder.addCase(onVerifyOtp.rejected, (state, action) => {
      state.verifyOtpSuccess = null;
      state.verifyOtpLoading = false;
      state.verifyOtpError = "Something went wrong. Please try again.";
    });
    builder.addCase(
      accountLockContent.fulfilled,
      (state, action: PayloadAction<any>) => {
        state.fccAccountContent = action.payload?.data?.data?.attributes?.res;
        state.fccAccountContentOther = action.payload?.data.data?.attributes;
        state.fccAccountImages = action.payload?.data?.data?.attributes;
        state.fccAccountError = null;
        state.fccAccountContentLoading = false;
      }
    );
    builder.addCase(accountLockContent.pending, (state, action) => {
      state.fccAccountContent = null;
      state.fccAccountContentOther = null;
      state.fccAccountError = null;
      state.fccAccountContentLoading = true;
    });
    builder.addCase(accountLockContent.rejected, (state, action) => {
      state.fccAccountContent = null;
      state.fccAccountContentOther = null;
      state.fccAccountContentLoading = false;
      state.fccAccountError = "Something went wrong. Please try again.";
    });
  },
});

export const { resetAddRecoveryEmail, resetVerifyOtpState } =
  accountLockSlice.actions;

export default accountLockSlice.reducer;
