export const CardTypes = {
  VISA: "VISA",
  MASTERCARD: "MASTERCARD",
  JCB: "JCB",
  AMERICANEXPRESS: "AMERICAN EXPRESS",
  DISCOVER: "DISCOVER",
  AMEX: "AMEX"
};

export  const SettingsCardsEnum = {
  VOICE_MAIL : "VOICE_MAIL",
  WIFI_CALLING : "WIFI_CALLING",
  DATA_SAVER : "DATA_SAVER",
  PASSWORD : "PASSWORD",
  LANGUAGE : "LANGUAGE",
  WIFI_ADDRESS_UPDATE : "WIFI_ADDRESS_UPDATE",
  WIFI_ADDRESS : "WIFI_ADDRESS",
  ENABLE_WIFI : "ENABLE_WIFI",
  Enable : "Enable",
  English : "English"

  };

  export const WalletEnums = {
   calling : "calling",
   roaming : "roaming",
   failure : "failure"
  }
export const IRILDEnums = {
  call : "call",
  travel : "travel"
}
export const hsiReEligibilityStatus = {
  eligible: "ELIGIBLE",
  notEligible: "NOT_ELIGIBLE",
  failure: "FAILURE",
};

export const AccountPlanDetailEnums = {
  immediate: "IMMEDIATE",
  next_billing_cycle: "NEXT_BILL_CYCLE",
};

export const CarrierTypes ={
  ATT: "att",
  ATANDT: "at&t",
  VERIZON: "VERIZON"
}

export const brandNames = {
  WOW: "WOW",
  REACH: "REACH",
  GHOST: "GHOST",
  BRAND: "BRAND",
  NEXTLINK: "NEXTLINK"
}

export const AutoTopUpStatus = {
  ENABLED: 1,
  DISABLED: 2,
  FAILED: 3
}

export const PostActivationIRILDEnums = {
  calling : "calling",
  roaming : "roaming",
  tab_left: "left",
  tab_right: "right",
}

export const IRPackStatus = {
  SCHEDULED: 0, 
  ACTIVE: 1, 
  EXPIRED: 2, 
  CREATION_IN_PROGRESS: 3,
  PROVISIONING_IN_PROGRESS: 4,
  CANCELLED: 5, 
  FAILED: 6
}

export const SimTypeEnums = {
  sim: "Sim",
  Physical_Sim: "Physical Sim",
  Physical_SIM: "Physical SIM",
  eSim: "eSim",
  eSIM: "eSIM",
  phySim: "PHYSICAL_SIM",
  physical: "PHYSICAL",
};

export const OperationInProcessState = {
  paymentCallback: "PAYMENT_CALLBACK_IN_PROCESS"
}

export const LOGIN_PROVIDER = {
  FIREBASE: "firebase",
  SSO: "sso",
};