import { useMediaQuery, useTheme } from "@mui/material";
import { IAddress } from "../../features/checkout/Types";
import _ from "lodash";

export const formatPhoneNumber = (phone: string) => {
  var cleaned = ("" + phone).replace(/\D/g, "");
  var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? "+1 " : "";
    return [intlCode, "(", match[2], ") ", match[3], "-", match[4]].join("");
  }
  return null;
};

export const isInvalid = (value: undefined | string | number | object) => {
  return value === undefined || value === null || value === "";
};

export const isInValidAddressInfo = (address: IAddress) => {
  return (
    isInvalid(address.address1) ||
    isInvalid(address.city) ||
    isInvalid(address.state) ||
    isInvalid(address.country) ||
    isInvalid(address.zip)
  );
};

export const getFormattedPhoneNumber = (
  phoneNumber: string,
  isIncludeCountryCode = false
) => {
  let phone = phoneNumber?.replace(/[^\d]/g, "");
  return isIncludeCountryCode ? `+91${phone}` : phone;
};

export const displayPhoneNumberFormat = (phoneNumber: string) => {
  let phone = getFormattedPhoneNumber(phoneNumber);
  return phone
    ?.replace(/\D+/g, "")
    .replace(/(\d{3})(\d{3})(\d{4})/, "($1) $2-$3");
};

export const getNumberWithOrdinal = (n: any) => {
  const s = ["th", "st", "nd", "rd"];
  const v = n % 100;
  return n + (s[(v - 20) % 10] || s[v] || s[0]);
};

export const getIsCardExpired = (data: any) => {
  let today, someday;
  let exMonth = data?.dateOfExpiry.substring(0, 2);
  let exYear = data?.dateOfExpiry.substring(2);
  exYear = exYear && `20${exYear}`;
  today = new Date();
  someday = new Date();
  someday.setFullYear(exYear, exMonth, 1);
  if (someday < today) {
    return true;
  }
  return false;
};

export const getResolutionDetails = () => {
  const theme = useTheme();
  const largeDesktop = useMediaQuery(theme.breakpoints.up("lg"));
  const desktop = useMediaQuery(theme.breakpoints.between("md", "lg"));
  const ipad = useMediaQuery(theme.breakpoints.between("sm", "md"));
  const mobile = useMediaQuery(theme.breakpoints.down("sm"));
  return {
    largeDesktop,
    desktop,
    ipad,
    mobile,
  };
};

export const caclulatePlanCostBasedOnEligibility = (
  actualCost: number,
  discount: number
) => {
  let ssoCustomerInfo: any = localStorage.getItem("ssoCustomerInfo");
  ssoCustomerInfo = JSON.parse(ssoCustomerInfo);
  if (ssoCustomerInfo) {
    if (ssoCustomerInfo?.mobile_discount_qualified) {
      return actualCost - discount;
    } else {
      return actualCost;
    }
  } else {
    return actualCost - discount;
  }
};

export const getFontFamily = (data: any) => {
  switch (data) {
    case 1:
      return "var(--font_family_Bold)";
    case 2:
      return "var(--font_family_Semibold)";
    case 3:
      return "var(--font_family_Medium)";
    case 4:
      return "var(--font_family_Regular)";
    default:
      return data;
  }
};

export const renderTextStyles = (
  fontFamily: string | number,
  fontSize: any,
  color: string,
  _lineHeight?: any,
  _fontWeight?: string,
  _letterSpacing?: any
): any => {
  return {
    fontFamily: getFontFamily(fontFamily),
    fontSize: _.isString(fontSize) ? fontSize : { ...fontSize },
    color: color === "inherit" ? color : `var(--${color})`,
    ...(_lineHeight && {
      lineHeight: _.isString(_lineHeight) ? _lineHeight : { ..._lineHeight },
    }),
    ...(_fontWeight && {
      fontWeight: _fontWeight,
    }),
    ...(_letterSpacing && {
      letterSpacing: _.isString(_letterSpacing)
        ? _letterSpacing
        : { ..._letterSpacing },
    }),
  };
};