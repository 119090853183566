import { Box, Grid, Typography } from "@mui/material";
import { useFormik } from "formik";
import { AddressField } from "../../../checkout/AddressField/AddressField";
import { Divider, ButtonCust, SimpleModal } from "../../../widgets";
import { object, string } from "yup";
import { geocodeByPlaceId } from "react-places-autocomplete";
import { countryList } from "../../../../config/USAStateList";
import EnvConfig from "../../../../config/env.config.json";
import { useAppDispatch } from "../../../../app/hooks";
import { wifiUpdate } from "../../../../features/settings/settingsSlice";
type Props = {
  wifiAddressModal: boolean;
  onClose: Function;
  content?: any;
  modalType: string;
  formValues: any;
  customerId: any;
  isLoading: boolean;
  setIsLoading: Function;
};
const WifiAddressModal = (props: Props) => {
  const {
    wifiAddressModal,
    onClose,
    content,
    formValues,
    customerId,
    isLoading,
    setIsLoading,
  } = props;
  const dispatch = useAppDispatch();
  const billingAndShippingSchema = object({
    wifiCallingAddress: object({
      address1: string().trim().required(content?.adrs_flds?.address1_err),
      city: string().trim().required(content?.adrs_flds?.city_err),
      state: string().trim().required(content?.adrs_flds?.state_err),
      zip: string()
        .trim()
        .required(content?.adrs_flds?.zip_err1)
        .matches(/^[0-9]+$/, content?.adrs_flds?.zip_err2)
        .min(5, content?.adrs_flds?.zip_err3)
        .max(5, content?.adrs_flds?.zip_err3),
    }),
  });

  const handleUpdateAddress = () => {
    let address = Object.assign({}, formik.values.wifiCallingAddress);
    delete Object.assign(address, { ["street1"]: address["address1"] })[
      "address1"
    ];
    delete Object.assign(address, { ["street2"]: address["address2"] })[
      "address2"
    ];
    let payload = {
      customerId,
      data: {
        customerId,
        e911Address: address,
        enable: true,
      },
    };
    dispatch(wifiUpdate(payload));
  };

  const formik = useFormik({
    initialValues: formValues,
    validationSchema: billingAndShippingSchema,
    onSubmit: () => {
      setIsLoading(true);
      handleUpdateAddress();
    },
    enableReinitialize: true,
  });
  const handleClose = () => {
    setIsLoading(false);
    onClose(false);
  };
  const handleAddressChange = (value: any, name: string) => {
    formik.handleChange;
    formik.setFieldValue(`wifiCallingAddress.${name}`, value);
  };
  const handleAddressSearchSelection = async (
    address: any,
    addressObj: "wifiCallingAddress"
  ) => {
    const results = await geocodeByPlaceId(address);
    let city: string = "",
      state: string = "",
      zip: string = "",
      country: string = "",
      route: string = "",
      streetNumber: string = "";
    results &&
      results[0] &&
      results[0].address_components &&
      results[0].address_components.map((el: any) => {
        state = el.types.includes("administrative_area_level_1")
          ? el.short_name
          : state;
        zip = el.types.includes("postal_code") ? el.short_name : zip;
        city = el.types.includes("locality")
          ? el.long_name
          : el.types.includes("sublocality")
          ? el.long_name
          : el.types.includes('neighborhood')
          ? el.long_name
          : city;
        streetNumber = el.types.includes("street_number")
          ? el.short_name
          : streetNumber;
        route = el.types.includes("route") ? el.long_name : route;
        country = el.types.includes("country") ? el.short_name : country;
        return null;
      });
    const addressData = {
      address1: `${streetNumber} ${route}`,
      city: city,
      state: state,
      zip: zip,
      country: countryList?.[0]?.value,
    };
    if (country === "US") {
      formik.handleChange;
      formik.setFieldValue(
        `${addressObj}.address1`,
        `${streetNumber} ${route}`
      );
      formik.setFieldValue(`${addressObj}.city`, city);
      formik.setFieldValue(`${addressObj}.state`, state);
      formik.setFieldValue(`${addressObj}.zip`, zip);
      formik.setFieldValue(`${addressObj}.country`, "USA");
    }
  };
  const handleSubmit = (e: any) => {
    formik.handleSubmit(e);
  };
  return (
    <>
      <SimpleModal
        isShowModal={wifiAddressModal}
        onCloseModal={handleClose}
        showClose={true}
        sx={{
          width: { xs: "90%", sm: "60%", md: "80%", lg: "35%", xl: "35%" },
        }}
      >
        <Grid
          container
          display="flex"
          flexDirection="column"
          alignItems="center"
          textAlign="center"
        >
          <Typography
            sx={{
              fontFamily: "var(--font_family_Medium)",
              fontWeight: "var(--font_weight_4)",
              fontSize: "22px",
              pb: "16px",
            }}
          >
            {content?.ttl}
          </Typography>
          <Divider styles={{ mb: "20px" }}></Divider>
         <Box sx={{textAlign:"start"}}>
         <AddressField
            formik={formik}
            addressObjName="wifiCallingAddress"
            content={content?.adrs_flds}
            handleAddressChange={handleAddressChange}
            handleSelect={(e: any) =>
              handleAddressSearchSelection(e, "wifiCallingAddress")
            }
          />
         </Box>
          <ButtonCust
            onClick={(e: any) => handleSubmit(e)}
            variantType={EnvConfig.PRIMARY_BUTTON_TYPE}
            loading={isLoading}
          >
            {content?.btn}
          </ButtonCust>
        </Grid>
      </SimpleModal>
    </>
  );
};

export default WifiAddressModal;
